import { faSortSizeDown, faSortSizeDownAlt } from "@fortawesome/pro-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { Accordion, Panel } from "baseui/accordion"
import { Block } from "baseui/block"
import { Button, SHAPE } from "baseui/button"
import { Filter, Search, Show } from "baseui/icon"
import { Input } from "baseui/input"
import { Pagination } from "baseui/pagination"
import { StatefulPopover } from "baseui/popover"
import { Radio, RadioGroup } from "baseui/radio"
import { Select, TYPE } from "baseui/select"
import { StyledSpinnerNext } from "baseui/spinner"
import { TableBuilder, TableBuilderColumn } from "baseui/table-semantic"
import { Tag } from "baseui/tag"
import { colors } from "baseui/tokens"
import { Label1 } from "baseui/typography"
import React, { useCallback, useEffect, useState } from "react"
import { useMutation } from "react-fetching-library"
import { useHistory } from "react-router-dom"
import { AccountType, getAccountTypeString } from "../../api/enums"
import { debounce } from "../../lib/debounce"
import { getUsers, GetUsersRequest, GetUsersSortBy, User } from "../../lib/superAdmin"

type UserRowData = [
	string, // id
	string, // email
	string, // verified
	string, // account_type
	string, // first_name
	string, // last_name
	string, // contact_number
	string | undefined, // industry
	number, // credit_count
	string, // created_at
]

const columns = ["ID", "Email", "Verified", "Account Type", "First Name", "Last Name", "Contact Number", "Industry", "Credit Count", "Joined On"]

const defaultOptions: GetUsersRequest = {
	limit: 20,
	offset: 0,
}

interface SuperAdminManageUsersPageProps {}

export const SuperAdminManageUsersPage: React.FC<SuperAdminManageUsersPageProps> = () => {
	const history = useHistory()
	const { mutate: refetchUsers, payload, loading, error } = useMutation(getUsers)
	const [userRowData, setUserRowData] = useState<UserRowData[]>()
	const [currentPage, setCurrentPage] = useState(payload?.current_page || 1)
	const [options, setOptions] = useState<GetUsersRequest>(defaultOptions) // stores options that haven't been applied yet
	const [confirmedOptions, setConfirmedOptions] = useState<GetUsersRequest>(options) // stores applied options
	const [searchQuery, setSearchQuery] = useState("")

	useEffect(() => {
		refetchUsers(defaultOptions)
	}, [refetchUsers])

	useEffect(() => {
		if (!payload || error) return
		setUserRowData(generateUserRowDataFromUserList(payload.users))
	}, [payload, error])

	const debouncedRefetchUsersWithSearchQuery = useCallback(
		debounce((query: string) => {
			setCurrentPage(1)
			refetchUsers({
				...confirmedOptions,
				filters: {
					...confirmedOptions.filters,
					search_query: query,
				},
			})
			console.log("SEARCHED: " + query)
		}, 1000),
		[confirmedOptions],
	)

	const renderOptionTags = () => {
		const tags: React.ReactNode[] = []
		let index = 0

		if (confirmedOptions.sort_by) {
			let displayString = "Sort by: "
			switch (confirmedOptions.sort_by) {
				case GetUsersSortBy.SORT_BY_CREATED_AT_ASCENDING:
					displayString += "Oldest to Newest"
					break
				case GetUsersSortBy.SORT_BY_CREATED_AT_DESCENDING:
					displayString += "Newest to Oldest"
					break
			}

			tags.push(
				<Tag
					key={index++}
					onActionClick={() => {
						setConfirmedOptions((prevConfirmedOptions) => ({
							...prevConfirmedOptions,
							sort_by: undefined,
						}))
						setOptions((prevOptions) => ({
							...prevOptions,
							sort_by: undefined,
						}))
						refetchUsers({
							...confirmedOptions,
							filters: {
								...confirmedOptions.filters,
								search_query: searchQuery,
							},
							sort_by: undefined,
						})
					}}
				>
					{displayString}
				</Tag>,
			)
		}

		if (confirmedOptions.filters) {
			const { credit_count, verified, account_types } = confirmedOptions.filters

			if (credit_count) {
				tags.push(
					<Tag
						key={index++}
						onActionClick={() => {
							setConfirmedOptions((prevConfirmedOptions) => ({
								...prevConfirmedOptions,
								filters: {
									...prevConfirmedOptions.filters,
									credit_count: undefined,
								},
							}))
							setOptions((prevOptions) => ({
								...prevOptions,
								filters: {
									...prevOptions.filters,
									credit_count: undefined,
								},
							}))
							refetchUsers({
								...confirmedOptions,
								filters: {
									...confirmedOptions.filters,
									search_query: searchQuery,
									credit_count: undefined,
								},
							})
						}}
					>
						Credits: {credit_count}
					</Tag>,
				)
			}

			if (typeof verified !== "undefined") {
				tags.push(
					<Tag
						key={index++}
						onActionClick={() => {
							setConfirmedOptions((prevConfirmedOptions) => ({
								...prevConfirmedOptions,
								filters: {
									...prevConfirmedOptions.filters,
									verified: undefined,
								},
							}))
							setOptions((prevOptions) => ({
								...prevOptions,
								filters: {
									...prevOptions.filters,
									verified: undefined,
								},
							}))
							refetchUsers({
								...confirmedOptions,
								filters: {
									...confirmedOptions.filters,
									search_query: searchQuery,
									verified: undefined,
								},
							})
						}}
					>
						Verification: {verified ? "Verified" : "Not verified"}
					</Tag>,
				)
			}

			if (account_types && account_types.length > 0) {
				account_types.forEach((a) => {
					tags.push(
						<Tag
							key={index++}
							onActionClick={() => {
								setConfirmedOptions((prevConfirmedOptions) => ({
									...prevConfirmedOptions,
									filters: {
										...prevConfirmedOptions.filters,
										account_types: prevConfirmedOptions.filters?.account_types?.filter((e) => e !== a),
									},
								}))
								setOptions((prevOptions) => ({
									...prevOptions,
									filters: {
										...prevOptions.filters,
										account_types: prevOptions.filters?.account_types?.filter((e) => e !== a),
									},
								}))
								refetchUsers({
									...confirmedOptions,
									filters: {
										...confirmedOptions.filters,
										search_query: searchQuery,
										account_types: confirmedOptions.filters?.account_types?.filter((e) => e !== a),
									},
								})
							}}
						>
							Account type: {getAccountTypeString(a)}
						</Tag>,
					)
				})
			}
		}

		return tags
	}

	return (
		<Block height="100%">
			{/* SEARCH BAR AND OPTIONS */}
			<Block
				position="sticky"
				top="0"
				display="flex"
				alignItems="center"
				padding="1rem 0"
				backgroundColor={"white"}
				overrides={{
					Block: {
						style: {
							zIndex: 5,
							overflowX: "auto",
							borderBottom: `1px solid ${colors.gray100}`,
						},
					},
				}}
			>
				<Input
					value={searchQuery || ""}
					onChange={(e) => {
						const { value } = e.currentTarget
						debouncedRefetchUsersWithSearchQuery(value)
						setSearchQuery(value)
					}}
					startEnhancer={<Search />}
					overrides={{
						Root: {
							style: {
								minWidth: "200px",
								maxWidth: "300px",
								marginRight: "1rem",
							},
						},
					}}
				/>
				<StatefulPopover
					content={({ close }) => (
						<Block width="300px">
							<Accordion>
								<Panel title="Filters">
									<Label1 marginBottom=".5rem">
										Credits:
										<Block height=".5rem" />
										<Input
											type="number"
											value={options.filters?.credit_count || ""}
											onChange={(e) => {
												const { value } = e.currentTarget

												setOptions((prevOptions) => ({
													...prevOptions,
													filters: {
														...prevOptions.filters,
														credit_count: parseInt(value),
													},
												}))
											}}
										/>
									</Label1>
									<Label1 marginBottom=".5rem">
										Verification:
										<RadioGroup
											onChange={(e) =>
												setOptions((prevOptions) => ({
													...prevOptions,
													filters: {
														...prevOptions.filters,
														verified: e.currentTarget.value === "true",
													},
												}))
											}
											value={String(options.filters?.verified)}
										>
											<Radio value={"true"}>Verified</Radio>
											<Radio value={"false"}>Not Verified</Radio>
										</RadioGroup>
									</Label1>
									<Label1>
										Account types:
										<Block height=".5rem" />
										<Select
											options={[
												{ id: AccountType.Free, label: "Free" },
												{ id: AccountType.Basic, label: "Basic" },
												{ id: AccountType.Professional, label: "Professional" },
												{ id: AccountType.NewProfessional, label: "New Professional" },
												{ id: AccountType.Enterprise, label: "Enterprise" },
												{ id: AccountType.SuperAdmin, label: "Super Admin" },
											]}
											valueKey="id"
											labelKey="label"
											type={TYPE.search}
											multi
											onChange={({ value }) => {
												const account_types: number[] = value.map((v) => v.id as number)
												setOptions((prevOptions) => ({
													...prevOptions,
													filters: {
														...prevOptions.filters,
														account_types,
													},
												}))
											}}
											value={
												options.filters?.account_types &&
												options.filters.account_types.map((a) => ({
													id: a,
													label: getAccountTypeString(a),
												}))
											}
										/>
									</Label1>
								</Panel>
								<Panel title="Sort By">
									<Label1 marginBottom=".5rem">
										Joined on:
										<RadioGroup
											onChange={(e) =>
												setOptions((prevOptions) => ({
													...prevOptions,
													sort_by: e.currentTarget.value as GetUsersSortBy,
												}))
											}
											value={options.sort_by}
										>
											<Radio value={GetUsersSortBy.SORT_BY_CREATED_AT_ASCENDING}>
												Oldest to Newest <FontAwesomeIcon icon={faSortSizeDownAlt} />
											</Radio>
											<Radio value={GetUsersSortBy.SORT_BY_CREATED_AT_DESCENDING}>
												Newest to Oldest <FontAwesomeIcon icon={faSortSizeDown} />
											</Radio>
										</RadioGroup>
									</Label1>
								</Panel>
							</Accordion>
							<Button
								overrides={{
									Root: {
										style: {
											width: "100%",
										},
									},
								}}
								onClick={() => {
									setCurrentPage(1)
									close()
									refetchUsers({
										...options,
										filters: {
											...options.filters,
											search_query: searchQuery,
										},
									})
									setConfirmedOptions(options)
								}}
							>
								Apply
							</Button>
						</Block>
					)}
				>
					<Button
						size="compact"
						shape={SHAPE.pill}
						endEnhancer={Filter}
						overrides={{
							Root: {
								style: {
									height: "2rem",
									marginRight: "1rem",
								},
							},
						}}
					>
						Options
					</Button>
				</StatefulPopover>
				{renderOptionTags()}
			</Block>
			{!userRowData || !payload || loading ? (
				<Block height="100%" display="flex" justifyContent="center" alignItems="center">
					<StyledSpinnerNext size="3rem" />
				</Block>
			) : (
				<>
					{/* TABLE AND DATA */}
					<TableBuilder
						overrides={{
							TableBodyCell: {
								style: {
									verticalAlign: "middle",
								},
							},
						}}
						data={userRowData}
					>
						<TableBuilderColumn>
							{(row) => (
								<Button
									onClick={() => {
										history.push(`/users/${row[0]}`)
									}}
									overrides={{
										BaseButton: {
											style: {
												":hover": {
													backgroundColor: colors.gray300,
												},
											},
										},
									}}
									size="mini"
									kind="tertiary"
									shape={SHAPE.circle}
								>
									<Show />
								</Button>
							)}
						</TableBuilderColumn>
						{columns.map((c, index) => (
							<TableBuilderColumn key={index} header={c}>
								{(row) => row[index]}
							</TableBuilderColumn>
						))}
					</TableBuilder>
				</>
			)}
			{/* PAGINATION */}
			<Block
				position="sticky"
				bottom="0"
				padding="1rem 0"
				backgroundColor={"white"}
				overrides={{
					Block: {
						style: {
							borderTop: `1px solid ${colors.gray100}`,
						},
					},
				}}
			>
				<Pagination
					numPages={payload?.total_pages || 0}
					currentPage={currentPage}
					onPageChange={async ({ nextPage }) => {
						setCurrentPage(nextPage)
						refetchUsers({
							...confirmedOptions,
							filters: {
								...confirmedOptions.filters,
								search_query: searchQuery,
							},
							offset: (nextPage - 1) * 20,
						})
					}}
				/>
			</Block>
		</Block>
	)
}

const generateUserRowDataFromUserList = (users: User[]) => {
	const rows: UserRowData[] = []

	users.forEach((u) => {
		rows.push([
			u.id,
			u.email,
			u.verified ? "Verified" : "",
			getAccountTypeString(u.account_type),
			u.first_name,
			u.last_name,
			u.contact_number,
			u.industry,
			u.credit_count,
			new Date(u.created_at).toLocaleDateString(),
		])
	})

	return rows
}
