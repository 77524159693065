import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { useStyletron } from "baseui"
import { Button } from "baseui/button"
import { Checkbox } from "baseui/checkbox"
import { TableBuilder, TableBuilderColumnProps } from "baseui/table-semantic"
import { H1 } from "baseui/typography"
import { plural } from "pluralize"
import * as React from "react"
import { useQuery } from "react-fetching-library"
import { useHistory } from "react-router-dom"
import { useLocalStorage } from "react-use"
import { ObjectType } from "../api/enums"
import { isAPIError } from "../api/types"
import { listCategories } from "../lib/categories"
import { exportQuery } from "../lib/common"
import { CommonContainer } from "../lib/controller"
import { listGroupJudges, listGroups, listGroupTeams } from "../lib/groups"
import { listJudges } from "../lib/judges"
import { listQuestions } from "../lib/questions"
import { listTeamAssessments } from "../lib/teamAssessments"
import { listTeams } from "../lib/teams"
import { getTemplate } from "../lib/templates"
import { ImportModal } from "./importModal"
import { PremiumButton } from "./premiumButton"
import { Spaced } from "./spaced"
import { Spread } from "./spread"

export interface ItemListColumn {
	name: string
	header?: React.ReactNode
	resolver: (value: any) => React.ReactNode

	sortable?: boolean
	filterable?: boolean
}

interface ItemListProps {
	header?: React.ReactNode
	hideActions: boolean
	type: ObjectType
	columns: ItemListColumn[]
	/** Defaults to link (eg: `/users/${id}`) */
	onRowClick?: ((data: any) => void) | null

	/** Defaults to link (eg: `/users/create`) */
	onCreate?: (() => void) | null
	canImport?: boolean
	canExport?: boolean
	customActions?: React.ReactNode
	hideArchiveToggle?: boolean
	/** Override the default table builder */
	tableBuilder?: (rows: any[], setRows: (rows: any) => void) => React.ReactNode

	teamID?: string
	groupID?: string
	lastEditID?: string
}

export const ItemList = (props: ItemListProps) => {
	const { header, type, columns, canImport, canExport, customActions, hideArchiveToggle, tableBuilder, hideActions } = props

	const { eventID } = CommonContainer.useContainer()

	const pluralName = plural(type)

	const history = useHistory()
	const onRowClick = props.onRowClick !== undefined ? props.onRowClick : (row: any) => history.push(`/${pluralName}/${row.id}`)
	const onCreate = props.onCreate !== undefined ? props.onCreate : () => history.push(`/${pluralName}/create`)

	// Querying
	const [errorMessage, setErrorMessage] = React.useState<string>()
	const [rows, setRows] = React.useState<any[]>([])
	const {
		query: refresh,
		error,
		payload,
		loading,
	} = useQuery<any[]>(
		(() => {
			switch (type) {
				case ObjectType.Team:
					return listTeams(eventID)
				case ObjectType.Category:
					return listCategories(eventID)
				case ObjectType.TeamAssessment:
					return listTeamAssessments(props.teamID || "")
				case ObjectType.Question:
					return listQuestions(eventID)
				case ObjectType.Group:
					return listGroups(eventID)
				case ObjectType.GroupTeamList:
					return listGroupTeams(props.groupID || "", eventID)
				case ObjectType.GroupJudgeList:
					return listGroupJudges(props.groupID || "", eventID)
				default:
					return listJudges(eventID)
			}
		})(),
	)

	React.useEffect(() => {
		if (loading) return
		if (error && isAPIError(payload)) {
			setErrorMessage(payload.shortMessage)
			return
		}
		if (payload === undefined) {
			setErrorMessage("Failed to get list")
			return
		}
		setRows(payload || [])
	}, [error, payload, loading])

	// Export/Import
	const { error: exportError, payload: exportPayload, query: queryExport, loading: isExporting } = useQuery(exportQuery(type, eventID), false)
	React.useEffect(() => {
		if (isExporting) return
		if (exportError && isAPIError(exportPayload)) setErrorMessage(exportPayload.shortMessage)
	}, [exportError, exportPayload, isExporting])

	const [isImportModalOpen, setIsImportModalOpen] = React.useState(false)
	const onExport = async () => {
		const resp = await queryExport()
		if (!resp.error && resp.payload instanceof Blob) {
			saveAs(resp.payload, `${eventID}_${pluralName}.csv`)
		}
	}
	const onImport = () => setIsImportModalOpen(true)

	// Template download
	const { query: queryTemplateDownload } = useQuery(getTemplate(type), false)
	const onTemplate = async () => {
		const resp = await queryTemplateDownload()
		if (!resp.error && resp.payload instanceof Blob) {
			saveAs(resp.payload, `${eventID}_${pluralName}.csv`)
		}
	}

	// Show Archived toggle
	const [getShowArchived, _setShowArchivedStorage] = useLocalStorage<boolean>(`${history.location.pathname}-showArchived`)
	const [showArchived, _setShowArchived] = React.useState(getShowArchived || false)
	const setShowArchived = (value: boolean) => {
		_setShowArchived(value)
		_setShowArchivedStorage(value)
	}

	// Styling
	const [css] = useStyletron()
	const container = css({
		maxWidth: "900px",
		margin: "10px auto",
	})
	const actionsStyle = css({
		marginBottom: "10px",
		display: "flex",
		justifyContent: "space-between",
		alignItems: "center",
		flexWrap: "wrap",
	})
	return (
		<div className={container}>
			{header && <H1>{header}</H1>}
			<div className={actionsStyle}>
				<Spread>
					<Spaced
						overrides={{
							container: {
								marginRight: "10px",
							},
						}}
					>
						{canImport && <PremiumButton onClick={onTemplate} label={"Template"} iconProp={["fal", "file-csv"]} />}
						{!hideActions && canImport && <PremiumButton onClick={onImport} label={"Import"} iconProp={["fal", "file-csv"]} />}
						{canExport && <PremiumButton onClick={onExport} label={"Export"} iconProp={["fal", "file-csv"]} />}
					</Spaced>
					{!hideActions && (
						<Spaced>
							{customActions}
							{onCreate && (
								<div style={{ marginTop: "5px" }}>
									<Button onClick={onCreate} startEnhancer={<FontAwesomeIcon icon={["fas", "plus-circle"]} />}>
										<div style={{ textTransform: "capitalize" }}>{`New ${type}`}</div>
									</Button>
								</div>
							)}
						</Spaced>
					)}
				</Spread>
				{!hideArchiveToggle && (
					<Checkbox
						checkmarkType="toggle_round"
						checked={showArchived}
						onChange={(e) => setShowArchived(e.currentTarget.checked)}
						overrides={{
							Root: {
								style: {
									marginLeft: "auto",
								},
							},
						}}
					>
						Show Archived
					</Checkbox>
				)}
			</div>
			{canImport && <ImportModal event={eventID} type={type} isOpen={isImportModalOpen} onClose={() => setIsImportModalOpen(false)} onRefresh={refresh} />}

			{errorMessage && (
				<div>
					<pre>{errorMessage}</pre>
				</div>
			)}

			{!!tableBuilder ? (
				tableBuilder(rows, setRows)
			) : (
				<TableBuilder
					data={showArchived ? rows : rows.filter((row) => !row.archived)}
					overrides={{
						Root: {
							style: {
								borderTopStyle: "unset",
								borderBottomStyle: "unset",
								borderLeftStyle: "unset",
								borderRightStyle: "unset",
								height: "100%",
							},
						},
						TableHeadCellSortable: {
							component: TableHeaderCell,
						},
						TableHeadCell: {
							component: TableHeaderCell,
						},
						TableBodyCell: {
							style: {
								height: "fit-content",
								color: "#767676",
								fontSize: "14px",
								paddingLeft: "12px",
								paddingRight: "12px",
								verticalAlign: "middle",
							},
						},
						TableBodyRow: {
							style: {
								cursor: "pointer",
							},
							component: TableRow,
							props: {
								onRowClick: onRowClick,
								lastEdited: props.lastEditID,
							},
						},
					}}
				>
					{columns.map((c) => ListTableColumn(c))}
				</TableBuilder>
			)}
			{!loading && rows.length === 0 && <pre style={{ padding: "0 16px" }}>No results</pre>}
		</div>
	)
}

const TableRow = React.forwardRef<HTMLTableRowElement>(({ $row, $rowIndex, $style, children, onRowClick, lastEdited }: any, ref) => {
	const [css, theme] = useStyletron()
	const row = css($style)
	const extra = css({
		backgroundColor: lastEdited === $row.id ? "#e8e8e8" : $rowIndex % 2 !== 0 ? theme.colors.backgroundSecondary : "unset",
		":hover": {
			backgroundColor: "#eef0f9",
		},
	})

	return (
		<tr className={`${row} ${extra}`} onClick={() => onRowClick && onRowClick($row)} ref={ref}>
			{children}
		</tr>
	)
})

const TableHeaderCell = React.forwardRef<HTMLTableHeaderCellElement>(({ $col, children, onFilter, filters, onSort, sortBy, sortAsc }: any, ref) => {
	const beingFiltered = filters?.get($col.id)?.value !== undefined

	const [css, theme] = useStyletron()
	const style = css({
		paddingTop: "8px",
		paddingBottom: "8px",
		paddingLeft: "12px",
		paddingRight: "12px",
		":before": {
			borderLeftStyle: "unset",
		},
		":after": {
			backgroundImage: "unset",
		},
		borderBottomColor: theme.colors.backgroundSecondary,
		borderBottomWidth: "2px",
		borderBottomStyle: "solid",
		fontWeight: 600,
		textAlign: "left",
		whiteSpace: "nowrap",
		cursor: "pointer",
		":hover":
			onFilter !== undefined
				? {
						backgroundColor: theme.colors.backgroundSecondary,
				  }
				: undefined,
	})
	const cellStyle = css({
		display: "flex",
		alignItems: "center",
	})
	const chevronContainerStyle = css({
		width: "30px",
	})
	const chevronStyle = css({
		marginLeft: "5px",
		verticalAlign: "initial",
	})
	const filterStyle = css({
		display: "flex",
		marginLeft: "auto",
		justifyContent: "center",
		alignItems: "center",
		width: "24px",
		height: "24px",

		cursor: "pointer",
		":hover": {
			backgroundColor: theme.colors.backgroundAlt,
			boxShadow: theme.lighting.shadow400,
		},
		backgroundColor: beingFiltered ? theme.colors.backgroundAlt : "unset",
		boxShadow: beingFiltered ? theme.lighting.shadow400 : "unset",
	})

	return (
		<th
			className={style}
			onClick={(e) => {
				if (!$col.sortable || !onSort) return
				e.stopPropagation()
				onSort($col.id)
			}}
			ref={ref}
		>
			<div className={cellStyle}>
				{$col.sortable ? children[0] : children}
				{$col.sortable && (
					<div className={chevronContainerStyle}>
						{sortBy === $col.id && <FontAwesomeIcon icon={["fal", sortAsc ? "chevron-up" : "chevron-down"]} size="xs" className={chevronStyle} />}
					</div>
				)}
				{$col.filterable && onFilter !== undefined && (
					<div
						className={filterStyle}
						onClick={(e) => {
							e.stopPropagation()
							onFilter($col)
						}}
					>
						<FontAwesomeIcon icon={[beingFiltered ? "fas" : "fal", "filter"]} size="xs" />
					</div>
				)}
			</div>
		</th>
	)
})

const ListTableColumn = (c: ItemListColumn) => (
	<CustomTableBuilderColumn
		key={`list-column-${c.name}`}
		id={c.name}
		header={c.header || c.name}
		sortable={c.sortable}
		filterable={c.filterable}
		overrides={{
			TableBodyCell: {
				style: ({ $row }) => ({
					opacity: $row?.archived ? "0.5" : "unset",
					verticalAlign: "middle",
					textDecoration: $row?.archived ? "line-through" : "unset",
				}),
			},
		}}
	>
		{(row) => c.resolver(row)}
	</CustomTableBuilderColumn>
)

interface CustomTableBuilderColumnProps<RowT> extends TableBuilderColumnProps<RowT> {
	filterable?: boolean
}
class CustomTableBuilderColumn<RowT> extends React.Component<CustomTableBuilderColumnProps<RowT>> {}
