import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { Button } from "baseui/button"
import { arrayMove, arrayRemove, List } from "baseui/dnd-list"
import { H3 } from "baseui/typography"
import * as React from "react"
import { useMutation } from "react-fetching-library"
import { useStyletron } from "styletron-react"
import { ObjectType } from "../../api/enums"
import { isAPIError } from "../../api/types"
import { ErrorNotification } from "../../components/common"
import { ItemList } from "../../components/itemList"
import { Spaced } from "../../components/spaced"
import { Category, reorderCategories } from "../../lib/categories"
import { CommonContainer } from "../../lib/controller"

export const CategoryList = () => {
	const { eventID, event } = CommonContainer.useContainer()
	const [css] = useStyletron()
	const { mutate: update, loading, error, payload, errorObject } = useMutation(reorderCategories)
	const [reordering, setReordering] = React.useState(false)
	const [oldRows, setOldRows] = React.useState<Category[]>([])

	const headerStyle = css({
		display: "none",
		"@media only screen and (max-width: 700px)": {
			display: "flex",
		},
	})
	const onSaveOrder = async (rows: Category[]) => {
		const resp = await update({
			eventID,
			ids: rows.map((row) => row.id),
		})
		if (!resp.error) setReordering(false)
	}

	const archivedStyle = css({
		opacity: "0.5",
		textDecoration: "line-through",
	})

	return (
		<>
			<div className={headerStyle}>
				<H3 $style={{ marginBottom: "5px", marginTop: 0 }}>Categories</H3>
			</div>
			<ItemList
				hideActions={!event?.is_current}
				type={ObjectType.Category}
				columns={[
					{
						name: "Name",
						resolver: (value) => value.name,
					},
					{
						name: "Description",
						resolver: (value) => value.description,
					},
					{
						name: "Weight",
						resolver: (value) => value.weight,
					},
				]}
				onCreate={reordering ? null : undefined}
				canImport={!reordering}
				canExport={!reordering}
				hideArchiveToggle={reordering}
				customActions={
					!reordering && (
						<div style={{ marginTop: "5px" }}>
							<Button onClick={() => setReordering(true)} startEnhancer={<FontAwesomeIcon icon={["fas", "sort"]} />}>
								Reorder
							</Button>
						</div>
					)
				}
				tableBuilder={
					reordering
						? (rows: Category[], setRows) => {
								if (oldRows.length === 0) {
									setOldRows(rows)
								}
								return (
									<>
										<Spaced>
											<Button
												onClick={() => {
													setRows(oldRows)
													setReordering(false)
												}}
												kind="secondary"
												disabled={loading}
											>
												Cancel
											</Button>
											<Button
												onClick={() => {
													setOldRows([])
													onSaveOrder(rows)
												}}
												isLoading={loading}
												startEnhancer={<FontAwesomeIcon icon={["fas", "save"]} />}
											>
												Save Order
											</Button>
										</Spaced>
										{error && <ErrorNotification message={isAPIError(payload) ? payload.shortMessage : `${errorObject}`} />}
										<List
											items={rows.map((row, i) => (
												<div key={`reorder-item-${row.id}`} className={row.archived ? archivedStyle : ""}>
													{row.name}
												</div>
											))}
											onChange={({ oldIndex, newIndex }) => setRows(newIndex === -1 ? arrayRemove(rows, oldIndex) : arrayMove(rows, oldIndex, newIndex))}
										/>
									</>
								)
						  }
						: undefined
				}
			/>
		</>
	)
}
