import * as React from "react"
import { ReportData } from "../lib/report"
import { Card } from "baseui/card"
import { useStyletron } from "styletron-react"
import { ParagraphMedium } from "baseui/typography"
import { StyledSpinnerNext as Spinner } from "baseui/spinner"
import { CommonContainer } from "../lib/controller"
import { ScoreType } from "../api/enums"

export const TeamScoreBreakdown = (props: { report: ReportData }) => {
	const { report } = props
	const { event } = CommonContainer.useContainer()
	const scoreDivision = event?.score_type === ScoreType.Decimal ? 10 : 1

	const [css] = useStyletron()
	const containerStyle = css({
		justifyContent: "center",
		display: "flex",
		flexDirection: "column",
		height: "100%",
		width: "100%",
	})
	const cardStyle = css({
		borderTopColor: "black",
		borderBottomColor: "black",
		borderLeftColor: "black",
		borderRightColor: "black",
		borderRadius: "3px",
	})
	const flexSpace = css({
		display: "flex",
		justifyContent: "space-between",
	})
	const headerStyle = css({
		fontSize: "1rem",
		fontWeight: "bold",
	})
	const scoreStyle = css({
		display: "flex",
		justifyContent: "space-between",
		alignItems: "center",
		marginBottom: "10px",
		minHeight: "50px",
	})

	if (!report) return <Spinner />

	const comments = report.comments.filter((c) => c.trim() !== "")

	return (
		<div className={containerStyle}>
			{report.total === 0 ? (
				<p style={{ color: "red", fontSize: "1.3rem" }}>No scores recorded</p>
			) : (
				<Card
					title={"Score Breakdown"}
					overrides={{
						Root: {
							style: {
								borderTopLeftRadius: "3px",
								borderTopRightRadius: "3px",
								borderBottomLeftRadius: "3px",
								borderBottomRightRadius: "3px",
								boxShadow: "0px 0px 18px #00000019;",
								borderTopWidth: "0px",
								borderBottomWidth: "0px",
								borderLeftWidth: "0px",
								borderRightWidth: "0px",
								padding: "1rem 0 1rem 0",
								marginBottom: "1rem",
								backgroundColor: "seashell",
							},
						},
						Title: {
							style: {
								fontSize: "30px",
							},
						},
					}}
				>
					<div>
						<div className={flexSpace}>
							<p className={headerStyle}>Category</p>
							<p className={headerStyle}>Score</p>
						</div>
						{report?.scores.map((score, i) => {
							return (
								<div key={`score-${score.categoryName}-${i}`}>
									<div className={scoreStyle}>
										<div>{score.categoryName}</div>
										<div style={{ paddingLeft: "4rem", fontSize: "30px", fontWeight: "bold" }}>{(score.value / scoreDivision).toPrecision(2)}</div>
									</div>
								</div>
							)
						})}
					</div>
				</Card>
			)}

			<div className={cardStyle}>
				<Card
					title="Feedback"
					overrides={{
						Root: {
							style: {
								borderTopLeftRadius: "3px",
								borderTopRightRadius: "3px",
								borderBottomLeftRadius: "3px",
								borderBottomRightRadius: "3px",
								boxShadow: "0px 0px 18px #00000019;",
								borderTopStyle: "none",
								borderBottomStyle: "none",
								borderLeftStyle: "none",
								borderRightStyle: "none",
								padding: "1rem 0 1rem 0",
								backgroundColor: "seashell",
								marginBottom: "1rem",
							},
						},
						Title: {
							style: {
								fontSize: "30px",
							},
						},
					}}
				>
					{comments.length === 0 ? (
						<p>No comments provided</p>
					) : (
						<Card
							overrides={{
								Root: {
									style: {
										borderTopStyle: "none",
										borderBottomStyle: "none",
										borderLeftStyle: "none",
										borderRightStyle: "none",
										backgroundColor: "seashell",
									},
								},
							}}
						>
							<div>
								{comments.map((c, i) => (
									<ParagraphMedium key={`judge-comment-${i}`}>{`"${c}"`}</ParagraphMedium>
								))}
							</div>
						</Card>
					)}
				</Card>
			</div>
		</div>
	)
}
