import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { useStyletron } from "baseui"
import { Button } from "baseui/button"
import { H1, H3 } from "baseui/typography"
import * as React from "react"
import { useQuery } from "react-fetching-library"
import { useSearchParam } from "react-use"
import { EventType, ScoreType } from "../api/enums"
import { isAPIError, isLeaderboardResponse, LeaderboardEntry } from "../api/types"
import { ErrorNotification } from "../components/common"
import { Loading } from "../components/loading"
import { CommonContainer } from "../lib/controller"
import { checkEventID, getLeaderboard } from "../lib/events"

export const PublicLeaderboard = () => {
	const eventID = useSearchParam("eventID")

	// Get Event Logo
	const { payload, error, errorObject } = useQuery(checkEventID(eventID || ""), !!eventID)
	const [logo, setLogo] = React.useState<string>()
	React.useEffect(() => {
		if (!payload || error) return
		if (payload.payload.logo) setLogo(`data:image/jpeg;base64,${payload.payload.logo}`)
	}, [payload, error, errorObject])

	// Styling
	const [css, theme] = useStyletron()
	const background = css({
		background: "linear-gradient(180deg, rgba(0,104,157,1) 0%, rgba(97,88,204,1) 100%)",
		height: "100%",
		display: "flex",
		justifyContent: "center",
		alignItems: "center",
	})
	const container = css({
		overflowY: "auto",
		maxHeight: "calc(100% - 40px)",
		maxWidth: "900px",
		minWidth: "40%",
		margin: "10px",
		paddingTop: "1px",
		paddingLeft: "20px",
		paddingRight: "20px",
		backgroundColor: "white",
		boxShadow: theme.lighting.shadow400,
		position: logo ? "relative" : "unset", // allow small event code hang in top-right corner
	})
	const headerStyle = css({
		display: "flex",
		alignItems: "center",
		justifyContent: "center",
	})
	const logoStyle = css({
		maxHeight: "120px",
		marginTop: "10px",
		marginBottom: "10px",
	})
	const smallEventCodeStyle = css({
		color: "grey",
		fontSize: "14px",
		position: "absolute",
		top: "10px",
		right: "10px",
	})

	return (
		<div className={background}>
			<div className={container}>
				<div className={headerStyle}>
					{logo && (
						<>
							<img src={logo} alt="Logo" className={logoStyle} />
							<div className={smallEventCodeStyle}>{payload?.payload?.shortcode}</div>
						</>
					)}
					{!logo && <H1>{payload?.payload?.shortcode}</H1>}
				</div>
				<Leaderboard />
			</div>
		</div>
	)
}

export const Leaderboard = () => {
	const eventIDArg = useSearchParam("eventID")
	const { eventID } = CommonContainer.useContainer()
	const { error, payload, loading } = useQuery(getLeaderboard(eventIDArg || eventID, !!eventIDArg))

	// Styling
	const [css] = useStyletron()
	const container = css({
		maxWidth: "900px",
		margin: "10px auto",
		fontSize: "18px",
		paddingBottom: "20px",
	})

	if (loading) return <Loading />
	if (error) return <ErrorNotification message={isAPIError(payload) ? payload.shortMessage : "Failed to contact the server"} />
	if (!payload || !isLeaderboardResponse(payload)) return null

	const hasMainScores = payload.EventType !== EventType.GuestOnly && hasScores(payload.MainJudge)
	const hasGuestScores = payload.EventType !== EventType.OfficialOnly && hasScores(payload.GuestJudge)

	return (
		<div className={container}>
			{payload.PublicEvent && <ShareLeaderboard eventID={eventIDArg || eventID} />}
			{!hasMainScores && !hasGuestScores && <p style={{ textAlign: "center" }}>No scores have been submitted yet.</p>}
			{hasMainScores && (
				<LeaderboardSection entries={payload.MainJudge} title={hasGuestScores ? "Main Judge Scores" : "Leaderboard"} scoreType={payload.ScoreType} />
			)}
			{hasGuestScores && (
				<LeaderboardSection entries={payload.GuestJudge} title={hasMainScores ? "Guest Judge Scores" : "Leaderboard"} scoreType={payload.ScoreType} />
			)}
		</div>
	)
}

const ShareLeaderboard = (props: { eventID?: string }) => {
	const { eventID } = props
	const [copied, setCopied] = React.useState<boolean>(false)

	const copyUrlToClipboard = () => {
		const el = document.createElement("textarea")
		el.value = `${window.location.origin}/leaderboard?eventID=${eventID}`
		document.body.appendChild(el)
		el.select()
		document.execCommand("copy")
		document.body.removeChild(el)
		setCopied(true)
	}

	return (
		<>
			<Button $style={{ marginTop: "5px" }} onClick={copyUrlToClipboard} startEnhancer={<FontAwesomeIcon icon={["fas", "share"]} />}>
				{copied ? "Copied!" : "Share"}
			</Button>
		</>
	)
}

const LeaderboardSection = (props: { entries: LeaderboardEntry[]; title: string; scoreType: ScoreType }) => {
	const { entries, title } = props
	const scoreDivision = props.scoreType === ScoreType.Decimal ? 10 : 1

	// Styling
	const [css, theme] = useStyletron()
	const containerStyle = css({
		marginTop: "10px",
	})
	const headerStyle = css({
		display: "flex",
	})
	const entryStyle = css({
		display: "flex",
		padding: "10px",
		alignItems: "center",
	})
	const entryAltStyle = css({
		backgroundColor: "#00000010",
		borderRadius: "4px",
	})
	const positionStyle = css({
		marginLeft: "8px",
		marginRight: "20px",
		minWidth: "24px",
		textAlign: "center",
		fontSize: "20px",
		fontWeight: "bold",
	})
	const teamStyle = css({
		display: "flex",
		flexDirection: "column",
		maxWidth: "60%",
		marginRight: "25px",
	})
	const teamNameStyle = css({
		fontSize: "14px",
		color: "grey",
	})
	const scoreStyle = css({
		marginLeft: "auto",
		display: "flex",
		alignItems: "center",
		justifyContent: "center",
		fontWeight: "bold",
		fontSize: "20px",
		color: "white",
		backgroundColor: "#CE4C41",
		borderRadius: "50px",
		width: "50px",
		height: "50px",
		minWidth: "50px",
		maxHeight: "50px",
		boxShadow: "inset " + theme.lighting.shadow400,
	})

	return (
		<div className={containerStyle}>
			<div className={headerStyle}>
				<H3 $style={{ marginBottom: "5px", marginTop: 0 }}>{title}</H3>
			</div>
			{entries.map((entry, i) => (
				<div key={`${title}-entry-${i}`} className={entryStyle + (i % 2 !== 0 ? " " + entryAltStyle : "")}>
					<div className={positionStyle}>{i + 1}</div>
					<div className={teamStyle}>
						<div>{entry.ProductName}</div>
						<div className={teamNameStyle}>{entry.TeamName}</div>
					</div>
					<div className={scoreStyle}>{Math.round((entry.Score / scoreDivision) * 10) / 10}</div>
				</div>
			))}
		</div>
	)
}

const hasScores = (entries: LeaderboardEntry[]) => {
	if (entries === null || entries.length === 0) return false
	return entries.some((e) => e.Score > 0)
}
