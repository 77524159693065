import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { useStyletron } from "baseui"
import { Table } from "baseui/table-semantic"
import { Tab, Tabs } from "baseui/tabs-motion"
import React from "react"
import { useParameterizedQuery, useQuery } from "react-fetching-library"
import { useHistory } from "react-router-dom"
import { AccountType } from "../../api/enums"
import { Invoice, isAPIError } from "../../api/types"
import { ErrorNotification } from "../../components/common"
import { Loading } from "../../components/loading"
import { Spread } from "../../components/spread"
import { CommonContainer } from "../../lib/controller"
import { GetBillingPortalURL } from "../../lib/stripe"
import { getInvoices } from "../../lib/user"
import { capitalizeFirstLetter } from "../../utils"
import { AccountTypeDescription } from "../subscription"

const COLUMNS = ["Purchase Date", "Start Date", "End Date", "Plan Type", "Price", "Currency", "Shop Front"]
const dateFormat = {
	day: "2-digit",
	month: "short",
	year: "numeric",
	hour: "2-digit",
	minute: "2-digit",
	hour12: false,
}

export const ManageBilling = () => {
	const [activeKey, setActiveKey] = React.useState("#current")
	const { user } = CommonContainer.useContainer()
	const history = useHistory()

	const { error: invoicesError, payload: invoicesPayload, loading: invoicesLoading } = useQuery<Invoice[]>(getInvoices())
	const { query: getPortalURL } = useParameterizedQuery<string>(GetBillingPortalURL)

	const [css, theme] = useStyletron()

	const container = css({
		maxWidth: "1200px",
		margin: "auto",
		marginTop: "10px",
	})
	const detailContainer = css({
		padding: "10px",
	})
	const link = css({
		cursor: "pointer",
	})
	const gridStyle = css({
		display: "grid",
		gridTemplateColumns: "48% 48%",
		columnGap: "2%",
		"@media only screen and (max-width: 980px)": {
			gridTemplateColumns: "100%",
		},
	})
	const detailHeader = css({
		color: "grey",
		margin: "0",
		fontSize: "12px",
	})
	const detail = css({
		color: "black",
		margin: "0",
		fontWeight: "bold",
		marginTop: "0",
		marginBottom: "10px",
	})
	const buttonStyle = css({
		...theme.typography.font450,
		fontWeight: "bold",
		padding: "10px",
		textAlign: "center",
		margin: "10px",
		borderRadius: "10px",
		backgroundColor: theme.colors.accent,
		color: theme.colors.white,
		border: "2px solid #d9deed",
		cursor: "pointer",
		":hover": {
			backgroundColor: "rgb(16 161 234)",
		},
		transition: "0.2s ease",
		outline: "unset",
		display: "flex",
		alignItems: "end",
		justifyContent: "right",
		marginLeft: "auto",
	})

	const onManageBilling = async (subService?: string) => {
		switch (subService) {
			case "google":
				window.open("https://play.google.com/store/account/subscriptions", "_blank")
				break
			case "apple":
				window.open("https://apple.co/2Th4vqI", "_blank")
				break
			default:
				try {
					const resp = await getPortalURL(window.location.href)
					if (resp.error || !resp.payload) return
					window.open(resp.payload, "_blank")
				} catch {}
		}
	}

	const invoiceData = (): any[][] => {
		let data: any[][] = []
		if (!invoicesPayload || invoicesPayload?.length === 0) {
			data.push(["No payment history"])
			return data
		}
		invoicesPayload?.map((invoice) => {
			let temp: any[] = []
			switch (invoice.storeName) {
				case "stripe":
				case "STRIPE":
					temp = [
						new Date(invoice.invoiceCreatedDate * 1000).toLocaleString(undefined, dateFormat),
						new Date(invoice.subStartDate * 1000).toLocaleString(undefined, dateFormat),
						new Date(invoice.subEndDate * 1000).toLocaleString(undefined, dateFormat),
						capitalizeFirstLetter(invoice.planName),
						invoice.price.toFixed(2),
						invoice.currency.toUpperCase(),
						<Spread>
							{capitalizeFirstLetter(invoice.storeName)}
							<div
								className={link}
								onClick={() => {
									onManageBilling("stripe")
								}}
							>
								<FontAwesomeIcon icon={["fas", "link"]} />
							</div>
						</Spread>,
					]

					break
				case "google":
				case "PLAY_STORE":
					temp = [
						new Date(invoice.invoiceCreatedDate * 1000).toLocaleString(undefined, dateFormat),
						new Date(invoice.invoiceCreatedDate * 1000).toLocaleString(undefined, dateFormat),
						new Date(invoice.subEndDate * 1000).toLocaleString(undefined, dateFormat),
						capitalizeFirstLetter(invoice.planName),
						invoice.price.toFixed(2),
						invoice.currency.toUpperCase(),
						<Spread>
							{capitalizeFirstLetter(invoice.storeName)}
							<div
								className={link}
								onClick={() => {
									onManageBilling("google")
								}}
							>
								<FontAwesomeIcon icon={["fas", "link"]} />
							</div>
						</Spread>,
					]
					break
				case "apple":
				case "APP_STORE":
					temp = [
						new Date(invoice.invoiceCreatedDate * 1000).toLocaleString(undefined, dateFormat),
						new Date(invoice.subStartDate * 1000).toLocaleString(undefined, dateFormat),
						new Date(invoice.subEndDate * 1000).toLocaleString(undefined, dateFormat),
						capitalizeFirstLetter(invoice.planName),
						invoice.price.toFixed(2),
						invoice.currency.toUpperCase(),
						<Spread>
							{capitalizeFirstLetter(invoice.storeName)}
							<div
								className={link}
								onClick={() => {
									onManageBilling("apple")
								}}
							>
								<FontAwesomeIcon icon={["fas", "link"]} />
							</div>
						</Spread>,
					]
					break
			}
			data.push(temp)
		})
		return data
	}

	return (
		<div className={container}>
			<Tabs
				onChange={({ activeKey }) => {
					setActiveKey(activeKey.toString())
				}}
				activeKey={activeKey}
				overrides={{
					TabHighlight: {
						style: {
							height: "3px",
							marginBottom: "7px",
						},
					},
					TabBorder: {
						style: { height: "1px" },
					},
				}}
			>
				<Tab title="Current" key="#current">
					<div className={gridStyle}>
						{user && !invoicesLoading && !invoicesError && invoicesPayload && (
							<div className={detailContainer}>
								<div>
									<p className={detailHeader}>Current Plan:</p>
									<p className={detail}>{AccountType[user?.accountType]}</p>
									{AccountTypeDescription(user.accountType, css)}
								</div>

								{user.accountType < AccountType.NewProfessional && (
									<button
										className={buttonStyle}
										onClick={(e) => {
											history.push("/subscription")
										}}
									>
										Upgrade!
									</button>
								)}
							</div>
						)}
						{user && user.accountType > 0 && !invoicesLoading && !invoicesError && invoicesPayload && invoicesPayload.length > 0 && (
							<div className={detailContainer}>
								<p className={detailHeader}>Start Date:</p>
								<p className={detail}>
									{new Date(invoicesPayload[invoicesPayload.length - 1].invoiceCreatedDate * 1000).toLocaleString(undefined, dateFormat)}
								</p>
								<p className={detailHeader}>Expiry Date:</p>
								<p className={detail}>{new Date(invoicesPayload[invoicesPayload.length - 1].subEndDate * 1000).toLocaleString(undefined, dateFormat)}</p>
								<p className={detailHeader}>Current Payment Store:</p>
								<p className={detail}>{capitalizeFirstLetter(invoicesPayload[invoicesPayload.length - 1].storeName)} </p>
								{user.accountType !== 0 && (
									<div>
										<button
											className={buttonStyle}
											onClick={(e) => {
												onManageBilling(invoicesPayload[invoicesPayload.length - 1].storeName)
											}}
										>
											<div>Manage Subscription</div>
										</button>
									</div>
								)}
							</div>
						)}
					</div>
				</Tab>
				<Tab title="Subscription History" key="#history">
					{invoicesLoading && <Loading />}
					{invoicesError && isAPIError(invoicesPayload) && <ErrorNotification message={invoicesPayload.shortMessage} />}
					{!invoicesLoading && !invoicesError && invoicesPayload && <Table columns={COLUMNS} data={invoiceData()} />}
				</Tab>
			</Tabs>
		</div>
	)
}
