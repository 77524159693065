import { Action } from "react-fetching-library"
import { CategoryInputType } from "../api/enums"
import { APIError } from "../api/types"

export interface Category {
	id: string
	event_id: string
	name: string
	description: string
	product_name: string
	weight: number
	score_text: string[]
	input_type: CategoryInputType
	position: number
	archived: boolean
}

interface UpdateCategoryRequest {
	eventID?: string
	categoryID?: string
	name?: string
	description?: string
	weight?: string
	archived?: boolean
}

interface CategoryReorderRequest {
	eventID: string
	ids: string[]
}

export const getCategory = (event_id: string, category_id: string): Action<Category[] | APIError> => {
	return {
		method: "GET",
		credentials: "include",
		endpoint: `/admin/categories/list?event_id=${event_id}&category_id=${category_id}`,
		responseType: "json",
	}
}
export const listCategories = (event_id: string): Action<Category[] | APIError> => {
	return {
		method: "GET",
		credentials: "include",
		endpoint: `/admin/categories/list?event_id=${event_id}`,
		responseType: "json",
	}
}

export const createCategory = (values: UpdateCategoryRequest): Action<Category | APIError> => {
	return {
		method: "POST",
		endpoint: `/admin/categories/add`,
		credentials: "include",
		body: {
			params: values,
		},
		responseType: "json",
	}
}
export const updateCategory = (values: UpdateCategoryRequest): Action<Category | APIError> => {
	return {
		method: "POST",
		endpoint: `/admin/categories/update`,
		credentials: "include",
		body: {
			params: values,
		},
		responseType: "json",
	}
}

export const reorderCategories = (values: CategoryReorderRequest): Action<Category | APIError> => {
	return {
		method: "POST",
		endpoint: `/admin/categories/reorder`,
		credentials: "include",
		body: { params: values },
		responseType: "json",
	}
}

export const deleteCategory = (categoryID: string): Action<any | APIError> => {
	return {
		method: "DELETE",
		endpoint: `/admin/categories/delete?category_id=${categoryID}`,
		credentials: "include",
		responseType: "json",
	}
}

export const exportCategories = (eventID: string): Action<Blob | APIError> => {
	return {
		method: "GET",
		credentials: "include",
		endpoint: `/admin/categories/export?event_id=${eventID}`,
		responseType: "blob",
	}
}

export const importCategories = (values: { eventID: string; file: Blob }): Action<APIError> => {
	const formData = new FormData()
	formData.set("file", values.file)
	return {
		method: "POST",
		credentials: "include",
		endpoint: `/admin/categories/import?event_id=${values.eventID}`,
		body: formData,
		responseType: "json",
	}
}
