import { Action } from "react-fetching-library"
import { AccountType } from "../api/enums"
import { APIError } from "../api/types"
import { Event } from "./events"

interface Address {
	id: string
	company_name?: string
	tax_number?: string
	line1?: string
	line2?: string
	city?: string
	state?: string
	country?: string
	post_or_zip?: string
	archived_on?: string
}

export interface User {
	id: string
	email: string
	verified: boolean
	account_type: number
	event_limit: number
	open_event_limit: number
	open_opinion_event_limit: number
	event_iteration_limit: number
	first_name: string
	last_name: string
	contact_number: string
	industry?: string
	credit_count: number
	created_at: string
	address?: Address
}

export interface ImpersonateUserRequest {
	id: string
}

export interface UpdateUserRequest {
	id: string
	contactNumber: string
	firstName: string
	lastName: string
	industry: string
	companyName: string
	taxNumber: string
	line1: string
	line2: string
	city: string
	state: string
	country: string
	postOrZip: string
	eventLimit?: number
	openEventLimit?: number
	openOpinionEventLimit?: number
	eventIterationLimit?: number
	creditCount?: number
}

export enum GetUsersSortBy {
	SORT_BY_CREATED_AT_ASCENDING = "SORT_BY_CREATED_AT_ASCENDING",
	SORT_BY_CREATED_AT_DESCENDING = "SORT_BY_CREATED_AT_DESCENDING",
}

export interface GetUsersFilters {
	account_types?: AccountType[]
	verified?: boolean
	credit_count?: number
	search_query?: string
}

export interface GetUsersRequest {
	filters?: GetUsersFilters
	sort_by?: GetUsersSortBy
	limit: number
	offset: number
}

export interface GetUsersResponse {
	users: User[]
	total_pages: number // indicates the total number of pages for the query
	current_page: number // indicates the current page based on limit/offset
}

export enum GetEventsSortBy {
	SORT_BY_CREATED_AT_ASCENDING = "SORT_BY_CREATED_AT_ASCENDING",
	SORT_BY_CREATED_AT_DESCENDING = "SORT_BY_CREATED_AT_DESCENDING",
}

export interface GetEventsFilters {
	archived?: boolean
	is_current?: boolean
	is_custom?: boolean
	unlocked?: boolean
	iteration?: number
	search_query?: string
}

export interface GetEventsRequest {
	filters?: GetEventsFilters
	sort_by?: GetEventsSortBy
	limit: number
	offset: number
}

export interface GetUserEventsRequest extends GetEventsRequest {
	id: string
}

export interface GetEventsResponse {
	events: Event[]
	total_pages: number // indicates the total number of pages for the query
	current_page: number // indicates the current page based on limit/offset
}

export interface EventDetails {
	name?: string
	teamDisplayName?: string
	scoreType?: number
	publicLeaderboardCombine?: boolean
	officialWeight?: number
	guestWeight?: number
	commentSectionCaption?: string
	judgeLimit?: number
	teamLimit?: number
	categoryLimit?: number
	opinionCategoryLimit?: number
	audienceParticipationLimit?: number
}

export interface UpdateEventRequest extends EventDetails {
	id: string
}

export interface CreateEventRequest extends EventDetails {
	ownerUserId: string
	shortcode: string
}

export interface UnlockEventRequest {
	id: string
}

export const impersonateUser = (request: ImpersonateUserRequest): Action<User | APIError> => ({
	method: "POST",
	endpoint: "/superadmin/users/impersonate",
	credentials: "include",
	body: request,
	responseType: "json",
})

export const getUsers = (request: GetUsersRequest): Action<GetUsersResponse> => ({
	method: "POST",
	endpoint: `/superadmin/users/list`,
	credentials: "include",
	body: request,
	responseType: "json",
})

export const getUser = (id: string): Action<User> => ({
	method: "GET",
	endpoint: `/superadmin/users?id=${id}`,
	credentials: "include",
	responseType: "json",
})

export const getUserEvents = (request: GetUserEventsRequest): Action<GetEventsResponse> => ({
	method: "POST",
	endpoint: `/superadmin/users/events/list`,
	credentials: "include",
	body: request,
	responseType: "json",
})

export const updateUser = (request: UpdateUserRequest): Action => ({
	method: "POST",
	endpoint: "/superadmin/users/update",
	credentials: "include",
	body: request,
	responseType: "json",
})

export const getEvents = (request: GetEventsRequest): Action<GetEventsResponse> => ({
	method: "POST",
	endpoint: `/superadmin/events/list`,
	credentials: "include",
	body: request,
	responseType: "json",
})

export const getEvent = (id: string): Action<Event> => ({
	method: "GET",
	endpoint: `/superadmin/events?id=${id}`,
	credentials: "include",
	responseType: "json",
})

export const createEvent = (request: CreateEventRequest): Action => ({
	method: "POST",
	endpoint: "/superadmin/events/create",
	credentials: "include",
	body: request,
	responseType: "json",
})

export const updateEvent = (request: UpdateEventRequest): Action => ({
	method: "POST",
	endpoint: "/superadmin/events/update",
	credentials: "include",
	body: request,
	responseType: "json",
})

export const unlockEvent = (request: UnlockEventRequest): Action => ({
	method: "POST",
	endpoint: "/superadmin/events/unlock",
	credentials: "include",
	body: request,
	responseType: "json",
})
