import { Action } from "react-fetching-library"
import { APIError } from "../api/types"

export interface Question {
	id: string
	event_id: string
	name: string
	type: number
	option: number
	sub_options: string
	archived: boolean
	activated: boolean
	votes: number
	voted: boolean
	sub_options_arr: string[] // array of the choices for multiple choice question
	sub_options_index: number
}

export interface QuestionUpdateRequest {
	eventID?: string
	categoryID?: string
	name?: string
	type?: number
	option?: number
	sub_options?: string
	archived?: boolean
}

export const listQuestions = (eventID: string): Action<Question[] | APIError> => {
	return {
		method: "GET",
		credentials: "include",
		endpoint: `/admin/opinion-categories/list?event_id=${eventID}`,
		responseType: "json",
	}
}

export const getQuestion = (eventID: string, id: string): Action<Question[] | APIError> => {
	return {
		method: "GET",
		credentials: "include",
		endpoint: `/admin/opinion-categories/list?event_id=${eventID}&category_id=${id}`,
		responseType: "json",
	}
}

export const createQuestion = (values: QuestionUpdateRequest): Action<Question | APIError> => {
	return {
		method: "POST",
		endpoint: `/admin/opinion-categories/add`,
		credentials: "include",
		body: { params: values },
		responseType: "json",
	}
}

export const updateQuestion = (values: QuestionUpdateRequest): Action<Question | APIError> => {
	return {
		method: "POST",
		endpoint: `/admin/opinion-categories/update`,
		credentials: "include",
		body: { params: values },
		responseType: "json",
	}
}

export const deleteQuestion =
	(eventID: string) =>
	(id: string): Action<any | APIError> => {
		return {
			method: "DELETE",
			endpoint: `/admin/opinion-categories/delete?event_id=${eventID}&category_id=${id}`,
			credentials: "include",
			responseType: "json",
		}
	}

export const pushQuestion =
	(eventID: string) =>
	(id: string): Action => {
		return {
			method: "POST",
			endpoint: `/admin/opinion-categories/activate?event_id=${eventID}&category_id=${id}`,
			credentials: "include",
			responseType: "json",
		}
	}
