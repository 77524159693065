import { useStyletron } from "baseui"
import { Button } from "baseui/button"
import { StyledAction, StyledBody } from "baseui/card"
import { FormControl } from "baseui/form-control"
import { Input } from "baseui/input"
import { Modal, ModalBody, ModalButton, ModalFooter, ModalHeader } from "baseui/modal"
import { Notification } from "baseui/notification"
import React from "react"
import { useMutation } from "react-fetching-library"
import { useForm } from "react-hook-form"
import { useHistory } from "react-router-dom"
import { APIError, isAPIError, User } from "../api/types"
import { ErrorNotification } from "../components/common"
import { ForgotPasswordModal, ResetPasswordModal } from "../components/forgotPassword"
import { Spread } from "../components/spread"
import { VersionText } from "../components/version"
import { ResendVerifyEmail, VerifyEmail, VerifyResetToken } from "../lib/auth"
import { CommonContainer } from "../lib/controller"
import { AccentButtonOverrides, GreyButtonOverrides } from "../themeOverrides"

export const SignIn = () => {
	const [css] = useStyletron()
	const history = useHistory()

	const urlArgs = new URLSearchParams(history.location.search)
	const fromLink = urlArgs.get("link") === "true"
	const tokenInURL = urlArgs.get("token") || ""

	const jfLogo = require("../assets/images/jfLogo.png")

	const parentStyle = css({
		display: "flex",
		flexDirection: "column",
		minHeight: "100vh",
		overflow: "auto",
		justifyContent: "center",
		alignItems: "center",
		background: "linear-gradient(180deg, rgba(0,104,157,1) 0%, rgba(97,88,204,1) 100%)",
	})
	const image = css({
		minHeight: "0",
		textAlign: "center",
		width: "200px",
		paddingBottom: "50px",
	})
	const cardStyle = css({
		minWidth: "320px",
		"@media only screen and (max-width: 320px)": {
			minWidth: "unset",
		},
	})
	const btnStyle = css({
		display: "flex",
		flexDirection: "column",
		paddingBottom: "20px",
	})
	const inputStyle = css({
		margin: "10px 0 10px 0",
	})
	const versionWrap = css({
		position: "absolute",
		right: "6px",
		bottom: "10px",
		"@media only screen and (max-height: 580px) and (max-width: 580px)": {
			display: "none",
		},
	})

	const { signIn } = CommonContainer.useContainer()
	const [error, setError] = React.useState<string>()
	const [errors, setErrors] = React.useState<boolean[]>([false, false, false])
	const [email, setEmail] = React.useState<string>(urlArgs.get("email") || "")
	const [password, setPassword] = React.useState<string>("")
	const [token, setToken] = React.useState<string>(tokenInURL)

	// Password Resetting
	const [showForgotPass, setShowForgotPass] = React.useState(false)
	const [showResetPass, setShowResetPass] = React.useState(false)
	const [verifyPasswordReset, setVerifyPasswordReset] = React.useState(false)
	const {
		mutate: verifyResetToken,
		loading: verifyingResetToken,
		payload: verifyResetTokenPayload,
		error: verifyResetTokenError,
	} = useMutation(VerifyResetToken)

	// Email Verification
	const [showResendDialog, setShowResendDialog] = React.useState(false)
	const [showVerifyDialog, setShowVerifyDialog] = React.useState(urlArgs.get("verify") === "true")
	const { mutate: resendVerifyEmail, loading: resending, payload: resendPayload, error: resendError } = useMutation<APIError>(ResendVerifyEmail)
	const { mutate: verifyEmail, loading: verifying, payload: verifyPayload, error: verifyError } = useMutation(VerifyEmail)
	const { handleSubmit } = useForm<FormData>()

	const { setUser } = CommonContainer.useContainer()

	const onSignIn = async () => {
		if (email === "" || password === "") {
			setErrors([email === "", password === "", false])
			return
		}
		setErrors([false, false, false])

		const resp = await signIn(email, password)
		if (!resp) {
			localStorage.clear()
			history.replace("/")
			window.location.reload()
			return
		}
		setError(resp)
		if (resp === "User not verified") setShowResendDialog(true)
	}

	const onVerify = handleSubmit(async () => {
		if (email === "" || token.length < 6) {
			setErrors([email === "", false, token.length < 6])
			return
		}
		setErrors([false, false, false])

		if (verifyPasswordReset) {
			const resp = await verifyResetToken({ email, token })
			if (!resp.error) {
				setShowVerifyDialog(false)
				setShowResetPass(true)
			}
		} else {
			const resp = await verifyEmail({ email, token })
			if (!resp.error) {
				setUser(resp.payload as User)
				setTimeout(() => {
					history.replace("/")
					window.location.reload()
				}, 3000)
			}
		}
	})

	React.useEffect(() => {
		const loginOnValidated = async () => {
			if (showVerifyDialog && fromLink && tokenInURL) {
				const resp = await verifyEmail({ email, token })
				if (!resp.error) {
					setUser(resp.payload as User)
					setTimeout(() => {
						history.replace("/")
						window.location.reload()
					}, 3000)
				}
			}
		}
		loginOnValidated()
	}, [showVerifyDialog, fromLink, email, history, token, tokenInURL, verifyEmail, setUser])

	return (
		<div className={parentStyle}>
			<div style={{ minHeight: "0px" }}>
				<img onClick={() => history.push("/")} className={image} src={jfLogo} alt="JudgeFest" />
			</div>

			<form
				className={cardStyle}
				onSubmit={(e) => {
					e.preventDefault()
					onSignIn()
				}}
			>
				<StyledBody>
					{error && <ErrorNotification message={error} />}
					<div className={inputStyle}>
						<Input
							type="email"
							value={email}
							onChange={(e) => {
								const target = e.target as HTMLTextAreaElement
								setEmail(target.value)
							}}
							placeholder="Email"
							error={errors[0]}
						/>
					</div>
					<div className={inputStyle}>
						<Input
							type="password"
							value={password}
							onChange={(e) => {
								const target = e.target as HTMLTextAreaElement
								setPassword(target.value)
							}}
							placeholder="Password"
							error={errors[1]}
						/>
					</div>
				</StyledBody>
				<StyledAction>
					{process.env.NODE_ENV !== "production" && (
						<Button
							onClick={async () => {
								const resp = await signIn("test@example.com", "NinjaDojo_!")
								if (!resp) {
									history.replace("/")
									window.location.reload()
								} else setError(resp)
							}}
							type="button"
							overrides={{
								Root: {
									style: {
										position: "absolute",
										right: "10px",
										top: "20px",
									},
								},
							}}
						>
							cheat
						</Button>
					)}
					<div className={btnStyle}>
						<Button overrides={AccentButtonOverrides}>Sign in</Button>

						<Spread>
							<Button onClick={() => history.push("/register")} overrides={GreyButtonOverrides} type="button">
								No Account? Sign up
							</Button>
							<Button onClick={() => setShowForgotPass(true)} overrides={GreyButtonOverrides} type="button">
								Forgot Password
							</Button>
						</Spread>
					</div>
				</StyledAction>
			</form>

			<div className={versionWrap}>
				<VersionText />
			</div>

			{/* Resend Dialog */}
			<Modal isOpen={showResendDialog} onClose={() => setShowResendDialog(false)} closeable>
				<ModalHeader>Resend verification email?</ModalHeader>
				<ModalBody>
					<p>This account is not currently verified, resend verification email?</p>
					<Input
						type="email"
						value={email}
						onChange={(e) => {
							const target = e.target as HTMLTextAreaElement
							setEmail(target.value)
						}}
						placeholder="Email"
						error={errors[0]}
					/>
					{resendError && <ErrorNotification message={resendPayload?.shortMessage || "An error occurred."} />}
				</ModalBody>

				<ModalFooter>
					<ModalButton
						isLoading={resending}
						onClick={async () => {
							const resp = await resendVerifyEmail(email)
							if (resp.error) return
							setShowResendDialog(false)
							setShowVerifyDialog(true)
						}}
					>
						Resend
					</ModalButton>
				</ModalFooter>
			</Modal>

			{/* Verify Dialog */}
			<Modal isOpen={showVerifyDialog} onClose={() => setShowVerifyDialog(false)} closeable>
				<form onSubmit={onVerify}>
					<ModalHeader>{verifyPasswordReset ? "Reset Password" : "Verify Email"}</ModalHeader>
					<ModalBody>
						{(!verifyPayload || verifyError) && (
							<>
								<p>Enter Verification Code</p>
								<p style={{ color: "grey" }}>{`(An email has been sent to ${email})`}</p>
								<FormControl>
									<Input
										type="email"
										value={email}
										onChange={(e) => {
											const target = e.target as HTMLTextAreaElement
											setEmail(target.value)
										}}
										placeholder="Email"
										error={errors[0]}
									/>
								</FormControl>
								<FormControl error={errors[2] ? "Please enter a valid code" : ""}>
									<Input
										value={token}
										onChange={(e) => {
											const target = e.target as HTMLTextAreaElement
											setToken(target.value)
										}}
										onBlur={(e) => setToken(e.currentTarget.value.toUpperCase())}
										placeholder="Token"
										error={errors[2]}
									/>
								</FormControl>
							</>
						)}
						{verifyPasswordReset ? (
							<>{verifyResetTokenError && isAPIError(verifyResetTokenPayload) && <ErrorNotification message={verifyResetTokenPayload.shortMessage} />}</>
						) : (
							<>
								{verifyError && isAPIError(verifyPayload) && <ErrorNotification message={verifyPayload.shortMessage} />}
								{!verifyError && verifyPayload && <Notification kind="positive">Account Verified. You will be redirected in 3 seconds.</Notification>}
							</>
						)}
					</ModalBody>

					<ModalFooter>
						{(!verifyPayload || verifyError) && (
							<ModalButton isLoading={verifying || verifyingResetToken} onClick={onVerify}>
								Verify
							</ModalButton>
						)}
					</ModalFooter>
				</form>
			</Modal>

			<ForgotPasswordModal
				isOpen={showForgotPass}
				onClose={(email) => {
					setShowForgotPass(false)
					if (!!email) {
						setEmail(email)
						setVerifyPasswordReset(true)
						setShowVerifyDialog(true)
					}
				}}
			/>
			<ResetPasswordModal token={token} isOpen={showResetPass} onClose={() => setShowResetPass(false)} />
		</div>
	)
}
