import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { useStyletron } from "baseui"
import { Button } from "baseui/button"
import { Card } from "baseui/card"
import { Checkbox } from "baseui/checkbox"
import { FileUploader } from "baseui/file-uploader"
import { FormControl } from "baseui/form-control"
import { Input } from "baseui/input"
import { Select } from "baseui/select"
import { Slider } from "baseui/slider"
import { Tab, Tabs } from "baseui/tabs-motion"
import { Textarea } from "baseui/textarea"
import * as React from "react"
import { useMutation, useQuery } from "react-fetching-library"
import { Controller, useForm } from "react-hook-form"
import { useHistory } from "react-router-dom"
import { EventType, EventTypeNames, ScoreType, ScoreTypeNames, TeamDisplayNameOptions } from "../../api/enums"
import { APIError, isAPIError, User } from "../../api/types"
import { ErrorNotification } from "../../components/common"
import { Spaced } from "../../components/spaced"
import { createEvent, Event, getEventUsers, updateEvent } from "../../lib/events"
import { NumberSliderOverrides, VerticalTabOverrides } from "../../themeOverrides"

interface EventInput {
	teamDisplayName: string
	publicLeaderboard: boolean
	publicLeaderboardLimit: number
	publicLeaderboardCombine: boolean
	officialWeight: number
	guestWeight: number
	judgeType: EventType
	scoreType: ScoreType
	logo?: File
	commentSectionCaption: string
	ownerID?: string
	minPossibleScore: number
	avgOverallScores: boolean
}

interface selectObj {
	id: string
	label: string
}

const required = { required: { value: true, message: "Required" } }

const scoreTypeOptions = ScoreTypeNames.map((s, i) => ({ id: i as ScoreType, label: s }))
const eventTypeOptions = EventTypeNames.map((s, i) => ({ id: i as EventType, label: s }))
const teamDisplayNameOptions = TeamDisplayNameOptions.map((s, i) => ({ id: i, label: s }))

const fileToBase64 = (file: File) =>
	new Promise<string>((resolve, reject) => {
		const reader = new FileReader()
		reader.readAsDataURL(file)
		reader.onload = () => {
			let encoded = (reader.result as string).toString().replace(/^data:(.*,)?/, "")
			if (encoded.length % 4 > 0) {
				encoded += "=".repeat(4 - (encoded.length % 4))
			}
			resolve(encoded)
		}
		reader.onerror = (error) => reject(error)
	})

export const EventEdit = (props: { event?: Event; isNew?: boolean; stopEditMode: (event?: Event) => void }) => {
	const { event, stopEditMode } = props
	const isNew = props.isNew === true
	const history = useHistory()

	// Setup form
	const { control, register, handleSubmit, setValue, trigger, errors, watch } = useForm<EventInput>({
		defaultValues: {
			teamDisplayName: TeamDisplayNameOptions[0],
			publicLeaderboard: false,
			publicLeaderboardLimit: 0,
			publicLeaderboardCombine: true,
			officialWeight: 100,
			guestWeight: 50,
			judgeType: EventType.Default,
			scoreType: ScoreType.Whole,
			logo: undefined,
			commentSectionCaption: "",
			minPossibleScore: 0,
			avgOverallScores: true,
		},
		shouldUnregister: false,
	})
	const { mutate: update, loading, error, errorObject, payload } = useMutation(isNew ? createEvent : updateEvent)
	const { payload: getUsersPayload, loading: getUsersLoading } = useQuery<User[]>(getEventUsers(event?.id || ""), !!event)

	const eventType = watch("judgeType")

	const onSaveForm = handleSubmit(async (input) => {
		if (!trigger()) return

		let logo: string | undefined
		if (!!input.logo) logo = await fileToBase64(input.logo)

		const data = {
			teamdisplayname: input.teamDisplayName ? input.teamDisplayName : "Team",
			publicleaderboard: input.publicLeaderboard,
			publicLeaderboardLimit: +input.publicLeaderboardLimit, // '+' to convert to number - for some reason when you type it transforms the type from number to string :S
			publicLeaderboardCombine: input.publicLeaderboardCombine,
			officialWeight: input.officialWeight,
			guestWeight: input.guestWeight,
			judgetype: input.judgeType,
			scoretype: input.scoreType,
			logo: logo,
			commentSectionCaption: input.commentSectionCaption,
			minPossibleScore: input.minPossibleScore,
			avgOverallScores: input.avgOverallScores,
			ownerUserId: input.ownerID,
		}

		const resp = await update(!!event ? { id: event.id, ...data } : data)
		// Error?
		if (resp.error) return
		if ((resp.error && isAPIError(resp.payload)) || (resp.payload as APIError).error) return
		const updatedEvent = resp.payload as Event
		// On Success
		if (updatedEvent) stopEditMode(updatedEvent)
	})

	const [users, setUsers] = React.useState<selectObj[]>([])

	React.useEffect(() => {
		if (getUsersLoading) return
		if (getUsersPayload) {
			const changeOwnerOptions = getUsersPayload.map((u) => {
				return { id: u.id, label: u.email }
			})
			setUsers(changeOwnerOptions)
		}
	}, [getUsersPayload, getUsersLoading, setUsers])

	// Load defaults
	React.useEffect(() => {
		if (!event) return
		setValue("newShortcode", event.shortcode)
		setValue("teamDisplayName", event.team_display_name)
		setValue("publicLeaderboard", event.public_leaderboard)
		setValue("publicLeaderboardLimit", event.public_leaderboard_limit)
		setValue("publicLeaderboardCombine", event.public_leaderboard_combine)
		setValue("officialWeight", event.official_weight)
		setValue("guestWeight", event.guest_weight)
		setValue("judgeType", event.judge_type)
		setValue("scoreType", event.score_type)
		setValue("commentSectionCaption", event.comment_section_caption)
		setValue("minPossibleScore", event.min_possible_score)
		setValue("avgOverallScores", event.avg_overall_scores)
		setValue("ownerID", event.owner_user_id)
	}, [event, setValue])

	const [activeKey, setActiveKey] = React.useState(history.location.hash || "#general")
	React.useEffect(() => {
		setActiveKey(history.location.hash || "#general")
	}, [history.location.hash])

	// Styling
	const [css] = useStyletron()
	const containerStyle = css({
		padding: "10px",
	})
	const footerStyle = css({
		display: "flex",
		flexDirection: "row-reverse",
	})
	const previewImageStyle = css({
		width: "100%",
	})

	return (
		<form onSubmit={onSaveForm} className={containerStyle}>
			{error && <ErrorNotification message={isAPIError(payload) ? payload.shortMessage : `${errorObject}`} />}

			<Tabs
				onChange={({ activeKey }: any) => {
					setActiveKey(activeKey)
					history.push({ hash: activeKey, search: !props.isNew ? "?edit=true" : undefined })
				}}
				orientation="vertical"
				activeKey={activeKey}
				overrides={{
					TabHighlight: {
						style: {
							width: "3px",
							marginRight: "7px",
						},
					},
					TabBorder: {
						style: { width: "1px" },
					},
					TabList: {
						style: {
							width: "15%",
							minWidth: "160px",
						},
					},
				}}
			>
				<Tab title="General" key="#general" overrides={VerticalTabOverrides}>
					<FormControl label="Shortcode">
						<Input value={event?.shortcode} disabled />
					</FormControl>
					<FormControl label="Event Type" error={errors.judgeType && errors.judgeType.message}>
						<Controller
							name="judgeType"
							control={control}
							rules={required}
							render={({ onChange, value }) => {
								const v = eventTypeOptions.find((i) => i.id === value)
								return (
									<Select
										options={eventTypeOptions}
										onChange={({ value }) => {
											if (!value[0]) return
											onChange(value[0].id)
										}}
										value={v ? [v] : []}
										clearable={false}
										error={errors.judgeType !== undefined}
										disabled={loading}
										searchable={false}
									/>
								)
							}}
						/>
					</FormControl>

					{eventType === EventType.AudienceParticipation && (
						<FormControl error={errors.publicLeaderboard?.message} positive="">
							<Controller
								name="publicLeaderboard"
								control={control}
								defaultValue={false}
								render={({ value, onChange }) => (
									<Checkbox checked={value} onChange={(e) => onChange(e.currentTarget.checked)} disabled={loading}>
										Public Dashboard
									</Checkbox>
								)}
							/>
						</FormControl>
					)}
				</Tab>

				{eventType !== EventType.AudienceParticipation && (
					<Tab title="Scoring" key="#scoring" overrides={VerticalTabOverrides}>
						<FormControl label="Score Type" error={errors.scoreType && errors.scoreType.message}>
							<Controller
								name="scoreType"
								control={control}
								rules={required}
								render={({ onChange, value }) => {
									const v = scoreTypeOptions.find((i) => i.id === value)
									return (
										<Select
											options={scoreTypeOptions}
											onChange={({ value }) => value[0] && onChange(value[0].id)}
											value={v ? [v] : []}
											clearable={false}
											error={errors.scoreType !== undefined}
											disabled={loading}
											searchable={false}
										/>
									)
								}}
							/>
						</FormControl>

						<FormControl
							error={errors.avgOverallScores?.message}
							positive=""
							caption="If un-checked, scores on the leaderboard and report cards will ignore category weights and not be averaged (out of 10). Instead they will be out of a total based on the number of categories times 10."
						>
							<Controller
								name="avgOverallScores"
								control={control}
								defaultValue={false}
								render={({ value, onChange }) => (
									<Checkbox checked={value} onChange={(e) => onChange(e.currentTarget.checked)} disabled={loading}>
										Average Overall Scores
									</Checkbox>
								)}
							/>
						</FormControl>

						<FormControl error={errors.minPossibleScore?.message} positive="" caption="If checked, judges can move slider down to and rate categories 0">
							<Controller
								name="minPossibleScore"
								control={control}
								defaultValue={false}
								render={({ value, onChange }) => (
									<Checkbox checked={value === 0} onChange={(e) => onChange(e.currentTarget.checked ? 0 : 1)} disabled={loading}>
										Judges can vote 0
									</Checkbox>
								)}
							/>
						</FormControl>

						{eventType === EventType.Default && (
							<>
								<Card>
									<FormControl label="Official Judge Score Weight" error="" positive="">
										<Controller
											name="officialWeight"
											control={control}
											onChange={([{ value }]: any) => value}
											defaultValue={5}
											render={({ value, onChange }) => {
												return (
													<>
														<Input
															name="officialWeight"
															value={value}
															onChange={(e) => {
																let v = +e.currentTarget.value
																if (v < 0) v = 0
																else if (v > 100) v = 100
																onChange(v)
															}}
															type="number"
															disabled={loading}
														/>
														<Slider value={[value]} onChange={(e) => onChange(e.value[0])} min={0} max={100} overrides={NumberSliderOverrides} />
													</>
												)
											}}
										/>
									</FormControl>
								</Card>
								<br />

								<Card>
									<FormControl label="Guest Judge Score Weight" error="" positive="">
										<Controller
											name="guestWeight"
											control={control}
											onChange={([{ value }]: any) => value}
											defaultValue={5}
											render={({ value, onChange }) => {
												return (
													<>
														<Input
															name="guestWeight"
															value={value}
															onChange={(e) => {
																let v = +e.currentTarget.value
																if (v < 0) v = 0
																else if (v > 100) v = 100
																onChange(v)
															}}
															type="number"
															disabled={loading}
														/>
														<Slider value={[value]} onChange={(e) => onChange(e.value[0])} min={0} max={100} overrides={NumberSliderOverrides} />
													</>
												)
											}}
										/>
									</FormControl>
								</Card>
							</>
						)}
					</Tab>
				)}

				{eventType !== EventType.AudienceParticipation && (
					<Tab title="Leaderboard" key="#leaderboard" overrides={VerticalTabOverrides}>
						<FormControl error={errors.publicLeaderboard?.message} positive="">
							<Controller
								name="publicLeaderboard"
								control={control}
								defaultValue={false}
								render={({ value, onChange }) => (
									<Checkbox checked={value} onChange={(e) => onChange(e.currentTarget.checked)} disabled={loading}>
										Public Leaderboard
									</Checkbox>
								)}
							/>
						</FormControl>

						<FormControl
							label="Public Leaderboard Limit"
							error={errors.publicLeaderboardLimit?.message}
							positive=""
							caption="Limit the public leaderboard to only show Top # (0 = no limit)"
						>
							<Input name="publicLeaderboardLimit" inputRef={register} type="number" min={0} disabled={loading} />
						</FormControl>

						{eventType === EventType.Default && (
							<FormControl error={errors.publicLeaderboardCombine?.message} positive="" caption="Show Official and Guest Judge scores separately or combined">
								<Controller
									name="publicLeaderboardCombine"
									control={control}
									defaultValue={false}
									render={({ value, onChange }) => (
										<Checkbox checked={value} onChange={(e) => onChange(e.currentTarget.checked)} disabled={loading}>
											Combine Official & Guest Scores
										</Checkbox>
									)}
								/>
							</FormControl>
						)}
					</Tab>
				)}

				{eventType !== EventType.AudienceParticipation && (
					<Tab title="Misc" key="#misc" overrides={VerticalTabOverrides}>
						<FormControl label="Team Display Name" error={errors.teamDisplayName && errors.teamDisplayName.message}>
							<Controller
								name="teamDisplayName"
								control={control}
								rules={required}
								render={({ onChange, value }) => {
									const v = teamDisplayNameOptions.find((i) => i.label === value) || teamDisplayNameOptions[teamDisplayNameOptions.length - 1]
									return (
										<>
											<Select
												options={teamDisplayNameOptions}
												onChange={({ value }) => {
													if (value[0] && value[0].label === "Other") {
														onChange("")
														return
													}
													value[0] && onChange(value[0].label)
												}}
												value={v ? [v] : []}
												clearable={false}
												disabled={loading}
												searchable={false}
											/>
											{v.id === teamDisplayNameOptions.length - 1 && (
												<Input
													value={value}
													onChange={onChange}
													disabled={loading}
													placeholder="Teams"
													overrides={{
														Root: {
															style: {
																marginTop: "10px",
															},
														},
													}}
												/>
											)}
										</>
									)
								}}
							/>
						</FormControl>

						<FormControl
							label="Comment Section Caption"
							error={errors.commentSectionCaption?.message}
							positive=""
							caption={`The caption text shown in the "Additional Comments" section`}
						>
							<Controller
								name="commentSectionCaption"
								defaultValue=""
								control={control}
								as={Textarea}
								overrides={{
									Input: {
										style: {
											resize: "vertical",
											minHeight: "46px",
										},
									},
								}}
								disabled={loading}
							/>
						</FormControl>

						<FormControl label="Change Event Owner" error={errors.ownerID?.message} positive="" caption={``}>
							<Controller
								name="ownerID"
								control={control}
								rules={required}
								render={({ onChange, value }) => {
									const v = users.find((e) => e.id === value)
									return (
										<Select
											options={users}
											onChange={({ value }) => value[0] && onChange(value[0].id)}
											value={v ? [v] : []}
											clearable={false}
											error={errors.ownerID !== undefined}
											disabled={getUsersLoading}
											searchable={false}
										/>
									)
								}}
							/>
						</FormControl>
					</Tab>
				)}

				<Tab title="Logo" key="#logo" overrides={VerticalTabOverrides}>
					<FormControl label="Logo">
						<Controller
							name="logo"
							control={control}
							defaultValue={null}
							render={({ value, onChange }) => {
								const previewImage = value ? URL.createObjectURL(value) : undefined
								return (
									<>
										<FileUploader
											multiple={false}
											accept={".jpg, .jpeg, .png, .svg, .gif, .bmp"}
											onDrop={(acceptedFiles) => {
												if (acceptedFiles.length === 0) return
												onChange(acceptedFiles[0])
											}}
										/>
										{previewImage && <img src={previewImage} alt={value.name} className={previewImageStyle} />}
										{!previewImage && event?.logo && <img src={`data:image/jpeg;base64,${event.logo}`} className={previewImageStyle} alt="Logo" />}
									</>
								)
							}}
						/>
					</FormControl>
				</Tab>
			</Tabs>

			<div className={footerStyle}>
				<Spaced>
					<Button onClick={() => stopEditMode()} kind="secondary">
						Cancel
					</Button>
					<Button onClick={onSaveForm} isLoading={loading} startEnhancer={<FontAwesomeIcon icon={["fas", "save"]} />}>
						Save
					</Button>
				</Spaced>
			</div>
		</form>
	)
}
