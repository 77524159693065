import { Action } from "react-fetching-library"
import { APIError, TeamScore, TeamAssessment } from "../api/types"

interface UpdateTeamAssessment {
	teamID: string
	judgeID: string
	scores: TeamScore[]
	comment: string
}

export const getTeamAssessment = (teamID: string, judgeID: string): Action<TeamAssessment[] | APIError> => ({
	method: "GET",
	credentials: "include",
	endpoint: `/admin/scores/list?team_id=${teamID}&judge_id=${judgeID}`,
	responseType: "json",
})

export const listTeamAssessments = (teamID: string): Action<TeamAssessment[] | APIError> => ({
	method: "GET",
	credentials: "include",
	endpoint: `/admin/scores/list?team_id=${teamID}`,
	responseType: "json",
})

export const createTeamAssessment = (values: UpdateTeamAssessment): Action<APIError> => ({
	method: "POST",
	endpoint: `/admin/scores/add`,
	credentials: "include",
	body: {
		params: values,
	},
	responseType: "json",
})

export const updateTeamAssessment = (values: UpdateTeamAssessment): Action<APIError> => ({
	method: "POST",
	endpoint: `/admin/scores/update`,
	credentials: "include",
	body: {
		params: values,
	},
})

export const deleteTeamAssessment = (values: { teamID: string; judgeID: string }): Action<APIError> => ({
	method: "DELETE",
	endpoint: `/admin/scores/delete?team_id=${values.teamID}&judge_id=${values.judgeID}`,
	credentials: "include",
})
