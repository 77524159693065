import { Action } from "react-fetching-library"
import { Address, APIError, Invoice } from "../api/types"

export const ChangeDetails = (values: { email: string; firstName: string; lastName: string; contactNumber: string; industry: string }): Action<APIError> => ({
	method: "POST",
	endpoint: "/auth/user/update_details",
	body: { params: values },
	credentials: "include",
	responseType: "json",
})
export const ChangePassword = (values: { password: string; newPassword: string }): Action<APIError> => ({
	method: "POST",
	endpoint: "/auth/user/update_password",
	body: { params: values },
	credentials: "include",
	responseType: "json",
})

export const ChangeAddress = (values: Address): Action<APIError> => {
	return {
		method: "POST",
		endpoint: "/auth/user/update_address",
		body: { params: values },
		credentials: "include",
		responseType: "json",
	}
}

export const getInvoices = (): Action<Invoice[] | APIError> => {
	return {
		method: "GET",
		credentials: "include",
		endpoint: `/auth/user/invoices`,
		responseType: "json",
	}
}