import { Action } from "react-fetching-library"
import { APIError } from "../api/types"
import { Judge } from "./judges"
import { Team } from "./teams"

export interface Group {
	id: string
	eventID: string
	name: string
	assigned_teams: Team[]
	assigned_judges: Judge[]
}

export interface GroupCreateRequest {
	eventID: string
	name: string
	assignedTeamIDs: string[]
	assignedJudgeIDs: string[]
}

export interface GroupUpdateRequest {
	groupID: string
	eventID: string
	newname: string
	assignedTeamIDs: string[]
	assignedJudgeIDs: string[]
}

export interface GroupDeleteRequest {
	groupID: string
	eventID: string
}

export interface GroupJudgeAddRequest {
	judgeID: string
	eventID: string
}

export interface GroupJudgeDeleteRequest {
	judgeID: string
	eventID: string
}

export const getGroup = (groupID: string, eventID: string): Action<Group | APIError> => {
	return {
		method: "GET",
		endpoint: `/admin/groups/${groupID}/?event_id=${eventID}`,
		credentials: "include",
		responseType: "json",
	}
}

export const listGroups = (eventID: string): Action<Group | APIError> => {
	return {
		method: "GET",
		endpoint: `/admin/groups/list/?event_id=${eventID}`,
		credentials: "include",
		responseType: "json",
	}
}

export const createGroup = (values: GroupCreateRequest): Action<Group | APIError> => {
	return {
		method: "POST",
		endpoint: "/admin/groups/add",
		credentials: "include",
		body: { params: values },
		responseType: "json",
	}
}

export const updateGroup = (values: GroupUpdateRequest): Action<Group | APIError> => {
	return {
		method: "POST",
		endpoint: "/admin/groups/update",
		credentials: "include",
		body: { params: values },
		responseType: "json",
	}
}

export const deleteGroup = (values: GroupDeleteRequest): Action<Group | APIError> => {
	return {
		method: "DELETE",
		endpoint: "/admin/groups/delete",
		credentials: "include",
		body: { params: values },
		responseType: "json",
	}
}

export const listGroupTeams = (groupID: string, eventID: string): Action<Team[] | APIError> => {
	return {
		method: "GET",
		endpoint: `/admin/groups/${groupID}/teams/?event_id=${eventID}`,
		credentials: "include",
		responseType: "json",
	}
}

export const listGroupJudges = (groupID: string, eventID: string): Action<Judge[] | APIError> => {
	return {
		method: "GET",
		endpoint: `/admin/groups/${groupID}/judges/?event_id=${eventID}`,
		credentials: "include",
		responseType: "json",
	}
}
