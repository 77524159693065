import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { useStyletron } from "baseui"
import { Button } from "baseui/button"
import { Checkbox } from "baseui/checkbox"
import { FormControl } from "baseui/form-control"
import { Input } from "baseui/input"
import { Modal, ModalBody, ModalButton, ModalFooter, ModalHeader } from "baseui/modal"
import { Select } from "baseui/select"
import { H1, LabelMedium } from "baseui/typography"
import * as React from "react"
import { useMutation, useQuery } from "react-fetching-library"
import { Controller, useForm } from "react-hook-form"
import { RouteComponentProps, useHistory } from "react-router-dom"
import { APIError, isAPIError } from "../../api/types"
import { ErrorNotification, InlineLabel } from "../../components/common"
import { Loading } from "../../components/loading"
import { Spaced } from "../../components/spaced"
import { Spread } from "../../components/spread"
import { CommonContainer } from "../../lib/controller"
import { Group, listGroups } from "../../lib/groups"
import { createJudge, deleteJudge, generateJudgePin, getJudge, Judge, updateJudge } from "../../lib/judges"
import { listTeams, Team } from "../../lib/teams"

export const JudgePage = (props: RouteComponentProps<{ id: string }>) => {
	// Styling
	const [css, theme] = useStyletron()
	const containerStyle = css({
		maxWidth: "900px",
		margin: "10px auto",
	})
	const nameStyle = css({
		...theme.typography.HeadingSmall,
	})
	const contentStyle = css({
		marginTop: "20px",
	})
	const detailStyle = css({
		padding: "10px",
		display: "grid",
		gridTemplateColumns: "150px auto",
	})
	const detailAltStyle = css({
		padding: "10px",
		display: "grid",
		gridTemplateColumns: "150px auto",
		backgroundColor: theme.colors.backgroundSecondary,
		borderRadius: "4px",
	})
	const guestHelpStyle = css({
		marginLeft: "5px",
		color: "grey",
		fontSize: "14px",
	})
	const headerStyle = css({
		display: "none",
		"@media only screen and (max-width: 700px)": {
			display: "flex",
		},
	})

	const id = props.match.params.id
	const isNew = id === "create"

	const { eventID, event } = CommonContainer.useContainer()
	const history = useHistory()

	// Get Judge
	const [judge, setJudge] = React.useState<Judge>()
	const { error, payload, loading, query: refresh } = useQuery<Judge[]>(getJudge(eventID, id), !isNew)

	React.useEffect(() => {
		if (!payload || payload.length === 0) return
		setJudge(payload[0])
	}, [payload, loading, error])

	// Edit Mode
	const [editMode, _setEditMode] = React.useState(isNew)
	const setEditMode = (value: boolean) => {
		_setEditMode(value)
		history.replace({ search: value ? "edit=true" : undefined })
	}
	React.useEffect(() => {
		// Set edit mode based on edit search param
		if (isNew) return
		const params = new URLSearchParams(history.location.search)
		_setEditMode(params.get("edit") === "true")
	}, [history.location.search, isNew])

	const onEditComplete = (updatedJudge?: Judge) => {
		if (updatedJudge) {
			history.push(`/judges/updated/${updatedJudge.id}`)
			setEditMode(false)
			return
		}
		history.push(`/judges`)
		setEditMode(false)
	}

	// Archiving
	const { mutate, loading: mutating } = useMutation(updateJudge(eventID))
	const onArchive = () =>
		mutate({
			id,
			archived: true,
		}).then(() => refresh())
	const onUnarchive = () =>
		mutate({
			id,
			archived: false,
		}).then(() => refresh())

	// Deleting
	const { mutate: mutateDelete, loading: deleting, error: deleteError, payload: deletePayload } = useMutation(deleteJudge(eventID))
	const [showDeleteModal, setShowDeleteModal] = React.useState(false)

	// Edit Mode?
	if (editMode)
		return (
			<div className={containerStyle}>
				<div className={headerStyle}>
					<H1>Judges</H1>
				</div>
				<div className={nameStyle}>{judge ? judge.email : "New Judge"}</div>
				<JudgeEdit judge={judge} isNew={isNew} stopEditMode={onEditComplete} />
			</div>
		)

	// Loading
	if (!judge && loading) return <Loading />
	if (!judge) return <></>

	let details: { label: String; value: React.ReactNode }[] = [
		{ label: "Email", value: judge.email },
		{ label: "PIN", value: judge.is_guest ? "GUEST" : judge.pin },
	]
	if (judge.is_guest)
		details.push({
			label: "Guest",
			value: (
				<span>
					<FontAwesomeIcon icon={["fal", "check"]} /> <span className={guestHelpStyle}>{`(this is a guest judge who signed into this event themselves)`}</span>
				</span>
			),
		})

	return (
		<div className={containerStyle}>
			<Spread>
				<div className={nameStyle}>{judge.email}</div>

				{/* Actions */}
				{event?.is_current && (
					<Spaced>
						{!judge.archived && (
							<Button onClick={() => setEditMode(true)} startEnhancer={<FontAwesomeIcon icon={["fas", "edit"]} />}>
								Edit
							</Button>
						)}
						{!judge.archived && (
							<Button onClick={onArchive} isLoading={mutating} startEnhancer={<FontAwesomeIcon icon={["fas", "archive"]} />}>
								Archive
							</Button>
						)}
						{judge.archived && (
							<Button onClick={onUnarchive} isLoading={mutating} startEnhancer={<FontAwesomeIcon icon={["fas", "undo"]} />}>
								Unarchive
							</Button>
						)}
						{judge.archived && (
							<Button onClick={() => setShowDeleteModal(true)} isLoading={mutating} startEnhancer={<FontAwesomeIcon icon={["fas", "trash-alt"]} />}>
								Delete
							</Button>
						)}
					</Spaced>
				)}
			</Spread>

			{/* Details */}
			<div className={contentStyle}>
				{details.map((detail, index) => (
					<div key={`judge-detail-${detail.label}`} className={index % 2 === 0 ? detailAltStyle : detailStyle}>
						<LabelMedium $style={{ fontWeight: 600 }}>{`${detail.label}:`}</LabelMedium>
						<LabelMedium>{detail.value}</LabelMedium>
					</div>
				))}
			</div>

			{/* Delete Modal */}
			<Modal onClose={() => setShowDeleteModal(false)} isOpen={showDeleteModal}>
				<ModalHeader>Delete Judge?</ModalHeader>
				<ModalBody>
					<p>Are you sure you want to delete this judge?</p>
					{deleteError && isAPIError(deletePayload) && <ErrorNotification message={deletePayload.shortMessage} />}
				</ModalBody>
				<ModalFooter>
					<ModalButton onClick={() => setShowDeleteModal(false)} kind="secondary">
						Cancel
					</ModalButton>
					<ModalButton
						onClick={async () => {
							const resp = await mutateDelete(id)
							if (!resp.error) history.push("/judges")
						}}
						isLoading={deleting}
						startEnhancer={<FontAwesomeIcon icon={["fas", "trash-alt"]} />}
					>
						Delete
					</ModalButton>
				</ModalFooter>
			</Modal>
		</div>
	)
}

interface JudgeInput {
	email?: string
	pin?: string
	guest?: boolean
	assignedTeams: { label: string; id: string }[]
	assignedGroups: { label: string; id: string }[]
}

const JudgeEdit = (props: { judge?: Judge; isNew: boolean; stopEditMode: (judge?: Judge) => void }) => {
	const { judge, isNew, stopEditMode } = props

	const { eventID, isPaidUser } = CommonContainer.useContainer()

	const { payload: teams } = useQuery<Team[]>(listTeams(eventID))

	// Setup form
	const { payload: groups } = useQuery<Group[]>(listGroups(eventID))
	const { control, register, handleSubmit, setValue, trigger, errors } = useForm<JudgeInput>()
	const { mutate: create, loading: cLoading, error: cError, payload: cPayload } = useMutation(createJudge(eventID))
	const { mutate: update, loading: uLoading, error: uError, payload: uPayload } = useMutation(updateJudge(eventID))

	const loading = isNew ? cLoading : uLoading
	const error = isNew ? cError : uError
	const payload = isNew ? cPayload : uPayload

	const onSaveForm = handleSubmit(async ({ assignedTeams, assignedGroups, ...values }) => {
		if (!trigger()) return

		const resp = isNew
			? await create({
					...values,
					assignedTeamIDs: assignedTeams?.map((t) => t.id) || [],
					assignedGroupIDs: assignedGroups?.map((g) => g.id) || [],
			  })
			: await update({
					...values,
					id: judge?.id || "",
					assignedTeamIDs: assignedTeams?.map((t) => t.id) || [],
					assignedGroupIDs: assignedGroups?.map((g) => g.id) || [],
			  })

		// Error?
		if ((resp.payload as APIError).error) return
		const updatedJudge = resp.payload as Judge
		// On Success
		if (updatedJudge) stopEditMode(updatedJudge)
	})

	// Generate PIN
	const { loading: generatingPin, query: generatePinQuery } = useQuery(generateJudgePin(eventID), false)
	const generatePIN = async () => {
		const resp = await generatePinQuery()
		if (resp.status !== 200) return
		if (resp.payload?.payload.pin) setValue("pin", resp.payload.payload.pin)
	}

	// Load defaults
	React.useEffect(() => {
		if (!judge) return
		setValue("email", judge.email)
		setValue("guest", judge.is_guest)
		if (!judge.is_guest) setValue("pin", judge.pin)
		if (!!judge.assigned_teams) setValue("assignedTeams", judge.assigned_teams.map(teamToSelectOption))
		setValue("assignedGroups", judge.assigned_groups?.map(groupToSelectOption))
	}, [judge, setValue])

	// Styling
	const [css] = useStyletron()
	const footerStyle = css({
		display: "flex",
		flexDirection: "row-reverse",
	})

	return (
		<form
			onSubmit={(e) => {
				e.preventDefault()
				onSaveForm()
			}}
		>
			{error && isAPIError(payload) && <ErrorNotification message={payload.shortMessage} />}

			<FormControl
				label="Email"
				caption="An email with the event shortcode and judge's PIN will be sent to this email"
				error={errors.email?.message}
				positive=""
			>
				<Input
					name="email"
					inputRef={register({
						required: "Email is required",
						pattern: {
							value: /.+@.+\..+/,
							message: "Invalid email address.",
						},
					})}
					disabled={loading}
				/>
			</FormControl>

			<FormControl label="PIN" error={errors.pin?.message} positive="" disabled={judge?.is_guest}>
				<Input
					name="pin"
					inputRef={register({ required: "PIN is required", minLength: { value: 4, message: "PIN must be 4 digits" } })}
					{...{ maxLength: 4 }}
					disabled={loading || (judge && judge.is_guest)}
					overrides={{
						Root: {
							style: {
								paddingRight: "unset",
							},
						},
						EndEnhancer: {
							style: {
								paddingRight: "unset",
							},
						},
					}}
					endEnhancer={
						<Button onClick={generatePIN} isLoading={generatingPin} type="button">
							Generate
						</Button>
					}
				/>
			</FormControl>

			<FormControl label={<InlineLabel label="Assigned Teams" caption="This judge can only see/judge the following teams" />}>
				<Controller
					name="assignedTeams"
					control={control}
					defaultValue={[] as Team[]}
					render={({ value, onChange }) => (
						<div>
							<Select
								options={teams ? teams.map(teamToSelectOption) : []}
								value={value}
								multi
								onChange={(params) => onChange(params.value)}
								overrides={{
									Tag: {
										props: {
											overrides: {
												Root: {
													style: {
														height: "unset",
														paddingTop: "6px",
														paddingBottom: "6px",
														paddingLeft: "14px",
														paddingRight: "14px",
													},
												},
												Text: {
													style: {
														maxWidth: "unset",
														whiteSpace: "no-wrap",
													},
												},
											},
										},
									},
								}}
								placeholder="All Teams"
								disabled={!isPaidUser}
								isLoading={!teams}
							/>
						</div>
					)}
				/>
			</FormControl>

			<FormControl label={<InlineLabel label="Assigned Groups" caption="Assigning this judge to a group allows them to judge the entire group of teams" />}>
				<Controller
					name="assignedGroups"
					control={control}
					defaultValue={[]}
					render={({ value, onChange }) => (
						<div>
							<Select
								options={groups ? groups.map(groupToSelectOption) : []}
								value={value}
								multi
								onChange={(params) => onChange(params.value)}
								overrides={{
									Tag: {
										props: {
											overrides: {
												Root: {
													style: {
														height: "unset",
														paddingTop: "6px",
														paddingBottom: "6px",
														paddingLeft: "14px",
														paddingRight: "14px",
													},
												},
												Text: {
													style: {
														maxWidth: "unset",
														whiteSpace: "no-wrap",
													},
												},
											},
										},
									},
								}}
								placeholder="All Teams"
								isLoading={!groups}
							/>
						</div>
					)}
				/>
			</FormControl>

			{/* DEV ONLY - Guest Judge Checkbox to help testing */}
			{process.env.NODE_ENV !== "production" && (
				<FormControl error={errors.guest?.message} positive="">
					<Controller
						name="guest"
						control={control}
						defaultValue={false}
						render={({ value, onChange }) => (
							<Checkbox checked={value} onChange={(e) => onChange(e.currentTarget.checked)}>
								{`[DEV] Guest Judge`}
							</Checkbox>
						)}
					/>
				</FormControl>
			)}

			<div className={footerStyle}>
				<Spaced>
					<Button type="button" onClick={() => stopEditMode()} kind="secondary">
						Cancel
					</Button>
					<Button
						onClick={(e) => {
							e.preventDefault()
							onSaveForm()
						}}
						isLoading={loading}
						startEnhancer={<FontAwesomeIcon icon={["fas", "save"]} />}
					>
						Save
					</Button>
				</Spaced>
			</div>
		</form>
	)
}

const teamToSelectOption = (t: Team) => ({ label: `${t.name} - ${t.product_name}`, id: t.id })
const groupToSelectOption = (g: Group) => ({ label: `${g.name} - ${g.assigned_teams?.length || 0} teams`, id: g.id })
