import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { useStyletron } from "baseui"
import { Button } from "baseui/button"
import { Card, StyledBody } from "baseui/card"
import { FormControl } from "baseui/form-control"
import { Input } from "baseui/input"
import { Modal, ModalBody, ModalButton, ModalFooter, ModalHeader, ROLE, SIZE } from "baseui/modal"
import { Select, SelectOverrides } from "baseui/select"
import { Label1 } from "baseui/typography"
import * as React from "react"
import { useMutation, useQuery } from "react-fetching-library"
import { useHistory } from "react-router-dom"
import { isAPIError } from "../api/types"
import jfLogo from "../assets/images/jfLogo.png"
import { ErrorNotification } from "../components/common"
import { Spaced } from "../components/spaced"
import { CommonContainer } from "../lib/controller"
import { createEvent, Event, EventUpdateRequest, getEvents } from "../lib/events"
import { generateShortcode } from "../utils"

export const EventPickerPage = () => {
	const [css] = useStyletron()
	const parentStyle = css({
		display: "flex",
		flexDirection: "column",
		minHeight: "100vh",
		marginTop: "-70px",
		justifyContent: "center",
		alignItems: "center",
		background: "linear-gradient(180deg, rgba(0,104,157,1) 0%, rgba(97,88,204,1) 100%)",
	})
	const image = css({
		textAlign: "center",
		width: "200px",
		paddingBottom: "50px",
	})

	return (
		<div className={parentStyle}>
			<img className={image} src={jfLogo} alt="JudgeFest" />
			<Card
				overrides={{
					Root: {
						style: {
							borderTopLeftRadius: "3px",
							borderTopRightRadius: "3px",
							borderBottomLeftRadius: "3px",
							borderBottomRightRadius: "3px",
							borderTopStyle: "none",
							borderBottomStyle: "none",
							borderLeftStyle: "none",
							borderRightStyle: "none",
						},
					},
				}}
			>
				<StyledBody>
					<EventPicker isPage />
				</StyledBody>
			</Card>
		</div>
	)
}

export const EventPicker = (props: { className?: string; overrides?: SelectOverrides; isPage?: boolean; createEventModal?: boolean }) => {
	const { isPage, createEventModal } = props

	const { eventID, setEvent, removeEvent, event } = CommonContainer.useContainer()
	const history = useHistory()

	const { mutate, error, payload: createPayload } = useMutation(createEvent)
	const { query, payload } = useQuery(getEvents())
	const [createModal, setCreateModal] = React.useState(createEventModal)
	const [eventCreatePayload, setEventCreatePayload] = React.useState<EventUpdateRequest>({
		newname: "",
		newshortcode: "",
	})

	const onSubmit = async () => {
		const resp = await mutate(eventCreatePayload)
		if (resp.error) {
			await query()
			return
		}
		if (!!resp.payload) {
			// Set event and redirect to dashboard
			setEvent((resp.payload as Event).id)
			setCreateModal(false)
			setEventCreatePayload({
				newname: "",
				newshortcode: "",
			})
		}
	}

	const generateNames = (name: string) => {
		const generatedShortname = generateShortcode(name)
		setEventCreatePayload({ ...eventCreatePayload, newname: name, newshortcode: generatedShortname })
	}

	// Refresh list on event update
	React.useEffect(() => {
		if (!!event) query()
	}, [event, query])

	/** Event list mapped to options for baseweb select field */
	const options =
		payload?.map((el) => ({
			id: el.id,
			label: (
				<Spaced>
					{el.archived && <FontAwesomeIcon icon={["fas", "lock-alt"]} />}
					<div>{el.shortcode}</div>
				</Spaced>
			),
		})) || []

	return (
		<>
			<div className={props.className}>
				{isPage && <Label1 $style={{ textAlign: "center", marginBottom: "10px" }}>Select Event</Label1>}
				<Select
					options={options}
					value={[{ id: eventID, label: eventID }!]}
					placeholder="Select event"
					onChange={({ value }) => {
						if (!value || value.length === 0) {
							removeEvent()
							return
						}
						if (value && value[0] && value[0].id) {
							setEvent(value[0].id.toString())
							// Redirect to dashboard on event change
							history.push("/")
						}
					}}
					overrides={props.overrides}
				/>
				<Button
					onClick={() => setCreateModal(true)}
					overrides={{
						BaseButton: {
							style: {
								whiteSpace: "nowrap",

								"::after": {
									content: `"Create New Event"`,
									"@media only screen and (max-width: 575px)": isPage
										? {}
										: {
												content: "unset",
										  },
								},
							},
						},
						StartEnhancer: {
							style: isPage
								? {}
								: {
										"@media only screen and (max-width: 575px)": {
											marginRight: "unset",
										},
								  },
						},
					}}
					startEnhancer={<FontAwesomeIcon icon={["fas", "plus-circle"]} />}
				/>
			</div>

			<Modal onClose={() => setCreateModal(false)} isOpen={createModal} animate autoFocus size={SIZE.default} role={ROLE.dialog}>
				<form
					onSubmit={(e) => {
						e.preventDefault()
						onSubmit()
					}}
				>
					<ModalHeader>Create a new event</ModalHeader>
					<ModalBody>
						<p>
							Create your new event below. No spaces are permitted, and it must contain at least one alphanumeric character. Your users will use this shortcode
							to access the event, so make it memorable!
						</p>

						<FormControl label="Event Name">
							<Input
								value={eventCreatePayload.newname}
								onChange={(e) => {
									const target = e.target as HTMLTextAreaElement
									generateNames(target.value)
								}}
								onBlur={(e) => {
									setEventCreatePayload({ ...eventCreatePayload, newname: e.currentTarget.value.toUpperCase() })
								}}
								placeholder="Event Name"
								error={error}
								overrides={{
									Input: {
										style: ({ $theme }) => ({
											textTransform: "uppercase",
											"::placeholder": {
												textTransform: "none",
											},
											"::-webkit-input-placeholder": {
												textTransform: "none",
											},
											"::-moz-placeholder": {
												textTransform: "none",
											},
											"::-ms-input-placeholder": {
												textTransform: "none",
											},
										}),
									},
								}}
							/>
						</FormControl>

						<FormControl label="Event Shortcode">
							<Input
								value={eventCreatePayload.newshortcode}
								onChange={async (e) => {
									const target = e.target as HTMLTextAreaElement
									setEventCreatePayload({ ...eventCreatePayload, newshortcode: target.value })
								}}
								onBlur={(e) => setEventCreatePayload({ ...eventCreatePayload, newshortcode: e.currentTarget.value.toUpperCase() })}
								placeholder="Event shortcode"
								error={error}
								overrides={{
									Input: {
										style: ({ $theme }) => ({
											textTransform: "uppercase",
											"::placeholder": {
												textTransform: "none",
											},
											"::-webkit-input-placeholder": {
												textTransform: "none",
											},
											"::-moz-placeholder": {
												textTransform: "none",
											},
											"::-ms-input-placeholder": {
												textTransform: "none",
											},
										}),
									},
								}}
							/>
						</FormControl>
						<FormControl label="Copy Data from Existing Event? (optional)">
							<Select
								options={options}
								value={eventCreatePayload.copyFromID ? [{ id: eventCreatePayload.copyFromID, label: eventCreatePayload.copyFromID }] : undefined}
								onChange={({ value }) => {
									if (!value || value.length === 0 || !value[0]?.id) setEventCreatePayload({ ...eventCreatePayload, copyFromID: undefined })
									else setEventCreatePayload({ ...eventCreatePayload, copyFromID: value[0].id.toString() })
								}}
								clearable
							/>
						</FormControl>

						{error && <ErrorNotification message={isAPIError(createPayload) ? createPayload.shortMessage : "An error occurred"} />}
					</ModalBody>

					<ModalFooter>
						<ModalButton
							onClick={(e) => {
								e.preventDefault()
								onSubmit()
							}}
							startEnhancer={<FontAwesomeIcon icon={["fas", "save"]} />}
						>
							Create Event
						</ModalButton>
					</ModalFooter>
				</form>
			</Modal>
		</>
	)
}
