import * as React from "react"
import { Notification } from "baseui/notification"
import { FormControl } from "baseui/form-control"
import { Controller, useForm } from "react-hook-form"
import { CommonContainer } from "../lib/controller"
import { ErrorNotification } from "./common"
import { Button } from "baseui/button"
import { Input } from "baseui/input"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { Select } from "baseui/select"
import { Modal, ModalHeader, ModalBody } from "baseui/modal"
import { Spread } from "./spread"

interface FormData {
	email: string
	firstName: string
	lastName: string
	contactNumber: string
	industry: string
}

const industries = [{ id: "Education", label: "Education" }]

export const ChangeDetailsForm = () => {
	const { control, register, handleSubmit, trigger, errors, setValue, formState } = useForm<FormData>()
	const { user, useChangeDetails } = CommonContainer.useContainer()
	const { changeDetails, loading, success, error } = useChangeDetails()
	const [showConfirmModal, setShowConfirmModal] = React.useState(false)
	const [formInput, setFormInput] = React.useState<FormData>()

	const onSubmit = handleSubmit((input) => {
		if (!trigger()) return

		// If changing email
		if (input.email.toLowerCase() !== user?.email.toLowerCase()) {
			setShowConfirmModal(true)
			setFormInput(input)
			return
		}

		changeDetailsFunc(input)
	})

	const changeDetailsFunc = (input: FormData) => {
		changeDetails(input.email.toLowerCase(), input.firstName, input.lastName, input.contactNumber, input.industry)
	}

	// Load current data
	React.useEffect(() => {
		if (!user) return
		setValue("email", user.email)
		setValue("firstName", user.firstName)
		setValue("lastName", user.lastName)
		setValue("contactNumber", user.contactNumber)
		setValue("industry", user.industry)
	}, [user, setValue])

	return (
		<>
			<form onSubmit={onSubmit}>
				<div>
					{success && !formState.isDirty && <Notification kind="positive">Your details has been updated.</Notification>}

					{error && <ErrorNotification message={error} />}

					<FormControl label="Email" error={errors.email && errors.email.message}>
						<Input
							name="email"
							type="email"
							error={errors.email !== undefined}
							inputRef={register({
								required: "Email is required.",
								pattern: {
									value: /.+@.+\..+/,
									message: "Invalid email address",
								},
							})}
							onBlur={(e) => (e.currentTarget.value = e.currentTarget.value.toLowerCase())}
							disabled={loading}
						/>
					</FormControl>

					<FormControl label="First Name" error={errors.firstName && errors.firstName.message}>
						<Input
							name="firstName"
							type="text"
							error={errors.firstName !== undefined}
							inputRef={register({
								required: "First Name is required.",
							})}
							disabled={loading}
						/>
					</FormControl>
					<FormControl label="Last Name" error={errors.lastName && errors.lastName.message}>
						<Input
							name="lastName"
							type="text"
							error={errors.lastName !== undefined}
							inputRef={register({
								required: "Last Name is required.",
							})}
							disabled={loading}
						/>
					</FormControl>
					<FormControl label="Contact Number" error={errors.contactNumber && errors.contactNumber.message}>
						<Input
							name="contactNumber"
							type="text"
							error={errors.contactNumber !== undefined}
							inputRef={register({
								required: "Contact Number is required.",
							})}
							disabled={loading}
						/>
					</FormControl>
					<FormControl label="Industry" error={errors.industry && errors.industry.message}>
						<Controller
							name="industry"
							control={control}
							render={({ onChange, value }) => {
								const v = industries.find((i) => i.label === value) || { id: value, label: value }
								return (
									<Select
										placeholder="Select a Industry or Type a new one."
										options={industries}
										onChange={({ value }) => {
											if (!value[0]) {
												onChange("")
												return
											}
											onChange(value[0].label)
										}}
										onInputChange={(e) => {
											const textBox = e.target as HTMLTextAreaElement
											onChange(textBox.value)
										}}
										value={v ? [v] : []}
										clearable={true}
										searchable={true}
									/>
								)
							}}
						/>
					</FormControl>
				</div>

				<Button
					onClick={onSubmit}
					isLoading={loading}
					startEnhancer={<FontAwesomeIcon icon={["fas", "save"]} />}
					overrides={{
						Root: {
							style: {
								display: "flex",
								marginLeft: "auto",
							},
						},
					}}
				>
					Save
				</Button>
			</form>
			<Modal
				isOpen={showConfirmModal}
				onClose={() => {
					setShowConfirmModal(false)
				}}
			>
				<ModalHeader>Confirm Email Change</ModalHeader>
				<ModalBody>
					Are you sure you want to change your login email to {formInput?.email.toLowerCase()}?
					<div style={{ marginTop: "10px" }}>
						<Spread>
							<Button onClick={(e) => setShowConfirmModal(false)}>No</Button>
							<Button
								onClick={(e) => {
									if (formInput) changeDetailsFunc(formInput)
									setShowConfirmModal(false)
								}}
							>
								Yes
							</Button>
						</Spread>
					</div>
				</ModalBody>
			</Modal>
		</>
	)
}
