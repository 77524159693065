import { IconProp } from "@fortawesome/fontawesome-svg-core"
import { faSparkles } from "@fortawesome/pro-light-svg-icons"
import { faDatabase, faUser } from "@fortawesome/pro-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { styled, useStyletron } from "baseui"
import { Block } from "baseui/block"
import { Breadcrumbs } from "baseui/breadcrumbs"
import { StatefulMenu } from "baseui/menu"
import { PLACEMENT, StatefulPopover } from "baseui/popover"
import { H6 } from "baseui/typography"
import * as React from "react"
import { Link, useHistory, useLocation } from "react-router-dom"
import { TOPBAR_HEIGHT } from "../app"
import BoostBoltSVG from "../assets/images/boost bolt.svg"
import { CommonContainer } from "../lib/controller"
import { isUUID } from "../utils"
import { Spaced } from "./spaced"
import { VerifyEmailNotification } from "./verifyEmailNotification"

interface Crumb {
	name: string
	url?: string
}

export const TopBar = () => {
	const location = useLocation()
	const { user } = CommonContainer.useContainer()

	const [css, theme] = useStyletron()
	const wrapperStyle = css({
		height: TOPBAR_HEIGHT,
		zIndex: 100,
	})
	const containerStyle = css({
		display: "flex",
		alignItems: "center",
		borderBottom: `1px solid #D9D9D9`,
		fontSize: "1.5rem",
		background: "white",
		boxShadow: "1px 2px 4px #00000022",
		height: "69px",
	})
	const topBarContentStyle = css({
		marginRight: "25px",
		display: "flex",
		alignItems: "center",
		justifyContent: "space-between",
		width: "100%",
	})
	const breadcrumbSeparator = css({
		marginLeft: "10px",
		marginRight: "10px",
		color: "#AAAAAA",
		userSelect: "none",
	})
	const breadcrumbDimmed = css({
		color: theme.colors.black + " !important",
		":hover": {
			color: theme.colors.accent + " !important",
		},
	})

	// Calculate breadcrumbs from pathname
	const [breadcrumbs, setBreadcrumbs] = React.useState<Crumb[]>([])
	React.useEffect(() => {
		const crumbs: Crumb[] = []
		const split = location.pathname.split("/")
		if (split[0] === "") split.shift()
		if (split[split.length - 1] === "") split.pop()
		if (split.length === 0) {
			setBreadcrumbs([{ name: "Event" }])
			return
		}

		let path = "/"
		for (let i = 0; i < split.length; i++) {
			if ((i !== 0 && isUUID(split[i])) || split[i].toLowerCase() === "updated") continue // don't show uuid's in the breadcrumbs or unreachable route "updated"
			crumbs.push({
				name: split[i]
					.replace(/[.-]/g, " ") // replace dots and dashes with spaces
					.replace("~", " / ") // replace ~ with / (for page titles that want a /)
					.replace(/([a-z])([A-Z])/g, "$1 $2"), // split camel-case
				url: i === split.length - 1 ? undefined : path + split[i],
			})
			path += split[i] + "/"
		}

		setBreadcrumbs(crumbs)
	}, [location])

	return (
		<div className={wrapperStyle}>
			<div className={containerStyle}>
				<div className={topBarContentStyle}>
					<Breadcrumbs
						overrides={{
							ListItem: {
								style: {
									fontSize: "22px",
									fontWeight: 600,
									textTransform: "capitalize",
									display: "flex",
								},
							},
							Root: {
								style: {
									marginLeft: "38px",
								},
							},
							List: {
								style: {
									display: "flex",
								},
							},
							Separator: {
								component: () => <div className={breadcrumbSeparator}>/</div>,
							},
						}}
					>
						{breadcrumbs.map((crumb, index) => {
							if (crumb.url)
								return (
									<Link key={`breadcrumb-${index}`} to={crumb.url} className={index !== breadcrumbs.length - 1 ? breadcrumbDimmed : undefined}>
										{crumb.name}
									</Link>
								)
							return <div key={`breadcrumb-${index}`}>{crumb.name}</div>
						})}
					</Breadcrumbs>
					<Block marginLeft="auto" marginRight="1rem">
						{!user?.isSuperAdmin && <CreditCounter />}
					</Block>
					<TopBarAccountDropdown />
				</div>
			</div>
		</div>
	)
}

interface DropdownListItem {
	id: string
	icon: IconProp
}

export const TopBarAccountDropdown = () => {
	const [isOpen, setIsOpen] = React.useState<boolean>(false)

	const history = useHistory()
	const { user, signOut } = CommonContainer.useContainer()

	const [css] = useStyletron()
	const userStyle = css({
		cursor: "pointer",
		display: "flex",
		alignItems: "center",
	})
	const userNameStyle = css({
		marginLeft: "10px",
		"@media only screen and (max-width: 700px)": {
			display: "none",
		},
	})
	const userNameDropDownStyle = css({
		fontSize: "12px",
		color: "grey",
		display: "none",
		"@media only screen and (max-width: 700px)": {
			display: "block",
		},
	})
	const iconStyle = css({
		width: "20px !important",
	})

	if (!user) return null

	const menuContent = (close: () => void) => {
		const items: DropdownListItem[] = []

		if (user.isSuperAdmin) {
			items.push({ id: "Users", icon: faUser }, { id: "Events", icon: faDatabase })
		} else {
			items.push({ id: "Settings", icon: ["fal", "cog"] }, { id: "Upgrade", icon: faSparkles }, { id: "Billing", icon: ["fas", "dollar-sign"] })
		}
		items.push({ id: "Log Out", icon: ["fal", "sign-out"] })

		return (
			<>
				<StatefulMenu
					overrides={{
						List: {
							style: {
								outline: "none",
								minWidth: "200px",
							},
						},
					}}
					items={items.map((item, i) => ({
						id: item.id,
						label: (
							<>
								<Spaced>
									<FontAwesomeIcon icon={item.icon} className={iconStyle} />
									<div>{item.id}</div>
								</Spaced>
								{i === items.length - 1 && <div className={userNameDropDownStyle}>{user.email}</div>}
							</>
						),
					}))}
					onItemSelect={async (selection) => {
						switch (selection.item.id) {
							case "Settings":
								history.push("/settings")
								close()
								return
							case "Upgrade":
								history.push("/upgrade")
								close()
								return
							case "Billing":
								history.push("/billing")
								close()
								return
							case "Users":
								history.push("/users")
								close()
								return
							case "Events":
								history.push("/events")
								close()
								return
							case "Log Out":
								await signOut()
								history.go(0)
								break
						}
					}}
				/>
			</>
		)
	}

	return (
		<>
			<StatefulPopover
				content={({ close }) => menuContent(close)}
				onOpen={() => setIsOpen(true)}
				onClose={() => setIsOpen(false)}
				placement={PLACEMENT.bottomRight}
			>
				<div className={userStyle}>
					<FontAwesomeIcon icon={["fal", "user-circle"]} />
					<div className={userNameStyle}>
						<H6
							overrides={{
								Block: {
									style: {
										marginTop: "0px",
										marginBottom: "0px",
									},
								},
							}}
						>
							{user.email}
						</H6>
					</div>
					<FontAwesomeIcon icon={["fal", isOpen ? "chevron-up" : "chevron-down"]} size="xs" style={{ marginLeft: "10px" }} />
				</div>
			</StatefulPopover>
			{!user.verified && <VerifyEmailNotification />}
		</>
	)
}

interface CreditCounterProps {}

export const CreditCounter: React.FC<CreditCounterProps> = () => {
	const { user } = CommonContainer.useContainer()
	const history = useHistory()

	const Base = styled("button", {
		display: "flex",
		alignItems: "center",
		padding: ".1rem 1rem",
		font: "inherit",
		fontWeight: "bold",
		borderRadius: "2rem",
		border: "none",
		outline: "none",
		background: "linear-gradient(to bottom right, #e284b3, #7a92d8, #44b6b4)",
		color: "white",
		cursor: "pointer",
	})
	const BoostBolt = styled("img", {
		width: "1.4rem",
		marginLeft: "auto",
		marginRight: ".5rem",
		pointerEvents: "none",
	})

	return (
		<Base onClick={() => history.push("boosts")}>
			<BoostBolt src={BoostBoltSVG} />
			{user?.creditCount}
		</Base>
	)
}
