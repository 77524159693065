import { createClient } from "react-fetching-library"
import { requestHostInterceptor } from "./requestInterceptors/requestHostInterceptor"

let HOST
switch (process.env.NODE_ENV) {
	case "development":
		HOST = "http://localhost:8080/api"
		break
	case "production":
		// prod
		HOST = "https://app.judgefest.com/api"
		// online staging, put here or Makefile will not work as intended
		// HOST = "https://judgefest.theninja.life/api"
		break
	default:
		HOST = "NODE_ENV NOT SET"
		break
}
export const Client = createClient({
	requestInterceptors: [requestHostInterceptor(HOST)],
})
