import React from "react"
import { Page, Text, View, Document, StyleSheet, pdf, Image } from "@react-pdf/renderer"
import { useQuery } from "react-fetching-library"
import { isAPIError } from "../api/types"
import { getFullAdminReport, ReportData } from "../lib/report"
import { Button } from "baseui/button"
import { StyledSpinnerNext as Spinner } from "baseui/spinner"
import { saveAs } from "file-saver"
import { CommonContainer } from "../lib/controller"
import { Modal, ModalBody, ModalFooter, ModalHeader } from "baseui/modal"
import JSZip from "jszip"
import Logo from "../assets/images/jfLogo.png"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { ScoreType } from "../api/enums"
import { Event } from "../lib/events"

// Create styles
const styles = StyleSheet.create({
	header: {
		display: "flex",
		flexDirection: "row",
		justifyContent: "space-between",
		alignItems: "center",
		padding: 10,
		fontSize: 12,
		color: "grey",
	},
	reportHeader: {
		color: "#FFFFFF",
		backgroundColor: "#1C0658",
		marginLeft: 10,
		marginRight: 10,
		marginBottom: 10,
		padding: 15,
		display: "flex",
		flexDirection: "row",
		borderRadius: 3,
	},
	section: {
		backgroundColor: "#FFF5EE",
		margin: 10,
		padding: 15,
		borderRadius: 3,
	},
	title: {
		fontSize: 26,
	},
	subtitle: {
		fontSize: 14,
		marginLeft: 8,
		marginTop: 10,
	},
	sectionTitle: {
		fontSize: 24,
		marginBottom: 5,
	},
	scoreTotal: {
		display: "flex",
		flexDirection: "row",
		alignItems: "flex-end",
		marginLeft: "auto",
		fontSize: 14,
	},
	score: {
		marginLeft: 5,
		fontSize: 20,
		fontWeight: "bold",
	},
	categoryScore: {
		fontSize: 14,
		display: "flex",
		flexDirection: "row",
		justifyContent: "space-between",
		alignItems: "center",
		minHeight: 45,
		marginBottom: 5,
		marginLeft: 10,
	},
	category: {
		maxWidth: "75%",
	},
	comment: {
		fontSize: 14,
		margin: 10,
	},
})

export const ReportCard = ({ report, event }: { report: ReportData; event: Event }) => {
	const scoreDivision = event.score_type === ScoreType.Decimal ? 10 : 1

	const comments = report.comments.filter((c) => c.trim() !== "")

	const pageHeader = (
		<View style={styles.header}>
			{event.logo ? (
				<Image
					src={`data:${event.logo_content_type};base64,${event.logo}`}
					style={{
						height: 50,
						padding: 2,
					}}
				/>
			) : (
				<Image
					src={Logo}
					style={{
						width: 40,
						padding: 2,
					}}
				/>
			)}
			<View
				style={{
					display: "flex",
					alignItems: "flex-end",
					maxWidth: "200px",
				}}
			>
				<Text>{`${report.productName} Report Card`}</Text>
				<Text>{new Date().toLocaleDateString()}</Text>
			</View>
		</View>
	)

	return (
		<Document title={`${report.teamName} Report Card`}>
			<Page size="A4">
				{pageHeader}

				{/* Report Header */}
				<View style={styles.reportHeader}>
					<View>
						<Text style={styles.title}>{report.productName}</Text>
						<Text style={styles.subtitle}>{report.teamName}</Text>
					</View>
					<View style={styles.scoreTotal}>
						<Text>Total Score:</Text>
						<Text style={styles.score}>{Math.round((report.total / scoreDivision) * 10) / 10}</Text>
					</View>
				</View>

				{/* Score Breakdown */}
				<View style={styles.section}>
					<Text style={styles.sectionTitle}>Score Breakdown</Text>
					{report.scores.map((score) => (
						<View style={styles.categoryScore} key={`score-${score.categoryName}`}>
							<Text style={styles.category}>{score.categoryName}</Text>
							<Text style={styles.score}>{(score.value / scoreDivision).toPrecision(2)}</Text>
						</View>
					))}
				</View>
			</Page>

			{/* Feedback */}
			<Page size="A4">
				{pageHeader}

				{comments.length > 0 && (
					<View style={styles.section}>
						<Text style={styles.sectionTitle}>Feedback</Text>
						{comments.map((comment, i) => (
							<Text style={styles.comment} key={`comment-${i}`}>
								{`"${comment}"`}
							</Text>
						))}
					</View>
				)}
			</Page>
		</Document>
	)
}

export const ReportCardsDownload = (props: { event: string }) => {
	const { error, payload, loading: fetching } = useQuery<ReportData[]>(getFullAdminReport(props.event))
	const { event } = CommonContainer.useContainer()
	const [loading, setLoading] = React.useState(false)

	const downloadBlobs = async () => {
		if (!payload || payload.length === 0 || !event) return
		setLoading(true)

		const zip = new JSZip()
		const padZeroes = payload.length.toString().length
		for (let i = 0; i < payload.length; i++) {
			const no = ("00000" + (i + 1)).slice(-padZeroes)

			const blob = await pdf(<ReportCard report={payload[i]} event={event} />).toBlob()
			zip.file(`${no} ${payload[i].productName} (${payload[i].teamName}) Report Card.pdf`, blob)
		}

		await zip.generateAsync({ type: "blob" }).then((content) => saveAs(content, `${props.event}_report_cards.zip`))

		setLoading(false)
	}

	if (error && isAPIError(payload)) {
		return (
			<div>
				<pre>{payload.shortMessage}</pre>
			</div>
		)
	}
	if (!payload) return <Spinner />

	return (
		<Button onClick={downloadBlobs} isLoading={fetching || loading}>
			Download
		</Button>
	)
}

export const DownloadReportCardsButton = () => {
	const { eventID } = CommonContainer.useContainer()
	const [downloadModalOpen, setDownloadModalOpen] = React.useState(false)

	return (
		<>
			<Button onClick={() => setDownloadModalOpen(true)} startEnhancer={<FontAwesomeIcon icon={["fal", "file-pdf"]} />}>
				Download Report Cards
			</Button>
			<Modal closeable isOpen={downloadModalOpen} onClose={() => setDownloadModalOpen(false)}>
				<ModalHeader>Download Report Cards</ModalHeader>
				<ModalBody>
					<p>Download a zip file with PDFs for each team.</p>
					<p>Each PDF contains the team's total score, score breakdown and judge comments.</p>
				</ModalBody>
				<ModalFooter>
					<ReportCardsDownload event={eventID} />
				</ModalFooter>
			</Modal>
		</>
	)
}
