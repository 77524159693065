import * as React from "react"
import { useStyletron } from "baseui"
import { H1 } from "baseui/typography"
import { Tab, Tabs } from "baseui/tabs-motion"
import { useHistory } from "react-router-dom"
import { VerticalTabOverrides } from "../themeOverrides"
import { ChangePasswordForm } from "../components/changePassword"
import { ChangeDetailsForm } from "../components/changeDetails"
import { CommonContainer } from "../lib/controller"
import { Spread } from "../components/spread"
import { AccountType } from "../api/enums"
import { ChangeAddressForm } from "../components/changeAddress"

export const Settings = () => {
	const history = useHistory()
	const [activeKey, setActiveKey] = React.useState(history.location.hash || "#account")

	const { user } = CommonContainer.useContainer()

	// Styling
	const [css] = useStyletron()
	const container = css({
		maxWidth: "900px",
		margin: "auto",
	})
	const accountTypeStyle = css({
		color: "grey",
	})

	return (
		<div className={container}>
			<Spread>
				<H1>Settings</H1>
				{user && <div className={accountTypeStyle}>{`${AccountType[user.accountType]} Account`}</div>}
			</Spread>

			<Tabs
				onChange={({ activeKey }: any) => {
					setActiveKey(activeKey)
					history.push({ hash: activeKey })
				}}
				orientation="vertical"
				activeKey={activeKey}
				overrides={{
					TabHighlight: {
						style: {
							width: "3px",
							marginRight: "7px",
						},
					},
					TabBorder: {
						style: { width: "1px" },
					},
					TabList: {
						style: {
							width: "15%",
							minWidth: "160px",
						},
					},
				}}
			>
				<Tab title="Account" key="#account" overrides={VerticalTabOverrides}>
					<ChangeDetailsForm />
				</Tab>
				<Tab title="Address" key="#address" overrides={VerticalTabOverrides}>
					<ChangeAddressForm />
				</Tab>
				<Tab title="Password" key="#password" overrides={VerticalTabOverrides}>
					<ChangePasswordForm />
				</Tab>
			</Tabs>
		</div>
	)
}
