import * as React from "react"
import { createTheme, lightThemePrimitives } from "baseui"
import { ButtonOverrides } from "baseui/button"
import { SliderOverrides } from "baseui/slider"
import { TabOverrides } from "baseui/tabs-motion"

// See https://github.com/uber-web/baseui/blob/master/src/themes/creator.js for full list of theme properties

export const paddingZero = { paddingLeft: "0", paddingRight: "0", paddingTop: "0", paddingBottom: "0" }

export const LightTheme = createTheme(
	{
		...lightThemePrimitives,
		primaryFontFamily: "'Open Sans', system-ui, 'Helvetica Neue', Helvetica, Arial, sans-serif",
	},
	{
		colors: {
			black: "#070707",
			primary: "#252525",
			accent: "#00689d",
			inputBorder: "#565656",
		},
		lighting: {
			shadow400: "rgba(0, 0, 0, 0.16) 0px 1px 4px 2px",
		},
	},
)

export const AccentButtonOverrides: ButtonOverrides = {
	BaseButton: {
		style: ({ $theme }) => ({
			marginTop: "20px",
			backgroundColor: "#B6373D",
			backgroundImage: "linear-gradient(90deg, rgba(182,55,61,1) 0%, rgba(219,90,69,1) 100%)",
			borderTopLeftRadius: "30px",
			borderTopRightRadius: "30px",
			borderBottomLeftRadius: "30px",
			borderBottomRightRadius: "30px",
			textDecoration: "none",
			boxShadow: $theme.lighting.shadow400,
			":hover": {
				backgroundImage: "linear-gradient(90deg, rgba(199,116,120,1) 0%, rgba(215,154,144,1) 100%)",
				boxShadow: "inset " + $theme.lighting.shadow400,
			},
		}),
	},
}

export const GreyButtonOverrides: ButtonOverrides = {
	BaseButton: {
		style: ({ $theme }) => ({
			marginTop: "20px",
			backgroundColor: "#111111",
			backgroundImage: "linear-gradient(90deg, rgba(17,17,17,1) 0%, rgba(68,68,68,1) 100%)",
			borderTopLeftRadius: "30px",
			borderTopRightRadius: "30px",
			borderBottomLeftRadius: "30px",
			borderBottomRightRadius: "30px",
			textDecoration: "none",
			fontSize: "14px",
			boxShadow: $theme.lighting.shadow400,
			":hover": {
				backgroundImage: "linear-gradient(90deg, rgba(34,34,34,1) 0%, rgba(88,88,88,1) 100%)",
				boxShadow: "inset " + $theme.lighting.shadow400,
			},
		}),
	},
}

export const NumberSliderOverrides: SliderOverrides = {
	InnerThumb: ({ $value, $thumbIndex }) => <>{$value[$thumbIndex]}</>,
	ThumbValue: () => null,
	Thumb: {
		style: {
			height: "36px",
			width: "36px",
			display: "flex",
			alignItems: "center",
			justifyContent: "center",
			borderTopLeftRadius: "36px",
			borderTopRightRadius: "36px",
			borderBottomRightRadius: "36px",
			borderBottomLeftRadius: "36px",
			borderLeftStyle: "solid",
			borderRightStyle: "solid",
			borderTopStyle: "solid",
			borderBottomStyle: "solid",
			borderLeftWidth: "3px",
			borderTopWidth: "3px",
			borderRightWidth: "3px",
			borderBottomWidth: "3px",
			borderLeftColor: "#ccc",
			borderTopColor: "#ccc",
			borderRightColor: "#ccc",
			borderBottomColor: "#ccc",
			backgroundColor: "#fff",
		},
	},
	InnerTrack: {
		style: {
			height: "6px",
		},
	},
}

export const VerticalTabOverrides: TabOverrides = {
	Tab: {
		style: {
			fontWeight: "normal",
			display: "block",
			textAlign: "left",
		},
	},
	TabPanel: {
		style: {
			paddingTop: "0px",
			paddingLeft: "30px",
			paddingRight: "30px",
			overflowY: "auto",
		},
	},
}
