import { Button } from "baseui/button"
import { FormControl } from "baseui/form-control"
import { Input } from "baseui/input"
import { Modal, ModalBody, ModalHeader } from "baseui/modal"
import { toaster } from "baseui/toast"
import React, { useState } from "react"
import { Controller, useForm } from "react-hook-form"
import { useHistory } from "react-router"
import { styled } from "styletron-react"
import { CommonContainer } from "../lib/controller"

interface ImpersonatorBorderAndButtonProps {}

export const ImpersonatorBorderAndButton: React.FC<ImpersonatorBorderAndButtonProps> = () => {
	const history = useHistory()
	const { signIn } = CommonContainer.useContainer()
	const [showCancelImpersonationModal, setShowCancelImpersonationModal] = useState(false)
	const [isLoading, setIsLoading] = useState(false)

	const { control, handleSubmit, trigger } = useForm<{
		email: string
		password: string
	}>({
		defaultValues: {
			email: "",
			password: "",
		},
	})

	const Top = styled("div", {
		zIndex: 9999,
		position: "absolute",
		top: 0,
		left: 0,
		right: 0,
		height: "3px",
		backgroundColor: "salmon",
	})
	const Right = styled("div", {
		zIndex: 9999,
		position: "absolute",
		top: 0,
		right: 0,
		bottom: 0,
		width: "3px",
		backgroundColor: "salmon",
	})
	const Bottom = styled("div", {
		zIndex: 9999,
		position: "absolute",
		bottom: 0,
		left: 0,
		right: 0,
		height: "3px",
		backgroundColor: "salmon",
	})
	const Left = styled("div", {
		zIndex: 9999,
		position: "absolute",
		top: 0,
		left: 0,
		bottom: 0,
		width: "3px",
		backgroundColor: "salmon",
	})

	const onSubmit = handleSubmit(async (input) => {
		// Trigger form validation, if not valid then return
		if (!trigger()) return

		setIsLoading(true)
		// Sign user in
		const message = await signIn(input.email, input.password)
		setIsLoading(false)

		if (message) {
			// If error display error toast
			toaster.negative(<>{message}</>, {})
			return
		}
		history.push("/")
		window.location.reload()
	})

	return (
		<>
			<Top />
			<Right />
			<Bottom />
			<Left />
			<Button
				overrides={{
					BaseButton: {
						style: {
							":hover": {
								backgroundColor: "salmon",
							},
						},
					},
				}}
				onClick={() => setShowCancelImpersonationModal(true)}
			>
				Cancel Impersonation
			</Button>
			<Modal onClose={() => setShowCancelImpersonationModal(false)} isOpen={showCancelImpersonationModal}>
				<ModalHeader>Return to Superadmin panel</ModalHeader>
				<ModalBody>
					Please enter your superadmin credentials
					<form onSubmit={onSubmit}>
						<FormControl label="Email">
							<Controller
								name="email"
								control={control}
								render={({ value, onChange }) => {
									return (
										<>
											<Input value={value} onChange={(e) => onChange(e.currentTarget.value)} type="email" disabled={isLoading} required />
										</>
									)
								}}
							/>
						</FormControl>
						<FormControl label="Password">
							<Controller
								name="password"
								control={control}
								render={({ value, onChange }) => {
									return (
										<>
											<Input value={value} onChange={(e) => onChange(e.currentTarget.value)} type="password" disabled={isLoading} required />
										</>
									)
								}}
							/>
						</FormControl>
						<Button>Login</Button>
					</form>
				</ModalBody>
			</Modal>
		</>
	)
}
