import React from "react"
export class ErrorBoundary extends React.Component {
	state = { hasError: false, error: "", errorInfo: "" }
	static getDerivedStateFromError(error: any) {
		return { hasError: true }
	}
	componentDidCatch(error: any, errorInfo: any) {
		this.setState({ error, errorInfo })
		console.error(error, errorInfo)
	}
	render() {
		if (this.state.hasError) {
			return (
				<div>
					<h1>Something went wrong.</h1>
					<pre>{this.state.error}</pre>
				</div>
			)
		}
		return this.props.children
	}
}
