import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { useStyletron } from "baseui"
import { Button } from "baseui/button"
import { FormControl } from "baseui/form-control"
import { Input } from "baseui/input"
import { Modal, ModalBody, ModalButton, ModalFooter, ModalHeader } from "baseui/modal"
import { Option, Select } from "baseui/select"
import { Slider } from "baseui/slider"
import { Textarea } from "baseui/textarea"
import { H1, LabelMedium } from "baseui/typography"
import * as React from "react"
import { useMutation, useQuery } from "react-fetching-library"
import { Controller, useForm } from "react-hook-form"
import { RouteComponentProps, useHistory } from "react-router-dom"
import { CategoryInputType, CategoryInputTypeNames } from "../../api/enums"
import { APIError, isAPIError } from "../../api/types"
import { ErrorNotification, InlineLabel } from "../../components/common"
import { Loading } from "../../components/loading"
import { Spaced } from "../../components/spaced"
import { Spread } from "../../components/spread"
import { Category, createCategory, deleteCategory, getCategory, updateCategory } from "../../lib/categories"
import { CommonContainer } from "../../lib/controller"
import { NumberSliderOverrides } from "../../themeOverrides"

const required = { required: { value: true, message: "Required" } }

const inputTypeOptions = CategoryInputTypeNames.map((s, i) => ({ id: i as CategoryInputType, label: s }))

const scoreTextPresets = [
	["Not Rated", "Not Addressed", "Needs Work", "Shows Promise", "Fair", "Alright", "Pretty Good", "Great", "Wow", "Awesome", "Perfect"],
	["Not Rated", "Appalling", "Horrible", "Very Bad", "Bad", "Average", "Fine", "Good", "Very Good", "Great", "Masterpiece"],
	["Custom"],
]
const scoreTextPresetOptions = scoreTextPresets.map((s, i) => ({
	id: i,
	label: i === scoreTextPresets.length - 1 ? s[0] : `${s[1]} - ${s[s.length - 1]}`,
	value: s,
}))

export const CategoryPage = (props: RouteComponentProps<{ id: string }>) => {
	const id = props.match.params.id
	const isNew = id === "create"

	const { eventID, event } = CommonContainer.useContainer()
	const history = useHistory()

	// Get Category
	const [category, setCategory] = React.useState<Category>()
	const { error, payload, loading, query: refresh } = useQuery<Category[]>(getCategory(eventID, id), !isNew)

	React.useEffect(() => {
		if (!payload || payload.length === 0) return
		setCategory(payload[0])
	}, [payload, loading, error])

	// Edit Mode
	const [editMode, _setEditMode] = React.useState(isNew)
	const [showSuccessModal, setShowSuccessModal] = React.useState<string>()
	const setEditMode = (value: boolean) => {
		_setEditMode(value)
		history.replace({ search: value ? "edit=true" : undefined })
	}
	React.useEffect(() => {
		// Set edit mode based on edit search param
		if (isNew) return
		const params = new URLSearchParams(history.location.search)
		_setEditMode(params.get("edit") === "true")
	}, [history.location.search, isNew])

	const onEditComplete = (updatedCategory?: Category) => {
		if (updatedCategory) {
			setShowSuccessModal(isNew ? "Created" : "Updated")
			setCategory(updatedCategory)
			history.replace("/categories/" + updatedCategory.id)
		} else if (isNew) {
			history.push("/categories")
		}

		setEditMode(false)
	}

	// Archiving
	const { mutate, loading: mutating } = useMutation(updateCategory)
	const onArchive = () => mutate({ categoryID: id, archived: true }).then(() => refresh())
	const onUnarchive = () => mutate({ categoryID: id, archived: false }).then(() => refresh())

	// Deleting
	const { mutate: mutateDelete, loading: deleting, error: deleteError, payload: deletePayload } = useMutation(deleteCategory)
	const [showDeleteModal, setShowDeleteModal] = React.useState(false)

	// Styling
	const [css, theme] = useStyletron()
	const containerStyle = css({
		maxWidth: "900px",
		margin: "10px auto",
	})
	const nameStyle = css({
		...theme.typography.HeadingSmall,
	})
	const contentStyle = css({
		marginTop: "20px",
	})
	const detailStyle = css({
		padding: "10px",
		display: "grid",
		gridTemplateColumns: "150px auto",
	})
	const detailAltStyle = css({
		padding: "10px",
		display: "grid",
		gridTemplateColumns: "150px auto",
		backgroundColor: theme.colors.backgroundSecondary,
		borderRadius: "4px",
	})
	const headerStyle = css({
		display: "none",
		"@media only screen and (max-width: 700px)": {
			display: "flex",
		},
	})

	// Edit Mode?
	if (editMode)
		return (
			<div className={containerStyle}>
				<div className={headerStyle}>
					<H1>Categories</H1>
				</div>
				<div className={nameStyle}>{category ? category.name : "New Category"}</div>
				<CategoryEdit category={category} isNew={isNew} stopEditMode={onEditComplete} />
			</div>
		)

	// Loading
	if (!category && loading) return <Loading />
	if (!category) return <></>

	const details = [
		{ label: "Name", value: category.name },
		{ label: "Description", value: category.description },
		{ label: "Weight", value: category.weight },
		{ label: "Input Type", value: CategoryInputTypeNames[category.input_type] },
		{ label: "Score Labels", value: category.score_text ? category.score_text.join(", ") : "N/A" },
	]

	return (
		<div className={containerStyle}>
			<H1>Categories</H1>
			<Spread>
				<div className={nameStyle}>{category.name}</div>

				{/* Actions */}
				{event?.is_current && (
					<Spaced>
						{!category.archived && (
							<Button onClick={() => setEditMode(true)} startEnhancer={<FontAwesomeIcon icon={["fas", "edit"]} />}>
								Edit
							</Button>
						)}
						{!category.archived && (
							<Button onClick={onArchive} isLoading={mutating} startEnhancer={<FontAwesomeIcon icon={["fas", "archive"]} />}>
								Archive
							</Button>
						)}
						{category.archived && (
							<Button onClick={onUnarchive} isLoading={mutating} startEnhancer={<FontAwesomeIcon icon={["fas", "undo"]} />}>
								Unarchive
							</Button>
						)}
						{category.archived && (
							<Button onClick={() => setShowDeleteModal(true)} isLoading={mutating} startEnhancer={<FontAwesomeIcon icon={["fas", "trash-alt"]} />}>
								Delete
							</Button>
						)}
					</Spaced>
				)}
			</Spread>

			{/* Details */}
			<div className={contentStyle}>
				{details.map((detail, index) => (
					<div key={`category-detail-${detail.label}`} className={index % 2 === 0 ? detailAltStyle : detailStyle}>
						<LabelMedium $style={{ fontWeight: 600 }}>{`${detail.label}:`}</LabelMedium>
						<LabelMedium>{detail.value}</LabelMedium>
					</div>
				))}
			</div>

			{/* Success Modal */}
			<Modal onClose={() => setShowSuccessModal(undefined)} isOpen={!!showSuccessModal}>
				<ModalHeader>{`Category ${showSuccessModal} Successfully`}</ModalHeader>
				<ModalFooter>
					<ModalButton onClick={() => setShowSuccessModal(undefined)}>OK</ModalButton>
				</ModalFooter>
			</Modal>

			{/* Delete Modal */}
			<Modal onClose={() => setShowDeleteModal(false)} isOpen={showDeleteModal}>
				<ModalHeader>Delete Category?</ModalHeader>
				<ModalBody>
					<p>Are you sure you want to delete this category?</p>
					{deleteError && isAPIError(deletePayload) && <ErrorNotification message={deletePayload.shortMessage} />}
				</ModalBody>
				<ModalFooter>
					<ModalButton onClick={() => setShowDeleteModal(false)} kind="secondary">
						Cancel
					</ModalButton>
					<ModalButton
						onClick={async () => {
							const resp = await mutateDelete(id)
							if (!resp.error) history.push("/categories")
						}}
						isLoading={deleting}
						startEnhancer={<FontAwesomeIcon icon={["fas", "trash-alt"]} />}
					>
						Delete
					</ModalButton>
				</ModalFooter>
			</Modal>
		</div>
	)
}

interface CategoryInput {
	name?: string
	description?: string
	weight?: number
	inputType: CategoryInputType
	scoreText: string[]
	archived?: boolean
}

const CategoryEdit = (props: { category?: Category; isNew: boolean; stopEditMode: (category?: Category) => void }) => {
	const { category, isNew, stopEditMode } = props

	const { eventID } = CommonContainer.useContainer()

	// Setup form
	const { control, register, handleSubmit, setValue, trigger, errors } = useForm<CategoryInput>({
		defaultValues: {
			name: "",
			description: "",
			weight: 5,
			inputType: CategoryInputType.Slider,
			scoreText: scoreTextPresets[0],
		},
	})
	const { mutate: update, loading, error, payload } = useMutation(isNew ? createCategory : updateCategory)

	const onSaveForm = handleSubmit(async ({ weight, ...rest }) => {
		if (!trigger()) return

		const input = {
			weight: `${weight}`, // force weight to be a string (for compatibility with api)
			...rest,
		}

		const resp = await update(!!category ? { categoryID: category.id, ...input } : { eventID, ...input })
		// Error?
		if ((error && isAPIError(resp.payload)) || (resp.payload as APIError).error) return
		const updatedCategory = resp.payload as Category
		// On Success
		if (updatedCategory) stopEditMode(updatedCategory)
	})

	/** Checks the current score text values and determines if it's currently using one of the presets */
	const getCurrentPreset = React.useCallback(() => {
		if (!category) return scoreTextPresetOptions[0]

		for (let i = 0; i < scoreTextPresets.length; i++) {
			const different = scoreTextPresets[i].some((label, i) => label !== category.score_text[i])
			if (!different) return scoreTextPresetOptions[i]
		}

		return scoreTextPresetOptions[scoreTextPresetOptions.length - 1]
	}, [category])
	const [scoreLabelPreset, setScoreLabelPreset] = React.useState<Option>(getCurrentPreset())
	const [editScoreLabels, setEditScoreLabels] = React.useState(false)

	// Load defaults
	React.useEffect(() => {
		if (!category) return
		setValue("name", category.name)
		setValue("description", category.description)
		setValue("weight", category.weight)
		setValue("inputType", category.input_type)
		setValue("scoreText", category.score_text)
		setScoreLabelPreset(getCurrentPreset())
	}, [category, setValue, getCurrentPreset])

	// Styling
	const [css] = useStyletron()
	const footerStyle = css({
		display: "flex",
		flexDirection: "row-reverse",
	})

	return (
		<form onSubmit={onSaveForm}>
			{error && isAPIError(payload) && <ErrorNotification message={payload.shortMessage} />}

			<FormControl label="Name" error={errors.name?.message} positive="">
				<Input name="name" inputRef={register({ required: "Name is required" })} disabled={loading} />
			</FormControl>

			<FormControl label={<InlineLabel label="Description" caption="(optional)" />} error={errors.description?.message} positive="">
				<Controller
					name="description"
					defaultValue=""
					control={control}
					as={Textarea}
					overrides={{
						Input: {
							style: {
								resize: "vertical",
								minHeight: "46px",
							},
						},
					}}
					disabled={loading}
				/>
			</FormControl>

			<FormControl label="Weight" error="" positive="">
				<Controller
					name="weight"
					control={control}
					onChange={([{ value }]: any) => value}
					defaultValue={5}
					render={({ value, onChange }) => {
						return (
							<>
								<Input
									name="weight"
									value={value}
									onChange={(e) => {
										let v = +e.currentTarget.value
										if (v < 0) v = 0
										else if (v > 100) v = 100
										onChange(v)
									}}
									type="number"
									disabled={loading}
								/>
								<Slider value={[value]} onChange={(e) => onChange(e.value[0])} min={0} max={100} overrides={NumberSliderOverrides} />
							</>
						)
					}}
				/>
			</FormControl>

			<FormControl
				label={<InlineLabel label="Input Type" caption="Judges can input their score via a slider or by typing in their desired score." />}
				error={errors.inputType && errors.inputType.message}
			>
				<Controller
					name="inputType"
					control={control}
					rules={required}
					render={({ onChange, value }) => {
						const v = inputTypeOptions.find((i) => i.id === value)
						return (
							<Select
								options={inputTypeOptions}
								onChange={({ value }) => value[0] && onChange(value[0].id)}
								value={v ? [v] : []}
								clearable={false}
								error={errors.inputType !== undefined}
								disabled={loading}
								searchable={false}
							/>
						)
					}}
				/>
			</FormControl>

			<FormControl
				label={<InlineLabel label="Score Labels" caption="The helper text displayed at different score values (0-10)" />}
				error={errors.scoreText ? "Invalid input" : ""}
			>
				<Controller
					name="scoreText"
					control={control}
					rules={required}
					render={({ onChange, value }) => {
						return (
							<div>
								<Spread>
									<p>{`${value[1]} - ${value[value.length - 1]}`}</p>
									<Spaced>
										{/* Apply Preset */}
										<Select
											options={scoreTextPresetOptions}
											onChange={({ value }) => {
												if (!value[0]) return
												setScoreLabelPreset(value[0])
												if (value[0].id !== scoreTextPresetOptions.length - 1) onChange(value[0].value)
											}}
											value={[scoreLabelPreset]}
											clearable={false}
											error={errors.inputType !== undefined}
											disabled={loading}
											overrides={{
												Root: {
													style: {
														minWidth: "230px",
													},
												},
											}}
											searchable={false}
										/>
										{/* Toggle edit fields */}
										<Button onClick={() => setEditScoreLabels(!editScoreLabels)} type="button" kind="secondary">
											<FontAwesomeIcon icon={["fas", "edit"]} />
										</Button>
									</Spaced>
								</Spread>
								{/* Edit fields for each score (0-10) */}
								{editScoreLabels &&
									[...Array(11)].map((_, i) => (
										<div key={`scoreText-${i}`} style={{ display: "flex" }}>
											<FormControl label={`Score ${i}`}>
												<Input
													value={value[i]}
													onChange={(e) => {
														const v: string[] = [...value]
														v[i] = e.currentTarget.value
														onChange(v)
														setScoreLabelPreset(scoreTextPresetOptions[scoreTextPresetOptions.length - 1])
													}}
												/>
											</FormControl>
										</div>
									))}
							</div>
						)
					}}
				/>
			</FormControl>

			<div className={footerStyle}>
				<Spaced>
					<Button type="button" onClick={() => stopEditMode()} kind="secondary">
						Cancel
					</Button>
					<Button onClick={onSaveForm} isLoading={loading} startEnhancer={<FontAwesomeIcon icon={["fas", "save"]} />}>
						Save
					</Button>
				</Spaced>
			</div>
		</form>
	)
}
