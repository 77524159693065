import { Action } from "react-fetching-library"
import { APIError, User } from "../api/types"

export const AuthCheck: Action<{ payload: User } | APIError> = {
	method: "GET",
	endpoint: "/auth/user/check",
	credentials: "include",
	responseType: "json",
}
export const SignInAction = (values: { email: string; password: string }): Action<User | APIError> => ({
	method: "POST",
	endpoint: "/auth/user/signin",
	credentials: "include",
	body: { params: { ...values, webportal: true } },
	responseType: "json",
})
export const SignOutAction = (): Action<SignOutActionResponse | APIError> => ({
	method: "GET",
	endpoint: "/auth/user/signout",
	credentials: "include",
	responseType: "json",
})
export const Register = (values: { email: string; firstName: string; lastName: string; contactNumber: string; password: string }): Action<User | APIError> => ({
	method: "POST",
	endpoint: "/auth/user/register",
	body: { params: values },
	responseType: "json",
})

export const ResendVerifyEmail = (email: string): Action<APIError> => ({
	method: "POST",
	endpoint: "/auth/user/resend_verify",
	body: { params: { email } },
	responseType: "json",
})

export const VerifyEmail = (values: { email: string; token: string }): Action<User | APIError> => ({
	method: "POST",
	endpoint: "/auth/user/verify",
	credentials: "include",
	body: { params: values },
	responseType: "json",
})

export const VerifyResetToken = (values: { email: string; token: string }): Action<APIError> => ({
	method: "POST",
	endpoint: "/auth/user/reset_password",
	body: { params: values },
	responseType: "json",
})

export const SendForgotPasswordEmail = (email: string): Action<APIError> => ({
	method: "POST",
	endpoint: "/auth/user/forgot_password",
	body: { params: { email } },
	responseType: "json",
})

export const ResetPassword = (values: { email: string; token: string; newPassword: string }): Action<APIError> => ({
	method: "POST",
	endpoint: "/auth/user/execute_reset_password",
	body: { params: values },
	responseType: "json",
})

interface SignOutActionResponse {
	response: {
		count: number
		message: string
		success: true
	}
	payload: {
		status: string
		message: string
		id: string
		email: string
		role: string
		verified: true
		eventID: string
		isGuest: false
	}
}
