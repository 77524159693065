import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { ORIENTATION, Tab, Tabs } from "baseui/tabs-motion"
import { H3 } from "baseui/typography"
import * as React from "react"
import { RouteComponentProps, useHistory } from "react-router-dom"
import { useStyletron } from "styletron-react"
import { ObjectType } from "../api/enums"
import { ItemList } from "../components/itemList"
import { CommonContainer } from "../lib/controller"
import { CategoryList } from "./management/categories"
import { QuestionList } from "./management/questions"
import { TeamAssessmentList } from "./management/teamAssessments"
import { TeamList } from "./management/teams"

export const JudgeList = (props: RouteComponentProps<{ id: string }>) => {
	const { event } = CommonContainer.useContainer()
	// Styling
	const [css] = useStyletron()
	const container = css({
		margin: "10px auto",
		maxWidth: "900px",
	})
	const headerStyle = css({
		display: "none",
		"@media only screen and (max-width: 700px)": {
			display: "flex",
		},
	})

	const id = props.match.params.id
	const history = useHistory()
	const [activeKey, setActiveKey] = React.useState<React.Key>(history.location.hash || "#judges")
	React.useEffect(() => {
		setActiveKey(history.location.hash || "#judges")
	}, [history.location.hash])

	return (
		<div className={container}>
			<div className={headerStyle}>
				<H3 $style={{ marginBottom: "5px", marginTop: 0 }}>Judges</H3>
			</div>
			<Tabs
				orientation={ORIENTATION.vertical}
				activeKey={activeKey}
				onChange={({ activeKey }) => {
					setActiveKey(activeKey)
					history.push({ hash: activeKey.toString() })
				}}
				activateOnFocus
				overrides={{
					TabHighlight: {
						style: {
							width: "3px",
							marginRight: "7px",
						},
					},
					TabBorder: {
						style: { width: "1px" },
					},
					TabList: {
						style: {
							width: "15%",
							minWidth: "160px",
						},
					},
				}}
			>
				<Tab key="#judges" title="Judges">
					<ItemList
						hideActions={!event?.is_current}
						type={ObjectType.Judge}
						lastEditID={id}
						columns={[
							{
								name: "Email",
								resolver: (value) => value.email,
							},
							{
								name: "PIN",
								resolver: (value) => (value.is_guest ? "GUEST" : value.pin),
							},
							{
								name: "Guest",
								resolver: (value) => value.is_guest === true && <FontAwesomeIcon icon={["fal", "check"]} />,
							},
						]}
						canImport
						canExport
					/>
				</Tab>
				<Tab key="#groups" title="Groups">
					<ItemList
						hideActions={!event?.is_current}
						type={ObjectType.Group}
						columns={[
							{
								name: "Name",
								resolver: (value) => value.name,
							},
							{
								name: "No. of Teams",
								resolver: (value) => value.assigned_teams?.length || 0,
							},
							{
								name: "No. of Judges",
								resolver: (value) => value.assigned_judges?.length || 0,
							},
						]}
						hideArchiveToggle={true}
					/>
				</Tab>
			</Tabs>
		</div>
	)
}

export const ListPage = {
	Team: TeamList,
	Judge: JudgeList,
	Category: CategoryList,
	TeamAssessments: TeamAssessmentList,
	Questions: QuestionList,
}
