import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { useStripe } from "@stripe/react-stripe-js"
import { useStyletron } from "baseui"
import { Button } from "baseui/button"
import { Spinner } from "baseui/spinner"
import { toaster } from "baseui/toast"
import * as React from "react"
import { useMutation, useParameterizedQuery, useQuery } from "react-fetching-library"
import { StyleObject } from "styletron-react"
import { AccountType } from "../api/enums"
import { isAPIError } from "../api/types"
import { ErrorNotification } from "../components/common"
import { Loading } from "../components/loading"
import { CommonContainer } from "../lib/controller"
import { GetBillingPortalURL, GetCheckoutSessionID, GetStripePrices, GetSubscriptionStatus, RevenueCatEntitlement, StripePlanPrices } from "../lib/stripe"

export const UserSubscription = () => {
	// Opening stripe checkout
	const stripe = useStripe()
	const { user } = CommonContainer.useContainer()
	const { mutate, error, payload, loading } = useMutation(GetCheckoutSessionID)
	const { payload: prices } = useQuery(GetStripePrices)
	const { payload: subStatus } = useQuery(GetSubscriptionStatus)
	const accountType = user?.accountType || AccountType.Free
	const { query: getPortalURL } = useParameterizedQuery<string>(GetBillingPortalURL)

	React.useEffect(() => {
		const isCheckoutSuccessful = new URLSearchParams(window.location.search).get("success") === "true"
		const subscriptionId = parseInt(new URLSearchParams(window.location.search).get("subscription") || "-1")

		let productInfo = ""
		if (isCheckoutSuccessful && subscriptionId !== -1) {
			// If a subscription was just bought successfully
			productInfo = `Judgefest's ${AccountType[subscriptionId]} plan`
		} else if (isCheckoutSuccessful)
			// If event was unlocked successfully
			productInfo = `Judgefest's Unlock Event product`

		if (isCheckoutSuccessful) {
			// Display toast
			toaster.info(
				<>
					Thank you for purchasing {productInfo}. We are now processing your purchase. Please refresh the page in a few minutes so that changes may be applied.
				</>,
				{},
			)
		}
	}, [])

	const onSubscribe = async (cardType: AccountType) => {
		if (cardType === AccountType.Enterprise) {
			window.open("mailto:sales@judgefest.com")
			return
		}
		if (!stripe) return

		// Get product ID from card type
		let productID: string | undefined
		switch (cardType) {
			case AccountType.NewProfessional:
				productID = prices?.new_professional.product_id
		}
		if (!productID) return

		// Get checkout session
		const resp = await mutate({
			successURL: `${window.location.href.split("?")[0]}?success=true&subscription=${cardType}`,
			cancelURL: window.location.href.split("?")[0],
			productID,
		})
		if (resp.error || !resp.payload) return
		// Redirect to checkout
		stripe.redirectToCheckout({
			sessionId: resp.payload,
		})
	}

	const onManageBilling = async () => {
		// todo replace with revenuecat's management url
		try {
			const resp = await getPortalURL(window.location.href)
			if (resp.error || !resp.payload) return
			window.open(resp.payload, "_blank")
		} catch {}
	}

	// Styling
	const [css] = useStyletron()
	const container = css({
		maxWidth: "1200px",
		margin: "auto",
	})
	const centerStyle = css({
		display: "flex",
		flexDirection: "column",
		alignItems: "center",
		marginBottom: "10px",
		marginTop: "20px",
	})
	const gridStyle = css({
		width: "100%",
		display: "grid",
		gridTemplateColumns: "repeat(auto-fill, minmax(300px, 1fr))",
		gap: "1rem",
		"@media only screen and (max-width: 1400px)": {
			gridTemplateColumns: "repeat(auto-fill, minmax(300px, 1fr))",
		},
		"@media only screen and (max-width: 500px)": {
			gridTemplateColumns: "100%",
		},
	})
	if (!user || !prices) return <Loading />

	if (accountType >= AccountType.Enterprise) {
		return (
			<div className={container}>
				<div className={centerStyle}>Account currently Enterprise, contact us for help and details.</div>
			</div>
		)
	}

	return (
		<div className={container}>
			<div className={centerStyle}>
				{error && <ErrorNotification message={isAPIError(payload) ? payload.shortMessage : "Failed to open checkout"} />}
				<div className={gridStyle}>
					<AccountTypeCard
						cardType={AccountType.Free}
						userAccountType={accountType}
						checkoutPayloadLoading={loading}
						onSubscribe={onSubscribe}
						onManageBilling={onManageBilling}
						prices={prices}
					/>
					<AccountTypeCard
						cardType={AccountType.NewProfessional}
						userAccountType={accountType}
						checkoutPayloadLoading={loading}
						onSubscribe={onSubscribe}
						onManageBilling={onManageBilling}
						prices={prices}
						entitlementStatus={subStatus?.entitlements}
					/>
					<AccountTypeCard
						cardType={AccountType.Enterprise}
						userAccountType={accountType}
						checkoutPayloadLoading={loading}
						onSubscribe={onSubscribe}
						onManageBilling={onManageBilling}
						prices={prices}
					/>
				</div>
			</div>
		</div>
	)
}

interface AccountTypeCardProps {
	cardType: AccountType
	setSelectedCard?: React.Dispatch<React.SetStateAction<AccountType | undefined>>
	userAccountType: AccountType
	checkoutPayloadLoading?: boolean
	onSubscribe?: (accType: AccountType, checkPayService?: boolean | undefined) => Promise<void>
	onManageBilling: (subService?: string | undefined) => Promise<void>
	prices: StripePlanPrices
	entitlementStatus?: Record<string, RevenueCatEntitlement>
}

export const AccountTypeCard = ({
	cardType,
	checkoutPayloadLoading,
	onSubscribe,
	onManageBilling,
	userAccountType,
	prices,
	entitlementStatus,
}: AccountTypeCardProps) => {
	const current = cardType === userAccountType
	const status = current ? "Active" : ""

	// Styling
	const [css, theme] = useStyletron()
	const container = css({
		position: "relative",
		overflow: "hidden",
		backgroundColor: "#363c49",
		color: "#d9deed",
		width: "100%",
		display: "flex",
		flexDirection: "column",
		borderRadius: "20px",
		boxSizing: "border-box",
		boxShadow:  userAccountType === cardType ? `0 0 0 4px ${theme.colors.accent}` : theme.lighting.shadow400,
		"@media only screen and (max-width: 980px)": {
			maxWidth: "380px",
			margin: "10px auto",
		},
	})
	const titleStyle = css({
		fontSize: "1.875em",
		padding: "20px 0",
		fontWeight: "bold",
		height: "2.5rem",
		display: "flex",
		alignItems: "center",
		justifyContent: "center",
		textTransform: "uppercase",
	})
	const priceStyle = css({
		fontSize: "3.625em",
		fontWeight: "bold",
		color: cardType === AccountType.Enterprise ? "transparent" : "#d9deed",
		backgroundColor: "#434c60",
		minHeight: "7rem",
		display: "flex",
		justifyContent: "center",
		alignItems: "center",
		flexDirection: "column",
		"@media only screen and (max-width: 1080px)": {
			fontSize: "2.2em",
		},
	})
	const priceWrap = css({
		display: "flex",
		alignItems: "center",
		justifyContent: "center",
	})
	const pricePerStyle = css({
		fontSize: "52%",
		color: "#d9deed",
	})
	const infoStyle = css({
		padding: "0 2rem",
	})
	const buttonContainerStyle = css({
		height: "100%",
		display: "flex",
		marginBottom: "10px",
		borderBottomLeftRadius: "10px",
		borderBottomRightRadius: "10px",
		flexDirection: "column-reverse",
	})
	const buttonStyle = css({
		...theme.typography.font450,
		fontWeight: "bold",
		padding: "10px",
		textAlign: "center",
		margin: "10px",
		borderRadius: "10px",
		backgroundColor: current ? theme.colors.white : theme.colors.accent,
		color: current ? "#363c49" : theme.colors.white,
		border: "2px solid #d9deed",
		cursor: current ? "default" : "pointer",
		":hover": current
			? {}
			: {
					backgroundColor: "rgb(16 161 234)",
			  },
		transition: "0.2s ease",
		outline: "unset",
		display: "flex",
		alignItems: "center",
		justifyContent: "center",
	})
	const statusDateStyle = css({
		color: "grey",
		fontSize: "12px",
		lineHeight: "12px",
		whiteSpace: "break-spaces",
		marginTop: "4px",
	})
	const newCover = css({
		position: "absolute",
		right: "0",
		width: "150px",
		transformOrigin: "0 0",
		transform: "translate(50%, -120%) rotate(45deg)",
		backgroundColor: "#a11d00",
		textAlign: "center",
		color: "white",
	})

	const dateString =
		entitlementStatus && entitlementStatus["professional_entitlement"] && entitlementStatus["professional_entitlement"].expires_date
			? new Date(entitlementStatus["professional_entitlement"].expires_date).toLocaleDateString(undefined, {
					day: "numeric",
					month: "long",
					year: "numeric",
			  })
			: null

	return (
		<div className={container}>
			{cardType === AccountType.NewProfessional && <div className={newCover}>New!</div>}
			<div>
				<div className={titleStyle}>{cardType === AccountType.NewProfessional ? "Professional" : AccountType[cardType]}</div>
				<div className={priceStyle}>
					<div className={priceWrap}>
						{cardType === AccountType.Enterprise && <sub className={pricePerStyle}> Contact Us</sub>}
						{cardType === AccountType.NewProfessional
							? "$" + prices.new_professional.price / 100
							: cardType === AccountType.Professional
							? "n/a"
							: cardType === AccountType.Enterprise
							? ""
							: cardType === AccountType.Free
							? "$0"
							: "-"}
						{cardType === AccountType.NewProfessional ? (
							<sub className={pricePerStyle}>/yr</sub>
						) : (
							cardType !== AccountType.Enterprise && <sub className={pricePerStyle}>/mo</sub>
						)}
					</div>
				</div>
			</div>
			<div className={infoStyle}>{AccountTypeDescription(cardType, css)}</div>

			{(current || userAccountType === AccountType.Free) && (
				<div className={buttonContainerStyle}>
					<button
						className={buttonStyle}
						onClick={(e) => {
							if (onSubscribe) {
								if (!current) onSubscribe(cardType, true)
							}
						}}
					>
						<div>
							{current ? (
								cardType === AccountType.Free || userAccountType === AccountType.Professional ? (
									<div>Active</div>
								) : (
									<div>
										<div>{status}</div>
										{userAccountType === AccountType.NewProfessional && dateString && <div className={statusDateStyle}>Renewing on {dateString}</div>}
									</div>
								)
							) : cardType === AccountType.Enterprise ? (
								"Contact Us"
							) : (
								"Subscribe"
							)}
						</div>
					</button>
				</div>
			)}
			{!current && userAccountType !== AccountType.Free && userAccountType < cardType && (
				<div className={buttonContainerStyle}>
					<button
						className={buttonStyle}
						onClick={(e) => {
							if (onSubscribe) {
								onSubscribe(cardType, true)
							}
						}}
					>
						{cardType !== AccountType.Enterprise && checkoutPayloadLoading && <Spinner size="24px" color="white" />}
						<div style={{ marginLeft: checkoutPayloadLoading ? "10px" : "unset" }}>
							{current ? (
								<div>
									<div>{status}</div>
									{userAccountType === AccountType.NewProfessional && dateString && <div className={statusDateStyle}>Renewing on {dateString}</div>}
								</div>
							) : cardType === AccountType.Enterprise ? (
								"Contact Us"
							) : (
								"Upgrade"
							)}
						</div>
					</button>
				</div>
			)}
			{current && userAccountType !== AccountType.Free && (
				<Button
					onClick={(e) => onManageBilling()}
					overrides={{
						BaseButton: {
							style: {
								borderBottomLeftRadius: "10px",
								borderBottomRightRadius: "10px",
								backgroundColor: theme.colors.accent,
								color: theme.colors.white,
								fontSize: "24px",
								fontWeight: "bold",
							},
						},
					}}
					startEnhancer={<FontAwesomeIcon icon={["fal", "credit-card"]} />}
				>
					Manage
				</Button>
			)}
		</div>
	)
}

export const AccountTypeDescription = (accountType: AccountType, css: (arg: StyleObject) => string) => {
	const listStyle = css({
		padding: 0,
	})

	switch (accountType) {
		case AccountType.Free:
			return (
				<ul className={listStyle}>
					<li>
						A maximum of <strong>1</strong> open judging event and <strong>1</strong> open opinion event
					</li>
					<li>
						A maximum of <strong>5</strong> scoring categories for judging events and <strong>100</strong> questions for open opinion events
					</li>
					<li>
						A maximum of <strong>5</strong> teams per judging event
					</li>
					<li>
						A maximum of <strong>25</strong> event participants
					</li>
				</ul>
			)
		case AccountType.Basic:
			return (
				<ul>
					<li>
						<i>This plan is no longer available.</i>
					</li>
					<li>
						A maximum of <strong>5</strong> open judging events and <strong>5</strong> open opinion events
					</li>
					<li>
						A maximum of <strong>20</strong> teams per judging event
					</li>
					<li>
						A maximum of <strong>200</strong> event participants
					</li>
					<li>Import/Export support (via CSVs) for Teams, Judges and Categories</li>
				</ul>
			)
		case AccountType.Professional:
			return (
				<ul className={listStyle}>
					<li>
						<i>This plan is no longer available.</i>
					</li>
					<li>
						A maximum of <strong>20</strong> open judging events and <strong>20</strong> open opinion events
					</li>
					<li>
						A maximum of <strong>40</strong> teams per judging event
					</li>
					<li>
						A maximum of <strong>1000</strong> event participants
					</li>
					<li>Import/Export support (via CSVs) for Teams, Judges and Categories</li>
					<li>Event team user management - invite other users to help manage your events</li>
				</ul>
			)
		case AccountType.NewProfessional:
			return (
				<ul className={listStyle}>
					<li>
						<strong>Unlimited</strong> open judging events and open opinion events
					</li>
					<li>
						A maximum of <strong>200</strong> teams per judging event
					</li>
					<li>
						A maximum of <strong>1000</strong> event participants
					</li>
					<li>Import/Export support (via CSVs) for Teams, Judges and Categories</li>
					<li>Event team user management - invite other users to help manage your events</li>
					<li>Removes all ads for all events on the mobile app</li>
				</ul>
			)
		case AccountType.Enterprise:
			return (
				<ul className={listStyle}>
					<li>
						Over <strong>10,000</strong> live event participants
					</li>
					<li>
						<strong>Custom </strong> developed functionality
					</li>
					<li>
						Geographically distributed services for <strong>low latency</strong>
					</li>
				</ul>
			)
	}
}
