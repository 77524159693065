import React from "react"
import { Button } from "baseui/button"
import { Input } from "baseui/input"
import { StyledBody } from "baseui/card"
import { useStyletron } from "baseui"
import { AccentButtonOverrides, GreyButtonOverrides } from "../themeOverrides"
import { useHistory } from "react-router-dom"
import { CommonContainer } from "../lib/controller"
import { ErrorNotification } from "../components/common"
import { VersionText } from "../components/version"

export const SignUp = () => {
	const [css] = useStyletron()
	const history = useHistory()
	const { signUp } = CommonContainer.useContainer()

	const [error, setError] = React.useState<string>()
	const [errors, setErrors] = React.useState<boolean[]>([false, false])
	const [email, setEmail] = React.useState<string>("")
	const [firstName, setFirstName] = React.useState<string>("")
	const [lastName, setLastName] = React.useState<string>("")
	const [contactNumber, setContactNumber] = React.useState<string>("")

	const [password, setPassword] = React.useState<string>("")

	const onSignUp = async () => {
		if (email === "" || firstName === "" || lastName === "" || contactNumber === "" || password === "") {
			setErrors([email === "", firstName === "", lastName === "", contactNumber === "", password === ""])
			return
		}
		setErrors([false, false, false, false, false])

		const resp = await signUp(email, firstName, lastName, contactNumber, password)
		// On successful sign up -> redirect to login page and open verify dialog
		if (!resp) history.push(`/?email=${email}&verify=true`)
		else setError(resp)
	}

	const jfLogo = require("../assets/images/jfLogo.png")

	const parentStyle = css({
		display: "flex",
		flexDirection: "column",
		minHeight: "100vh",
		overflow: "auto",
		justifyContent: "center",
		alignItems: "center",
		background: "linear-gradient(180deg, rgba(0,104,157,1) 0%, rgba(97,88,204,1) 100%)",
	})
	const image = css({
		textAlign: "center",
		width: "200px",
		paddingBottom: "30px",
		"@media only screen and (max-height: 550px)": {
			paddingBottom: "10px",
		},
	})
	const titleStyle = css({
		color: "white",
		fontSize: "30px",
		"@media only screen and (max-height: 680px)": {
			display: "none",
		},
	})
	const cardStyle = css({
		minWidth: "320px",
		"@media only screen and (max-width: 320px)": {
			minWidth: "unset",
		},
	})
	const inputStyle = css({
		margin: "10px 0 10px 0",
	})
	const btnStyle = css({
		display: "flex",
		flexDirection: "column",
		justifyContent: "center",
		paddingBottom: "20px",
	})
	const versionWrap = css({
		position: "absolute",
		right: "6px",
		bottom: "10px",
		"@media only screen and (max-height: 780px) and (max-width: 580px)": {
			display: "none",
		},
	})

	return (
		<div className={parentStyle}>
			<div style={{ minHeight: "0px" }}>
				<img onClick={() => history.push("/")} className={image} src={jfLogo} alt="JudgeFest" />
			</div>
			<p className={titleStyle}>Register</p>
			<form
				className={cardStyle}
				onSubmit={(e) => {
					e.preventDefault()
					onSignUp()
				}}
			>
				<StyledBody>
					<div className={inputStyle}>
						<Input
							type="email"
							value={email}
							onChange={(e) => {
								const target = e.target as HTMLTextAreaElement
								setEmail(target.value)
							}}
							placeholder="Email"
							error={errors[0]}
						/>
					</div>
					<div className={inputStyle}>
						<Input
							type="text"
							value={firstName}
							onChange={(e) => {
								const target = e.target as HTMLTextAreaElement
								setFirstName(target.value)
							}}
							placeholder="First Name"
							error={errors[1]}
						/>
					</div>
					<div className={inputStyle}>
						<Input
							type="text"
							value={lastName}
							onChange={(e) => {
								const target = e.target as HTMLTextAreaElement
								setLastName(target.value)
							}}
							placeholder="Last Name"
							error={errors[2]}
						/>
					</div>
					<div className={inputStyle}>
						<Input
							type="text"
							value={contactNumber}
							onChange={(e) => {
								const target = e.target as HTMLTextAreaElement
								setContactNumber(target.value)
							}}
							placeholder="Phone Number"
							error={errors[3]}
						/>
					</div>
					<Input
						type="password"
						value={password}
						onChange={(e) => {
							const target = e.target as HTMLTextAreaElement
							setPassword(target.value)
						}}
						placeholder="Password"
						error={errors[4]}
					/>
					{error && <ErrorNotification message={error} />}
				</StyledBody>
				<div className={btnStyle}>
					<Button overrides={AccentButtonOverrides}>Sign up</Button>
					<Button onClick={() => history.push("/")} overrides={GreyButtonOverrides} type="button">
						Back
					</Button>
				</div>
			</form>

			<div className={versionWrap}>
				<VersionText />
			</div>
		</div>
	)
}
