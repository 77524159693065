import { IconProp } from "@fortawesome/fontawesome-svg-core"
import { faPlus } from "@fortawesome/pro-light-svg-icons"
import { faHistory } from "@fortawesome/pro-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { styled, useStyletron } from "baseui"
import { Block } from "baseui/block"
import { Button } from "baseui/button"
import { FormControl } from "baseui/form-control"
import { Input } from "baseui/input"
import { ListItem, ListItemLabel } from "baseui/list"
import { Modal, ModalBody, ModalButton, ModalFooter, ModalHeader, ROLE, SIZE } from "baseui/modal"
import { Select } from "baseui/select"
import { Theme } from "baseui/theme"
import * as React from "react"
import { useMutation, useQuery } from "react-fetching-library"
import { Link, useHistory, useLocation } from "react-router-dom"
import { EventType } from "../api/enums"
import { isAPIError } from "../api/types"
import BoostBoltSVG from "../assets/images/boost bolt.svg"
import BoostedTagSVG from "../assets/images/boosted tag.svg"
import LogoSVG from "../assets/images/JudgeFest logo wide.svg"
import UpgradeIconSVG from "../assets/images/upgrade_icon.svg"
import { CommonContainer } from "../lib/controller"
import { createEvent, Event, EventUpdateRequest, getEventHistory, getEvents } from "../lib/events"
import { generateShortcode } from "../utils"
import { ErrorNotification } from "./common"
import { Spaced } from "./spaced"

export const SideBar = () => {
	const history = useHistory()
	const { eventID, event, removeEvent, setEvent } = CommonContainer.useContainer()
	const { query: refreshEvents, payload: events } = useQuery(getEvents())
	const { mutate, error, payload: createPayload } = useMutation(createEvent)
	const { payload: historicalEvents } = useQuery(getEventHistory(eventID))
	const [eventCreatePayload, setEventCreatePayload] = React.useState<EventUpdateRequest>({
		newname: "",
		newshortcode: "",
	})
	const [showCreateEventModal, setShowCreateEventModal] = React.useState(false)
	const [showEventHistoryModal, setShowEventHistoryModal] = React.useState(false)

	// Refresh list on event update
	React.useEffect(() => {
		if (!!event) refreshEvents()
	}, [event, refreshEvents])

	const onSubmit = async () => {
		const resp = await mutate(eventCreatePayload)
		if (resp.error) {
			await refreshEvents()
			return
		}
		if (!!resp.payload) {
			// Set event and redirect to dashboard
			setEvent((resp.payload as Event).id)
			setShowCreateEventModal(false)
			setEventCreatePayload({
				newname: "",
				newshortcode: "",
			})
		}
	}

	const generateNames = (name: string) => {
		const generatedShortname = generateShortcode(name)
		setEventCreatePayload({ ...eventCreatePayload, newname: name, newshortcode: generatedShortname })
	}

	const [, theme] = useStyletron()
	const Sidebar = styled("nav", {
		display: "flex",
		flexDirection: "column",
		height: "100%",
		minWidth: "300px",
		flexShrink: 0,
		backgroundColor: theme.colors.primary,
		color: "white",
		boxShadow: "1px 3px 6px #00000090",
		"@media only screen and (max-width: 700px)": {
			width: "80px",
		},

		"::-webkit-scrollbar": {
			width: "4px",
		},
		"::-webkit-scrollbar-track": {
			background: theme.colors.primary,
		},
		"::-webkit-scrollbar-thumb": {
			background: "#BBB",
		},
		"::-webkit-scrollbar-thumb:hover": {
			background: "#777",
		},
	})
	const LogoContainer = styled("div", {
		display: "flex",
		justifyContent: "center",
		alignItems: "center",
		padding: ".3rem 1rem",
	})
	const Logo = styled("img", {
		display: "block",
		height: "100%",
	})
	const EventPickerAndNavContainer = styled("div", {
		overflow: "hidden",
		display: "flex",
		height: "100%",
	})
	const NavWrap = styled("div", {
		overflowY: "auto",
		flex: 1,
	})
	const CurrentEvent = styled("div", {
		display: "flex",
		alignItems: "center",
		height: "80px",
	})
	const EventPickerWrap = styled("div", {
		overflowY: "auto",
		position: "relative",
		minWidth: "60px",
		padding: "0 0.1rem",
		background: "#242729",
		borderRight: "2px solid #31343a",
	})
	const EventLogo = styled("img", {
		position: "absolute",
		display: "block",
		height: "calc(100% - 4px)",
		width: "calc(100% - 4px)",
		transform: "translate(2px, 2px)",
		borderRadius: "50%",
	})
	const EventLogoInitials = styled("p", {
		position: "absolute",
		top: "50%",
		left: "50%",
		display: "block",
		margin: 0,
		transform: "translate(-50%, -50%)",
		color: "white",
		fontSize: "1.4rem",
		fontWeight: "bolder",
	})
	const EventLogoUnlocked = styled("img", {
		position: "absolute",
		bottom: "0",
		right: "0",
		height: "16px",
		width: "16px",
		borderRadius: "50%",
		background: "linear-gradient(to bottom right, #e284b3, #7a92d8, #44b6b4)",
	})
	const EventShortode = styled("p", {
		overflow: "hidden",
		width: "7ch",
		margin: 0,
		marginTop: ".3rem",
		whiteSpace: "nowrap",
		textOverflow: "ellipsis",
		fontWeight: "bold",
		color: "grey",
	})
	const EventButton = styled<
		{
			$eventId?: string
		},
		"button",
		Theme
	>("button", (props) => ({
		overflow: "hidden",
		display: "flex",
		flexDirection: "column",
		alignItems: "center",
		width: "100%",
		padding: ".5rem",
		border: "none",
		outline: "none",
		background: event?.id === props.$eventId ? "#181a1b" : "#242729",
		cursor: "pointer",
		borderBottom: "1px solid #31343a",
		":hover": {
			background: "#181a1b",
		},
	}))
	const EventLogoBackground = styled<
		{
			$eventUnlocked?: boolean
		},
		"div",
		Theme
	>("div", (props) => ({
		position: "relative",
		height: "44px",
		width: "44px",
		borderRadius: "50%",
		background: props.$eventUnlocked ? "linear-gradient(to bottom right, #e284b3, #7a92d8, #44b6b4)" : "#181a1b",
	}))
	const UnlockedByText = styled("p", {
		margin: "0",
		padding: ".5rem",
		fontWeight: "bold",
		color: "grey",
	})

	let index = 0

	if (!event || !events || !historicalEvents) {
		return (
			<Sidebar>
				<LogoContainer>
					<Link to="/">
						<Logo src={LogoSVG} alt={"JudgeFest logo"} />
					</Link>
				</LogoContainer>
				<p>Loading...</p>
			</Sidebar>
		)
	}

	const renderEventButtons = () => {
		const buttons: React.ReactNode[] = []
		events.forEach((e, index) => {
			buttons.push(
				<EventButton
					title={e.shortcode}
					key={index}
					$eventId={e.id}
					onClick={() => {
						if (e.id === event.id) return
						removeEvent()
						setEvent(e.id)
						// Redirect to dashboard on event change
						history.push("/")
					}}
				>
					<EventLogoBackground $eventUnlocked={e.unlocked}>
						{e.logo ? <EventLogo src={`data:image/jpeg;base64,${e.logo}`} /> : <EventLogoInitials>{e.shortcode.substring(0, 1)}</EventLogoInitials>}
						{e.unlocked && <EventLogoUnlocked src={BoostBoltSVG} />}
					</EventLogoBackground>
					<EventShortode>{e.shortcode}</EventShortode>
				</EventButton>,
			)
		})
		buttons.push(
			<Block position="sticky" bottom={0}>
				<EventButton key={events?.length} title="New Event" onClick={() => setShowCreateEventModal(true)}>
					<EventLogoBackground>
						<EventLogoInitials>
							<FontAwesomeIcon icon={faPlus} color="grey" />
						</EventLogoInitials>
					</EventLogoBackground>
				</EventButton>
			</Block>,
		)

		return buttons
	}

	const renderCurrentEventButton = () => {
		const CurrentEventBoostIcon = styled("img", {
			width: "38px",
			margin: "0 20px",
			textAlign: "center",
		})
		const CurrentEventShortcode = styled("p", {
			overflowX: "hidden",
			width: "8rem",
			margin: event.unlocked ? "0" : "0 20px",
			whiteSpace: "nowrap",
			textOverflow: "ellipsis",
			fontWeight: "bold",
			color: "white",
		})
		const CurrentEventBoostedTag = styled("img", {
			height: "1rem",
			marginTop: ".2rem",
		})

		return (
			<CurrentEvent>
				{event.unlocked && <CurrentEventBoostIcon src={BoostBoltSVG} />}
				<Block>
					<CurrentEventShortcode title={event.shortcode}>{event.shortcode}</CurrentEventShortcode>
					{event.unlocked && <CurrentEventBoostedTag src={BoostedTagSVG} />}
				</Block>
				<Button
					aria-labelledby="button-label"
					overrides={{
						BaseButton: {
							style: {
								marginLeft: "auto",
								marginRight: "20px",
								backgroundColor: "rgba(0, 0, 0, 0.2)",
							},
						},
					}}
					onClick={() => setShowEventHistoryModal(true)}
				>
					<span id="button-label" hidden>
						View History
					</span>
					<FontAwesomeIcon icon={faHistory} />
				</Button>
			</CurrentEvent>
		)
	}

	return (
		<>
			<Sidebar>
				<LogoContainer>
					<Link to="/">
						<Logo src={LogoSVG} alt={"JudgeFest logo"} />
					</Link>
				</LogoContainer>
				<EventPickerAndNavContainer>
					<EventPickerWrap>{renderEventButtons()}</EventPickerWrap>
					<NavWrap>
						{renderCurrentEventButton()}
						<SideMenuButton index={index++} icon="chart-pie-alt" label="Event" url="/" exact />
						{event.judge_type === EventType.AudienceParticipation ? (
							<>
								<SideMenuButton index={index++} icon="question" label="Questions" url="/questions" />
								{/* <SideMenuButton index={index++} icon="list" label="Word Blacklist" url="/word-blacklist" /> */}
							</>
						) : (
							<>
								<SideMenuButton index={index++} icon="users" label={event.team_display_name} url="/teams" />
								<SideMenuButton index={index++} icon="gavel" label="Judges" url="/judges" />
								<SideMenuButton index={index++} icon="star" label="Categories" url="/categories" />
								<SideMenuButton index={index++} icon="list" label="Leaderboard" url="/leaderboard" />
							</>
						)}
						{event.has_user_management && <SideMenuButton index={index++} icon="user-hard-hat" label="Users" url="/users" />}
						{!event.unlocked && <SideMenuButton index={index++} label="Upgrade" url="/upgrade" special />}
						{event.unlocked_by && <UnlockedByText key={index++}>Unlocked by: {event.unlocked_by.email}</UnlockedByText>}
					</NavWrap>
				</EventPickerAndNavContainer>
			</Sidebar>

			{/* Create Event Modal */}
			<Modal onClose={() => setShowCreateEventModal(false)} isOpen={showCreateEventModal} animate autoFocus size={SIZE.default} role={ROLE.dialog}>
				<form
					onSubmit={(e) => {
						e.preventDefault()
						onSubmit()
					}}
				>
					<ModalHeader>Create a new event</ModalHeader>
					<ModalBody>
						<p>
							Create your new event below. No spaces are permitted, and it must contain at least one alphanumeric character. Your users will use this shortcode
							to access the event, so make it memorable!
						</p>

						<FormControl label="Event Name">
							<Input
								value={eventCreatePayload.newname}
								onChange={(e) => {
									const target = e.target as HTMLTextAreaElement
									generateNames(target.value)
								}}
								onBlur={(e) => {
									setEventCreatePayload({ ...eventCreatePayload, newname: e.currentTarget.value.toUpperCase() })
								}}
								placeholder="Event Name"
								error={error}
								overrides={{
									Input: {
										style: () => ({
											textTransform: "uppercase",
											"::placeholder": {
												textTransform: "none",
											},
											"::-webkit-input-placeholder": {
												textTransform: "none",
											},
											"::-moz-placeholder": {
												textTransform: "none",
											},
											"::-ms-input-placeholder": {
												textTransform: "none",
											},
										}),
									},
								}}
							/>
						</FormControl>

						<FormControl label="Event Shortcode">
							<Input
								value={eventCreatePayload.newshortcode}
								onChange={async (e) => {
									const target = e.target as HTMLTextAreaElement
									setEventCreatePayload({ ...eventCreatePayload, newshortcode: target.value })
								}}
								onBlur={(e) => setEventCreatePayload({ ...eventCreatePayload, newshortcode: e.currentTarget.value.toUpperCase() })}
								placeholder="Event shortcode"
								error={error}
								overrides={{
									Input: {
										style: ({ $theme }) => ({
											textTransform: "uppercase",
											"::placeholder": {
												textTransform: "none",
											},
											"::-webkit-input-placeholder": {
												textTransform: "none",
											},
											"::-moz-placeholder": {
												textTransform: "none",
											},
											"::-ms-input-placeholder": {
												textTransform: "none",
											},
										}),
									},
								}}
							/>
						</FormControl>
						<FormControl label="Copy Data from Existing Event? (optional)">
							<Select
								options={
									events.map((el) => ({
										id: el.id,
										label: (
											<Spaced>
												{el.archived && <FontAwesomeIcon icon={["fas", "lock-alt"]} />}
												<div>{el.shortcode}</div>
											</Spaced>
										),
									})) || []
								}
								value={eventCreatePayload.copyFromID ? [{ id: eventCreatePayload.copyFromID, label: eventCreatePayload.copyFromID }] : undefined}
								onChange={({ value }) => {
									if (!value || value.length === 0 || !value[0]?.id) setEventCreatePayload({ ...eventCreatePayload, copyFromID: undefined })
									else setEventCreatePayload({ ...eventCreatePayload, copyFromID: value[0].id.toString() })
								}}
								clearable
							/>
						</FormControl>

						{error && <ErrorNotification message={isAPIError(createPayload) ? createPayload.shortMessage : "An error occurred"} />}
					</ModalBody>

					<ModalFooter>
						<ModalButton
							onClick={(e) => {
								e.preventDefault()
								onSubmit()
							}}
							startEnhancer={<FontAwesomeIcon icon={["fas", "save"]} />}
						>
							Create Event
						</ModalButton>
					</ModalFooter>
				</form>
			</Modal>

			{/* Event History Modal */}
			<Modal
				isOpen={showEventHistoryModal}
				onClose={() => {
					setShowEventHistoryModal(false)
				}}
			>
				<ModalHeader>Navigate Launch History</ModalHeader>
				<ModalBody>
					{historicalEvents.length > 0 ? (
						<ul
							style={{
								padding: 0,
							}}
						>
							{historicalEvents.map((h, index) => (
								<ListItem
									key={index}
									artwork={() =>
										h.logo ? (
											<img
												alt={`Event Logo for ${event.shortcode} v${h.iteration_number}`}
												height="40px"
												width="40px"
												src={`data:image/jpeg;base64,${h.logo}`}
											/>
										) : (
											<FontAwesomeIcon icon={faHistory} />
										)
									}
									endEnhancer={() => (
										<Button
											size="compact"
											onClick={() => {
												removeEvent()
												setEvent(h.id)
												setShowEventHistoryModal(false)
											}}
										>
											View
										</Button>
									)}
								>
									<ListItemLabel>
										v{h.iteration_number} {h.is_current && <b>(Latest)</b>}
									</ListItemLabel>
								</ListItem>
							))}
						</ul>
					) : (
						"No event history recorded"
					)}
				</ModalBody>
				<ModalFooter>
					<Block display="flex">
						<Button
							overrides={{
								BaseButton: {
									style: {
										marginRight: "auto",
									},
								},
							}}
							onClick={() => {
								setShowEventHistoryModal(false)
							}}
							kind="secondary"
						>
							Back
						</Button>
					</Block>
				</ModalFooter>
			</Modal>
		</>
	)
}

interface ButtonProps {
	index: number
	label: string
	icon?: IconProp
	url: string
	exact?: boolean
	special?: boolean
}

const SideMenuButton = (props: ButtonProps) => {
	const [css] = useStyletron()
	const { index, label, icon, url, exact, special } = props

	const location = useLocation()
	const [selected, setSelected] = React.useState(false)

	React.useEffect(() => {
		setSelected(exact ? window.location.pathname === url : window.location.pathname.startsWith(url))
	}, [location.pathname, exact, url])

	const linkStyle = css({
		textDecoration: "none",
		color: "white",
	})
	const background = special
		? "linear-gradient(to right, #e284b3, #7a92d8, #44b6b4)"
		: selected
		? "rgba(0, 0, 0, 0.75)"
		: index % 2 === 0
		? "rgba(0, 0, 0, 0.2)"
		: "transparent"
	const buttonStyle = css({
		height: "80px",
		display: "flex",
		flexDirection: "row",
		alignItems: "center",
		background,
		":hover": {
			background: special ? "linear-gradient(to right, #e284b3, #7a92d8, #44b6b4)" : "rgba(0, 0, 0, 0.5)",
		},
		boxShadow: selected ? "inset #00000029 0px 0px 4px 4px" : "unset",
	})
	const iconStyle = css({
		width: "38px",
		marginLeft: "20px",
		marginRight: "20px",
		textAlign: "center",
	})
	const UpgradeIcon = styled("img", {
		width: "2rem",
		margin: "0 20px",
	})
	const labelStyle = css({
		fontSize: "18px",
		fontWeight: selected ? 600 : "normal",
		textDecoration: "none",

		"@media only screen and (max-width: 700px)": {
			display: "none",
		},
	})

	return (
		<Link to={url} className={linkStyle}>
			<div className={buttonStyle}>
				{special ? (
					<UpgradeIcon src={UpgradeIconSVG} />
				) : (
					icon && (
						<div className={iconStyle}>
							<FontAwesomeIcon icon={icon} size={"2x"} />
						</div>
					)
				)}
				<div className={labelStyle}>{label}</div>
			</div>
		</Link>
	)
}
