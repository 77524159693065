import { faBadgeCheck, faEllipsisV, faExclamationCircle, faMask } from "@fortawesome/pro-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { Accordion, Panel } from "baseui/accordion"
import { Block } from "baseui/block"
import { Button, SHAPE } from "baseui/button"
import { Card } from "baseui/card"
import { FormControl } from "baseui/form-control"
import { Input } from "baseui/input"
import { StatefulMenu } from "baseui/menu"
import { StatefulPopover } from "baseui/popover"
import { Tag } from "baseui/tag"
import { toaster } from "baseui/toast"
import { colors } from "baseui/tokens"
import React, { useState } from "react"
import { QueryResponse, useMutation, useQuery } from "react-fetching-library"
import { Controller, useForm } from "react-hook-form"
import { useHistory, useParams } from "react-router-dom"
import { getAccountTypeString } from "../../api/enums"
import { APIError, isAPIError, User as SignInUser } from "../../api/types"
import { Heading1, Heading2 } from "../../components/typography"
import { CommonContainer } from "../../lib/controller"
import { getUser, getUserEvents, GetUserEventsRequest, impersonateUser, updateUser, UpdateUserRequest, User } from "../../lib/superAdmin"
import { EventsTable } from "./events"

interface SuperAdminViewUserPageProps {}

type SuperAdminViewUserPageParams = {
	id: string
}

export const SuperAdminViewUserPage: React.FC<SuperAdminViewUserPageProps> = () => {
	const { id } = useParams<SuperAdminViewUserPageParams>()
	const history = useHistory()
	const { setUser, user: currentUser } = CommonContainer.useContainer()
	const { query: refetchUser, payload: user, loading } = useQuery(getUser(id))
	const { mutate: impersonate, loading: impersonating } = useMutation<SignInUser | APIError>(impersonateUser)
	const [isEditMode, setIsEditMode] = useState(false)

	if (!user || loading) {
		return <Block>Loading</Block>
	}

	const impersonateDaUser = async () => {
		const resp = await impersonate({
			id: user.id,
		})

		if (resp.error) {
			if (isAPIError(resp.payload)) {
				// If error display error toast
				toaster.negative(<>{resp.payload.shortMessage}</>, {})
			}
			return
		}
		setUser(resp.payload as SignInUser)
		history.push("/")
		window.location.reload()
	}

	return (
		<Block height="100%">
			<Block display="flex" justifyContent="space-between" alignItems="center">
				<Heading1>
					<Block display="flex" alignItems="center">
						<Tag variant="solid" closeable={false}>
							{getAccountTypeString(user.account_type)}
						</Tag>
						<Block margin="0 .5rem">{user.email}</Block>
						{user.verified && <FontAwesomeIcon title="This user is verified" icon={faBadgeCheck} />}
					</Block>
				</Heading1>
				{user.id !== currentUser?.id && (
					<Button onClick={() => impersonateDaUser()} startEnhancer={<FontAwesomeIcon icon={faMask} />} isLoading={impersonating}>
						Impersonate User
					</Button>
				)}
			</Block>

			{isEditMode ? (
				<EditUserDetails user={user} setIsEditMode={setIsEditMode} refetchUser={refetchUser} />
			) : (
				<Card
					overrides={{
						Root: {
							style: {
								position: "relative",
							},
						},
					}}
				>
					<StatefulPopover
						content={({ close }) => (
							<StatefulMenu
								onItemSelect={({ item }) => {
									close()
									item.onClick()
								}}
								items={[
									{
										label: "Edit User",
										onClick: () => {
											setIsEditMode(true)
										},
									},
								]}
							/>
						)}
					>
						<Button
							overrides={{
								Root: {
									style: {
										position: "absolute",
										top: "1rem",
										right: "1rem",
									},
								},
							}}
							kind="tertiary"
							shape={SHAPE.circle}
						>
							<FontAwesomeIcon icon={faEllipsisV} />
						</Button>
					</StatefulPopover>
					<Block display="flex" width="100%">
						<Block flex={1}>
							<Heading2>User Privileges</Heading2>
							<Block>Event limit: {user.event_limit}</Block>
							<Block>Open event Limit: {user.open_event_limit}</Block>
							<Block>Open opinion event Limit: {user.open_opinion_event_limit}</Block>
							<Block>Event relaunch Limit: {user.event_iteration_limit}</Block>
							<Block>Credit count: {user.credit_count}</Block>
							<Block marginTop="1rem">
								<Tag variant="solid" kind="warning" closeable={false}>
									<FontAwesomeIcon icon={faExclamationCircle} /> Important
								</Tag>
								Modifying user privileges will automatically set the user's account type to Enterprise
							</Block>
						</Block>

						<Block width="1px" margin="0 2rem" backgroundColor={colors.gray400} />

						<Block flex={1}>
							<Heading2>User Details</Heading2>
							<Block>First name: {user.first_name}</Block>
							<Block>Last name: {user.last_name}</Block>
							<Block>Contact number: {user.contact_number}</Block>
							<Block>Industry: {user.industry || "Unknown"}</Block>
							<Block>Joined on: {new Date(user.created_at).toLocaleDateString()}</Block>
							<Block>
								<Accordion
									overrides={{
										Header: {
											style: {
												paddingLeft: 0,
											},
										},
									}}
								>
									<Panel title="Address details">
										{user.address ? (
											<>
												<Block>Company name: {user.address.company_name || "Unknown"}</Block>
												<Block>Tax number: {user.address.tax_number || "Unknown"}</Block>
												<Block>Line 1: {user.address.line1 || "Unknown"}</Block>
												<Block>Line 2: {user.address.line2 || "Unknown"}</Block>
												<Block>City: {user.address.city || "Unknown"}</Block>
												<Block>State: {user.address.state || "Unknown"}</Block>
												<Block>Country: {user.address.country || "Unknown"}</Block>
												<Block>Post/Zip code: {user.address.post_or_zip || "Unknown"}</Block>
											</>
										) : (
											<Block>No address recorded</Block>
										)}
									</Panel>
								</Accordion>
							</Block>
						</Block>
					</Block>
				</Card>
			)}
			<Block height="500px">
				<Heading2
					style={{
						marginBottom: 0,
					}}
				>
					Associated Events
				</Heading2>
				<EventsTable
					defaultOptions={
						{
							limit: 20,
							offset: 0,
							id: user.id,
						} as GetUserEventsRequest
					}
					query={getUserEvents}
					userId={user.id}
				/>
			</Block>
		</Block>
	)
}

interface EditUserDetailsProps {
	user: User
	setIsEditMode: React.Dispatch<React.SetStateAction<boolean>>
	refetchUser: () => Promise<QueryResponse<User>>
}

type UpdateUserForm = Omit<UpdateUserRequest, "id">

const EditUserDetails: React.FC<EditUserDetailsProps> = ({ user, setIsEditMode, refetchUser }) => {
	const { mutate: update, loading } = useMutation(updateUser)
	const { control, handleSubmit, trigger } = useForm<UpdateUserForm>({
		defaultValues: {
			eventLimit: user.event_limit,
			openEventLimit: user.open_event_limit,
			openOpinionEventLimit: user.open_opinion_event_limit,
			eventIterationLimit: user.event_iteration_limit,
			creditCount: user.credit_count,
			contactNumber: user.contact_number,
			firstName: user.first_name,
			lastName: user.last_name,
			industry: user.industry || "",
			companyName: user.address?.company_name || "",
			taxNumber: user.address?.tax_number || "",
			line1: user.address?.line1 || "",
			line2: user.address?.line2 || "",
			city: user.address?.city || "",
			state: user.address?.state || "",
			country: user.address?.country || "",
			postOrZip: user.address?.post_or_zip || "",
		},
	})

	const onSubmit = handleSubmit(async (input) => {
		// Trigger form validation, if not valid then return
		if (!trigger()) return

		// Update the event
		const resp = await update({
			...input,
			id: user.id,
		})

		if (resp.error) {
			// If error display error toast
			toaster.negative(<>{resp.payload.shortMessage}</>, {})
			return
		}

		// Refresh updated event
		await refetchUser()
		// Display success toast
		toaster.positive(<>{resp.payload?.response?.message}</>, {})
		setIsEditMode(false)
	})

	return (
		<Card
			overrides={{
				Root: {
					style: {
						position: "relative",
					},
				},
			}}
		>
			<form onSubmit={onSubmit}>
				<Block display="flex" position="absolute" top="1rem" right="1rem">
					<Button kind="secondary" onClick={() => setIsEditMode(false)} disabled={loading}>
						Cancel
					</Button>
					<Button
						overrides={{
							Root: {
								style: {
									marginLeft: "1rem",
								},
							},
						}}
						type="submit"
						isLoading={loading}
					>
						Save
					</Button>
				</Block>
				<Block display="flex" width="100%">
					<Block flex={1}>
						<Heading2>User Privileges</Heading2>
						<Block maxWidth="500px">
							<FormControl label="Event limit">
								<Controller
									name="eventLimit"
									control={control}
									render={({ value, onChange }) => {
										return (
											<>
												<Input
													value={value || ""}
													onChange={(e) => onChange(e.currentTarget.valueAsNumber || undefined)}
													type="number"
													size="compact"
													disabled={loading}
												/>
											</>
										)
									}}
								/>
							</FormControl>
							<FormControl label="Open event limit">
								<Controller
									name="openEventLimit"
									control={control}
									render={({ value, onChange }) => {
										return (
											<>
												<Input
													value={value || ""}
													onChange={(e) => onChange(e.currentTarget.valueAsNumber || undefined)}
													type="number"
													size="compact"
													disabled={loading}
												/>
											</>
										)
									}}
								/>
							</FormControl>
							<FormControl label="Open opinion event limit">
								<Controller
									name="openOpinionEventLimit"
									control={control}
									render={({ value, onChange }) => {
										return (
											<>
												<Input
													value={value || ""}
													onChange={(e) => onChange(e.currentTarget.valueAsNumber || undefined)}
													type="number"
													size="compact"
													disabled={loading}
												/>
											</>
										)
									}}
								/>
							</FormControl>
							<FormControl label="Event relaunch limit">
								<Controller
									name="eventIterationLimit"
									control={control}
									render={({ value, onChange }) => {
										return (
											<>
												<Input
													value={value || ""}
													onChange={(e) => onChange(e.currentTarget.valueAsNumber || undefined)}
													type="number"
													size="compact"
													disabled={loading}
												/>
											</>
										)
									}}
								/>
							</FormControl>
							<FormControl label="Credit count">
								<Controller
									name="creditCount"
									control={control}
									render={({ value, onChange }) => {
										return (
											<>
												<Input
													value={value || ""}
													onChange={(e) => onChange(e.currentTarget.valueAsNumber || undefined)}
													type="number"
													size="compact"
													disabled={loading}
												/>
											</>
										)
									}}
								/>
							</FormControl>
						</Block>
					</Block>

					<Block width="1px" margin="0 2rem" backgroundColor={colors.gray400} />

					<Block flex={1}>
						<Heading2>User Details</Heading2>
						<Block maxWidth="500px">
							<FormControl label="First name">
								<Controller
									name="firstName"
									control={control}
									render={({ value, onChange }) => {
										return (
											<>
												<Input value={value} onChange={(e) => onChange(e.currentTarget.value)} type="text" size="compact" disabled={loading} />
											</>
										)
									}}
								/>
							</FormControl>
							<FormControl label="Last name">
								<Controller
									name="lastName"
									control={control}
									render={({ value, onChange }) => {
										return (
											<>
												<Input value={value} onChange={(e) => onChange(e.currentTarget.value)} type="text" size="compact" disabled={loading} />
											</>
										)
									}}
								/>
							</FormControl>
							<FormControl label="Contact number">
								<Controller
									name="contactNumber"
									control={control}
									render={({ value, onChange }) => {
										return (
											<>
												<Input value={value} onChange={(e) => onChange(e.currentTarget.value)} type="text" size="compact" disabled={loading} />
											</>
										)
									}}
								/>
							</FormControl>
							<FormControl label="Industry">
								<Controller
									name="industry"
									control={control}
									render={({ value, onChange }) => {
										return (
											<>
												<Input value={value || ""} onChange={(e) => onChange(e.currentTarget.value)} type="text" size="compact" disabled={loading} />
											</>
										)
									}}
								/>
							</FormControl>
						</Block>
						<Accordion
							overrides={{
								Header: {
									style: {
										paddingLeft: 0,
									},
								},
							}}
						>
							<Panel title="Address details">
								<Block maxWidth="500px">
									<FormControl label="Company name">
										<Controller
											name="companyName"
											control={control}
											render={({ value, onChange }) => {
												return (
													<>
														<Input value={value} onChange={(e) => onChange(e.currentTarget.value)} type="text" size="compact" disabled={loading} />
													</>
												)
											}}
										/>
									</FormControl>
									<FormControl label="Tax number">
										<Controller
											name="taxNumber"
											control={control}
											render={({ value, onChange }) => {
												return (
													<>
														<Input value={value} onChange={(e) => onChange(e.currentTarget.value)} type="text" size="compact" disabled={loading} />
													</>
												)
											}}
										/>
									</FormControl>
									<FormControl label="Line 1">
										<Controller
											name="line1"
											control={control}
											render={({ value, onChange }) => {
												return (
													<>
														<Input value={value} onChange={(e) => onChange(e.currentTarget.value)} type="text" size="compact" disabled={loading} />
													</>
												)
											}}
										/>
									</FormControl>
									<FormControl label="Line 2">
										<Controller
											name="line2"
											control={control}
											render={({ value, onChange }) => {
												return (
													<>
														<Input value={value} onChange={(e) => onChange(e.currentTarget.value)} type="text" size="compact" disabled={loading} />
													</>
												)
											}}
										/>
									</FormControl>
									<FormControl label="City">
										<Controller
											name="city"
											control={control}
											render={({ value, onChange }) => {
												return (
													<>
														<Input value={value} onChange={(e) => onChange(e.currentTarget.value)} type="text" size="compact" disabled={loading} />
													</>
												)
											}}
										/>
									</FormControl>
									<FormControl label="State">
										<Controller
											name="state"
											control={control}
											render={({ value, onChange }) => {
												return (
													<>
														<Input value={value} onChange={(e) => onChange(e.currentTarget.value)} type="text" size="compact" disabled={loading} />
													</>
												)
											}}
										/>
									</FormControl>
									<FormControl label="Country">
										<Controller
											name="country"
											control={control}
											render={({ value, onChange }) => {
												return (
													<>
														<Input value={value} onChange={(e) => onChange(e.currentTarget.value)} type="text" size="compact" disabled={loading} />
													</>
												)
											}}
										/>
									</FormControl>
									<FormControl label="Post/Zip code">
										<Controller
											name="postOrZip"
											control={control}
											render={({ value, onChange }) => {
												return (
													<>
														<Input value={value} onChange={(e) => onChange(e.currentTarget.value)} type="text" size="compact" disabled={loading} />
													</>
												)
											}}
										/>
									</FormControl>
								</Block>
							</Panel>
						</Accordion>
					</Block>
				</Block>
			</form>
		</Card>
	)
}
