import * as React from "react"
import { Notification } from "baseui/notification"
import { FormControl } from "baseui/form-control"
import { useForm } from "react-hook-form"
import { CommonContainer } from "../lib/controller"
import { ErrorNotification } from "./common"
import { Button } from "baseui/button"
import { Input } from "baseui/input"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"

interface FormData {
	password: string
	newPassword: string
}

export const ChangePasswordForm = () => {
	const { register, handleSubmit, trigger, errors, reset } = useForm<FormData>()

	const { useChangePassword } = CommonContainer.useContainer()
	const { changePassword, loading, success, error } = useChangePassword()

	const onSubmit = handleSubmit((input) => {
		if (!trigger()) return
		changePassword(input.password, input.newPassword)
	})

	// Clear fields on change success
	React.useEffect(() => {
		if (success) reset()
	}, [success, reset])

	return (
		<form onSubmit={onSubmit}>
			<div>
				{success && <Notification kind="positive">Your password has been updated.</Notification>}

				{error && <ErrorNotification message={error} />}

				<FormControl label="Old Password" error={errors.password && errors.password.message}>
					<Input
						name="password"
						error={errors.password !== undefined}
						inputRef={register({ required: { value: true, message: "Please enter your current password." } })}
						type="password"
						placeholder="Enter your current password"
						disabled={loading}
					/>
				</FormControl>
				<FormControl label="New Password" error={errors.newPassword && errors.newPassword.message}>
					<Input
						name="newPassword"
						error={errors.newPassword !== undefined}
						inputRef={register({ required: { value: true, message: "Please enter a new password." } })}
						type="password"
						placeholder="Enter a new password"
						disabled={loading}
					/>
				</FormControl>
			</div>

			<Button
				onClick={onSubmit}
				isLoading={loading}
				startEnhancer={<FontAwesomeIcon icon={["fas", "save"]} />}
				overrides={{
					Root: {
						style: {
							display: "flex",
							marginLeft: "auto",
						},
					},
				}}
			>
				Save
			</Button>
		</form>
	)
}
