import { H3 } from "baseui/typography"
import * as React from "react"
import { useStyletron } from "styletron-react"
import { ObjectType } from "../../api/enums"
import { ItemList } from "../../components/itemList"
import { CommonContainer } from "../../lib/controller"

export const QuestionList = () => {
	const { event } = CommonContainer.useContainer()
	const [css] = useStyletron()
	const headerStyle = css({
		display: "none",
		"@media only screen and (max-width: 700px)": {
			display: "flex",
		},
	})

	const questionTypeString = (type: number): string => {
		switch (type) {
			case 0:
				return "Multiple Choice"
			case 1:
				return "Slider"
			case 2:
				return "Text"
			default:
				return "Multiple Choice"
		}
	}

	return (
		<>
			<div className={headerStyle}>
				<H3 $style={{ marginBottom: "5px", marginTop: 0 }}>Questions</H3>
			</div>
			<ItemList
				hideActions={!event?.is_current}
				type={ObjectType.Question}
				columns={[
					{
						name: "Question",
						resolver: (value) => value.name,
					},
					{
						name: "Type",
						resolver: (value) => questionTypeString(value.type),
					},
				]}
			/>
		</>
	)
}
