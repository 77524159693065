import * as React from "react"
import { StyledSpinnerNext as Spinner } from "baseui/spinner"
import { useStyletron } from "baseui"
import { H6 } from "baseui/typography"

export const Loading = () => {
	const [css] = useStyletron()
	const containerStyle: string = css({
		display: "flex",
		justifyContent: "center",
		alignItems: "center",
		height: "100vh",
	})
	const contentStyle: string = css({
		display: "flex",
		flexDirection: "column",
		justifyContent: "center",
		alignItems: "center",
	})

	return (
		<div className={containerStyle}>
			<div className={contentStyle}>
				<Spinner />
				<H6>Loading...</H6>
			</div>
		</div>
	)
}
