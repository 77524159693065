import { faEllipsisV, faExclamationCircle, faLockAlt, faUser } from "@fortawesome/pro-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { Block } from "baseui/block"
import { Button, SHAPE } from "baseui/button"
import { Card } from "baseui/card"
import { FormControl } from "baseui/form-control"
import { Input } from "baseui/input"
import { StatefulMenu } from "baseui/menu"
import { Modal, ModalBody, ModalFooter, ModalHeader } from "baseui/modal"
import { StatefulPopover } from "baseui/popover"
import { OptionsT, Select } from "baseui/select"
import { Tag } from "baseui/tag"
import { Textarea } from "baseui/textarea"
import { toaster } from "baseui/toast"
import { colors } from "baseui/tokens"
import React, { useState } from "react"
import { QueryResponse, useMutation, useQuery } from "react-fetching-library"
import { Controller, useForm } from "react-hook-form"
import { useHistory, useParams } from "react-router-dom"
import { ScoreType, ScoreTypeNames } from "../../api/enums"
import { Spread } from "../../components/spread"
import { Heading1, Heading2 } from "../../components/typography"
import { Event } from "../../lib/events"
import { getEvent, unlockEvent, updateEvent, UpdateEventRequest } from "../../lib/superAdmin"

interface SuperAdminViewEventPageProps {}

type SuperAdminViewEventPageParams = {
	id: string
}

export const SuperAdminViewEventPage: React.FC<SuperAdminViewEventPageProps> = () => {
	const { id } = useParams<SuperAdminViewEventPageParams>()
	const history = useHistory()
	const { query: refetchEvent, payload: event, loading } = useQuery(getEvent(id))
	const { mutate: unlock, loading: unlocking } = useMutation(unlockEvent)
	const [isEditMode, setIsEditMode] = useState(false)
	const [showUnlockModal, setShowUnlockModal] = React.useState(false)

	if (!event || loading) {
		return <Block>Loading</Block>
	}

	const onUnlock = async () => {
		// Update the event
		const resp = await unlock({
			id: event.id,
		})

		if (resp.error) {
			// If error display error toast
			toaster.negative(<>{resp.payload.shortMessage}</>, {})
			return
		}

		// Refresh updated event
		await refetchEvent()
		// Display success toast
		toaster.positive(<>{resp.payload?.response?.message}</>, {})
	}

	return (
		<>
			<Block height="100%">
				<Block display="flex" justifyContent="space-between" alignItems="center">
					<Heading1>
						<Block display="flex" alignItems="center">
							{event.is_custom && (
								<Tag variant="solid" closeable={false}>
									Custom
								</Tag>
							)}
							<Block margin="0 .5rem">{event.shortcode}</Block>
							{event.archived && <FontAwesomeIcon title="This event is closed" icon={faLockAlt} />}
						</Block>
					</Heading1>
					{event.owner_user && (
						<Button onClick={() => history.push(`/users/${event.owner_user!.id}`)} startEnhancer={<FontAwesomeIcon icon={faUser} />} kind="minimal">
							{event.owner_user.email}
						</Button>
					)}
				</Block>

				{isEditMode ? (
					<EditEventDetails event={event} setIsEditMode={setIsEditMode} refetchEvent={refetchEvent} />
				) : (
					<Card
						overrides={{
							Root: {
								style: {
									position: "relative",
								},
							},
						}}
					>
						<StatefulPopover
							content={({ close }) => (
								<StatefulMenu
									onItemSelect={({ item }) => {
										close()
										item.onClick()
									}}
									items={[
										{
											label: "Edit Event",
											onClick: () => {
												setIsEditMode(true)
											},
										},
										{
											label: "Unlock Event",
											onClick: () => {
												setShowUnlockModal(true)
											},
										},
									]}
								/>
							)}
						>
							<Button
								overrides={{
									Root: {
										style: {
											position: "absolute",
											top: "1rem",
											right: "1rem",
										},
									},
								}}
								kind="tertiary"
								shape={SHAPE.circle}
							>
								<FontAwesomeIcon icon={faEllipsisV} />
							</Button>
						</StatefulPopover>
						<Block display="flex" width="100%">
							<Block flex={1}>
								<Heading2>Event Limits</Heading2>
								<Block>Unlocked: {event.unlocked ? "Yes" : "No"}</Block>
								<Block>Judge limit: {event.judge_limit}</Block>
								<Block>Team limit: {event.team_limit}</Block>
								<Block>Category limit: {event.category_limit}</Block>
								<Block>Opinion category limit: {event.opinion_category_limit}</Block>
								<Block>Audience participation limit: {event.audience_participation_limit}</Block>
								<Block>Public leaderboard limit: {event.public_leaderboard_limit}</Block>
								<Block marginTop="1rem">
									<Tag variant="solid" kind="warning" closeable={false}>
										<FontAwesomeIcon icon={faExclamationCircle} /> Important
									</Tag>
									Modifying event limits will mark the event as custom
								</Block>
							</Block>

							<Block width="1px" margin="0 2rem" backgroundColor={colors.gray400} />

							<Block flex={1}>
								<Heading2>Event Details</Heading2>
								<Block>Name: {event.name}</Block>
								{event.word_blacklist !== "" && <Block>Word blacklist: {event.word_blacklist}</Block>}
								<Block>Team display name: {event.team_display_name}</Block>
								<Block>Score type: {ScoreTypeNames[event.score_type]}</Block>
								<Block>Combine public leaderboard scores: {event.public_leaderboard_combine ? "Yes" : "No"}</Block>
								<Block>Official judge scoring weight: {event.official_weight}</Block>
								<Block>Guest judge scoring weight: {event.guest_weight}</Block>
								<Block>Comment section caption: {event.comment_section_caption}</Block>
								<Block>Created on: {new Date(event.created_at).toLocaleDateString()}</Block>
							</Block>
						</Block>
					</Card>
				)}
			</Block>
			{/* Unlock Event Modal */}
			<Modal
				isOpen={showUnlockModal}
				onClose={() => {
					if (unlocking) return
					setShowUnlockModal(false)
				}}
			>
				<ModalHeader>Unlock this event?</ModalHeader>
				<ModalBody>
					<p>
						Boosting this event will unlock all of its features, including unlimited teams and event participants, ad removal, and CSV import/export support for
						teams, judges, and categories on the admin web app.
					</p>
					<p>An unlocked event can also be relaunched an unlimitted amount of times.</p>
					<p>This action cannot be undone.</p>
				</ModalBody>
				<ModalFooter>
					<Spread>
						<Button
							onClick={() => {
								if (unlocking) return
								setShowUnlockModal(false)
							}}
							kind="secondary"
							disabled={unlocking}
						>
							Cancel
						</Button>
						<Button
							onClick={async () => {
								if (unlocking) return
								await onUnlock()
								setShowUnlockModal(false)
							}}
							isLoading={unlocking}
							overrides={{
								BaseButton: {
									style: {
										backgroundColor: "#276ef1",
									},
								},
							}}
						>
							Unlock Event
						</Button>
					</Spread>
				</ModalFooter>
			</Modal>
		</>
	)
}

interface EditEventDetailsProps {
	event: Event
	setIsEditMode: React.Dispatch<React.SetStateAction<boolean>>
	refetchEvent: () => Promise<QueryResponse<Event>>
}

type UpdateEventForm = Omit<UpdateEventRequest, "id">

const EditEventDetails: React.FC<EditEventDetailsProps> = ({ event, setIsEditMode, refetchEvent }) => {
	const { mutate: update, loading } = useMutation(updateEvent)
	const { control, handleSubmit, trigger } = useForm<UpdateEventForm>({
		defaultValues: {
			judgeLimit: event.judge_limit,
			teamLimit: event.team_limit,
			categoryLimit: event.category_limit,
			opinionCategoryLimit: event.opinion_category_limit,
			audienceParticipationLimit: event.audience_participation_limit,
			name: event.name,
			teamDisplayName: event.team_display_name,
			scoreType: event.score_type,
			publicLeaderboardCombine: event.public_leaderboard_combine,
			officialWeight: event.official_weight,
			guestWeight: event.guest_weight,
			commentSectionCaption: event.comment_section_caption,
		},
	})

	const onSubmit = handleSubmit(async (input) => {
		// Trigger form validation, if not valid then return
		if (!trigger()) return

		// Update the event
		const resp = await update({
			...input,
			id: event.id,
		})

		if (resp.error) {
			// If error display error toast
			toaster.negative(<>{resp.payload.shortMessage}</>, {})
			return
		}

		// Refresh updated event
		await refetchEvent()
		// Display success toast
		toaster.positive(<>{resp.payload?.response?.message}</>, {})
		setIsEditMode(false)
	})

	return (
		<Card
			overrides={{
				Root: {
					style: {
						position: "relative",
					},
				},
			}}
		>
			<form onSubmit={onSubmit}>
				<Block display="flex" position="absolute" top="1rem" right="1rem">
					<Button kind="secondary" onClick={() => setIsEditMode(false)} disabled={loading}>
						Cancel
					</Button>
					<Button
						overrides={{
							Root: {
								style: {
									marginLeft: "1rem",
								},
							},
						}}
						type="submit"
						isLoading={loading}
					>
						Save
					</Button>
				</Block>
				<Block display="flex" width="100%">
					<Block flex={1}>
						<Heading2>Event Limits</Heading2>
						<Block maxWidth="500px">
							<FormControl label="Judge limit">
								<Controller
									name="judgeLimit"
									control={control}
									render={({ value, onChange }) => {
										return (
											<>
												<Input
													value={value || ""}
													onChange={(e) => onChange(e.currentTarget.valueAsNumber || undefined)}
													type="number"
													size="compact"
													disabled={loading}
												/>
											</>
										)
									}}
								/>
							</FormControl>
							<FormControl label="Team limit">
								<Controller
									name="teamLimit"
									control={control}
									render={({ value, onChange }) => {
										return (
											<>
												<Input
													value={value || ""}
													onChange={(e) => onChange(e.currentTarget.valueAsNumber || undefined)}
													type="number"
													size="compact"
													disabled={loading}
												/>
											</>
										)
									}}
								/>
							</FormControl>
							<FormControl label="Category limit">
								<Controller
									name="categoryLimit"
									control={control}
									render={({ value, onChange }) => {
										return (
											<>
												<Input
													value={value || ""}
													onChange={(e) => onChange(e.currentTarget.valueAsNumber || undefined)}
													type="number"
													size="compact"
													disabled={loading}
												/>
											</>
										)
									}}
								/>
							</FormControl>
							<FormControl label="Opinion category limit">
								<Controller
									name="opinionCategoryLimit"
									control={control}
									render={({ value, onChange }) => {
										return (
											<>
												<Input
													value={value || ""}
													onChange={(e) => onChange(e.currentTarget.valueAsNumber || undefined)}
													type="number"
													size="compact"
													disabled={loading}
												/>
											</>
										)
									}}
								/>
							</FormControl>
							<FormControl label="Audience participation limit">
								<Controller
									name="audienceParticipationLimit"
									control={control}
									render={({ value, onChange }) => {
										return (
											<>
												<Input
													value={value || ""}
													onChange={(e) => onChange(e.currentTarget.valueAsNumber || undefined)}
													type="number"
													size="compact"
													disabled={loading}
												/>
											</>
										)
									}}
								/>
							</FormControl>
						</Block>
					</Block>

					<Block width="1px" margin="0 2rem" backgroundColor={colors.gray400} />

					<Block flex={1}>
						<Heading2>Event Details</Heading2>
						<Block maxWidth="500px">
							<FormControl label="Name">
								<Controller
									name="name"
									control={control}
									render={({ value, onChange }) => {
										return (
											<>
												<Input value={value} onChange={(e) => onChange(e.currentTarget.value)} type="text" size="compact" disabled={loading} />
											</>
										)
									}}
								/>
							</FormControl>
							<FormControl label="Team display name">
								<Controller
									name="teamDisplayName"
									control={control}
									render={({ value, onChange }) => {
										return (
											<>
												<Input value={value} onChange={(e) => onChange(e.currentTarget.value)} type="text" size="compact" disabled={loading} />
											</>
										)
									}}
								/>
							</FormControl>
							<FormControl label="Score type">
								<Controller
									name="scoreType"
									control={control}
									render={({ onChange, value }) => {
										const options = ScoreTypeNames.map((name, index) => ({ id: index as ScoreType, label: name }))
										const v = options.filter((o) => o.id === value)

										return (
											<Select
												size="compact"
												options={options}
												onChange={({ option }) => {
													onChange(option?.id)
												}}
												value={v}
												clearable={false}
												searchable={false}
												disabled={loading}
											/>
										)
									}}
								/>
							</FormControl>
							<FormControl label="Combine public leaderboard scores">
								<Controller
									name="publicLeaderboardCombine"
									control={control}
									render={({ onChange, value }) => {
										const options: OptionsT = [
											{
												id: "Yes",
												value: true,
											},
											{
												id: "No",
												value: false,
											},
										]
										const v = options.filter((o) => o.value === value)

										return (
											<Select
												size="compact"
												options={options}
												onChange={({ option }) => {
													onChange(option?.value)
												}}
												value={v}
												labelKey="id"
												valueKey="value"
												clearable={false}
												searchable={false}
												disabled={loading}
											/>
										)
									}}
								/>
							</FormControl>
							<FormControl label="Official judge scoring weight">
								<Controller
									name="officialWeight"
									control={control}
									render={({ value, onChange }) => {
										return (
											<>
												<Input
													value={value || ""}
													onChange={(e) => onChange(e.currentTarget.valueAsNumber || undefined)}
													type="number"
													size="compact"
													disabled={loading}
												/>
											</>
										)
									}}
								/>
							</FormControl>
							<FormControl label="Guest judge scoring weight">
								<Controller
									name="guestWeight"
									control={control}
									render={({ value, onChange }) => {
										return (
											<>
												<Input
													value={value || ""}
													onChange={(e) => onChange(e.currentTarget.valueAsNumber || undefined)}
													type="number"
													size="compact"
													disabled={loading}
												/>
											</>
										)
									}}
								/>
							</FormControl>
							<FormControl label="Comment section caption">
								<Controller
									name="commentSectionCaption"
									control={control}
									render={({ value, onChange }) => {
										return (
											<>
												<Textarea value={value} onChange={(e) => onChange(e.currentTarget.value)} type="text" size="compact" disabled={loading} />
											</>
										)
									}}
								/>
							</FormControl>
						</Block>
					</Block>
				</Block>
			</form>
		</Card>
	)
}
