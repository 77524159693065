import { Action } from "react-fetching-library"
import { APIError } from "../api/types"

export interface Team {
	id: string
	event_id: string
	name: string
	product_name: string
	description: string
	website_url: string
	avatar: string
	position: number
	archived: boolean
}

export interface TeamUpdateRequest {
	eventID?: string
	teamID?: string
	name?: string
	productName?: string
	description?: string
	websiteUrl?: string
	archived?: boolean
}

interface ReorderRequest {
	eventID: string
	ids: string[]
}

export const createTeam = (values: TeamUpdateRequest): Action<Team | APIError> => {
	return {
		method: "POST",
		endpoint: `/admin/teams/add`,
		credentials: "include",
		body: { params: values },
		responseType: "json",
	}
}

export const listTeams = (eventID?: string): Action<Team[] | APIError> => {
	return {
		method: "GET",
		credentials: "include",
		endpoint: `/admin/teams/list?event_id=${eventID}`,
		responseType: "json",
	}
}

export const getTeam = (event_id?: string, id?: string): Action<Team[] | APIError> => {
	return {
		method: "GET",
		credentials: "include",
		endpoint: `/admin/teams/list?event_id=${event_id}&team_id=${id} `,
		responseType: "json",
	}
}

export const exportTeams = (eventID?: string): Action<Blob | APIError> => {
	return {
		method: "GET",
		credentials: "include",
		endpoint: `/admin/teams/export?event_id=${eventID}`,
		responseType: "blob",
	}
}

export const importTeams = (values: { eventID: string; file: Blob }): Action<APIError> => {
	const formData = new FormData()
	formData.set("file", values.file)
	return {
		method: "POST",
		credentials: "include",
		endpoint: `/admin/teams/import?event_id=${values.eventID}`,
		body: formData,
		responseType: "json",
	}
}

export const updateTeam = (values: TeamUpdateRequest): Action<Team | APIError> => {
	return {
		method: "POST",
		endpoint: `/admin/teams/update`,
		credentials: "include",
		body: { params: values },
		responseType: "json",
	}
}

export const deleteTeam = (id: string): Action<any | APIError> => {
	return {
		method: "DELETE",
		endpoint: `/admin/teams/delete?team_id=${id}`,
		credentials: "include",
		responseType: "json",
	}
}

export const reorderTeams = (values: ReorderRequest): Action<APIError> => {
	return {
		method: "POST",
		endpoint: `/admin/teams/reorder`,
		credentials: "include",
		body: { params: values },
		responseType: "json",
	}
}
