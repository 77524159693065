import * as React from "react"
import { Button } from "baseui/button"
import { FormControl } from "baseui/form-control"
import { Input } from "baseui/input"
import { Modal, ModalBody, ModalButton, ModalFooter, ModalHeader } from "baseui/modal"
import { useMutation } from "react-fetching-library"
import { APIError, isAPIError } from "../api/types"
import { VerifyEmail, ResendVerifyEmail } from "../lib/auth"
import { CommonContainer } from "../lib/controller"
import { ErrorNotification } from "./common"

export const VerifyEmailNotification = () => {
	const { user, setUser } = CommonContainer.useContainer()
	const [showVerifyDialog, setShowVerifyDialog] = React.useState(false)
	const { mutate: resendVerifyEmail, loading: resending, payload: resendPayload, error: resendError } = useMutation<APIError>(ResendVerifyEmail)
	const { mutate: verifyEmail, loading: verifying, payload: verifyPayload, error: verifyError } = useMutation(VerifyEmail)

	const [token, setToken] = React.useState("")
	const [error, setError] = React.useState(false)

	React.useEffect(() => {
		if (!user || verifyError || !verifyPayload) return

		setUser({ ...user, verified: true })
	}, [verifyPayload, verifyError, user, setUser])

	if (!user || user.verified) return null

	return (
		<>
			<Button
				onClick={async () => {
					const resp = await resendVerifyEmail(user?.email)
					if (resp.error) return
					setShowVerifyDialog(true)
				}}
				overrides={{
					BaseButton: {
						style: {
							backgroundColor: "#4CAF50",
							color: "white",
							width: "40%",
							clipPath: "polygon(0 0, 100% 0, 90% 100%, 10% 100%)",

							lineHeight: "14px",
							position: "absolute",
							top: "0",
							left: "25%",
							paddingTop: "5px",
							paddingBottom: "5px",
							paddingLeft: "40px",
							paddingRight: "40px",

							fontSize: "0.9rem",
							"@media only screen and (max-width: 560px)": {
								fontSize: "0.75rem",
							},

							":hover": { backgroundColor: "#5fc363" },
							":focus": { backgroundColor: "#6fd473" },
						},
					},
				}}
			>
				{!resending && !resendPayload && "Your email address is not verified. Click here to re-send verification email."}
				{resending && "Sending..."}
				{resendPayload && (resendError ? resendPayload.shortMessage : "Verification email sent")}
			</Button>

			{/* Verify Dialog */}
			<Modal isOpen={showVerifyDialog} onClose={() => setShowVerifyDialog(false)} closeable>
				<ModalHeader>Verify Email</ModalHeader>
				<ModalBody>
					{(!verifyPayload || verifyError) && (
						<>
							<p>Enter Verification Code</p>
							<p style={{ color: "grey" }}>{`(An email has been sent to ${user.email})`}</p>
							<FormControl error={error ? "Please enter a valid code" : ""}>
								<Input
									value={token}
									onChange={(e) => {
										const target = e.target as HTMLTextAreaElement
										setToken(target.value)
									}}
									onBlur={(e) => setToken(e.currentTarget.value.toUpperCase())}
									placeholder="Token"
									error={error}
								/>
							</FormControl>
						</>
					)}
					{verifyError && isAPIError(verifyPayload) && <ErrorNotification message={verifyPayload.shortMessage} />}
				</ModalBody>

				<ModalFooter>
					{(!verifyPayload || verifyError) && (
						<ModalButton
							isLoading={verifying}
							onClick={async () => {
								if (token.length < 6) {
									setError(true)
									return
								}
								setError(false)
								verifyEmail({ email: user.email, token })
							}}
						>
							Verify
						</ModalButton>
					)}
				</ModalFooter>
			</Modal>
		</>
	)
}
