import * as React from "react"
import { Button } from "baseui/button"
import { FormControl } from "baseui/form-control"
import { Input } from "baseui/input"
import { Modal, ModalHeader, ModalBody } from "baseui/modal"
import { useForm } from "react-hook-form"
import { useMutation } from "react-fetching-library"
import { ResetPassword, SendForgotPasswordEmail } from "../lib/auth"
import { isAPIError } from "../api/types"

export const ForgotPasswordModal = (props: { isOpen: boolean; onClose: (email?: string) => void }) => {
	const { mutate, error: mutateError, payload, loading } = useMutation(SendForgotPasswordEmail)
	const [error, setError] = React.useState<string>()
	const { register, handleSubmit, errors } = useForm<{ email: string }>()

	const onSubmit = handleSubmit(async ({ email }) => {
		const resp = await mutate(email)
		if (!resp.error) props.onClose(email)
	})

	React.useEffect(() => {
		if (mutateError) setError(isAPIError(payload) ? payload.shortMessage : "An error occurred")
		else if (errors.email) setError(errors.email.message)
		else setError(undefined)
	}, [mutateError, payload, errors.email])

	return (
		<Modal
			isOpen={props.isOpen}
			onClose={(e) => {
				if (e.closeSource === "backdrop") {
					return
				}
				props.onClose()
			}}
		>
			<ModalHeader>Forgot Password</ModalHeader>
			<ModalBody>
				<form onSubmit={onSubmit}>
					<FormControl error={error}>
						<Input
							type="email"
							name="email"
							inputRef={register({
								required: "Required",
								pattern: {
									value: /.+@.+\..+/,
									message: "Invalid email address.",
								},
							})}
							disabled={loading}
							error={errors.email !== undefined}
							placeholder="Email"
						/>
					</FormControl>

					<Button isLoading={loading}>Send Password Reset Email</Button>
				</form>
			</ModalBody>
		</Modal>
	)
}

export const ResetPasswordModal = (props: { token: string; isOpen: boolean; onClose: () => void }) => {
	const { mutate, error: mutateError, payload, loading } = useMutation(ResetPassword)
	const [changeSuccess, setChangeSuccess] = React.useState<boolean>()
	const [error, setError] = React.useState<string>()
	const { register, handleSubmit, errors } = useForm<{ email: string; password: string }>()

	const onSubmit = handleSubmit(({ email, password }) => {
		setChangeSuccess(false)

		mutate({ email, token: props.token, newPassword: password })
	})

	React.useEffect(() => {
		if (mutateError) setError(isAPIError(payload) ? payload.shortMessage : "An error occurred")
		else if (errors.email) setError(errors.email.message)
		else setError(undefined)

		if (!mutateError && !!payload) setChangeSuccess(true)
	}, [mutateError, payload, errors.email])

	return (
		<Modal onClose={() => props.onClose()} isOpen={props.isOpen}>
			<ModalHeader>Reset Password</ModalHeader>
			<ModalBody>
				{changeSuccess === true && <div>Password Reset Successfully!</div>}

				{!changeSuccess && (
					<form onSubmit={onSubmit}>
						<FormControl error={error}>
							<Input
								type="email"
								name="email"
								inputRef={register({
									required: "Required",
									pattern: {
										value: /.+@.+\..+/,
										message: "Invalid email address.",
									},
								})}
								disabled={loading}
								error={errors.email !== undefined}
								placeholder="Email"
							/>
						</FormControl>
						<FormControl error={errors.password?.message}>
							<Input
								type="password"
								name="password"
								inputRef={register({
									required: "Required",
								})}
								disabled={loading}
								error={errors.password !== undefined}
								placeholder="Password"
							/>
						</FormControl>

						<Button isLoading={loading}>Reset Password</Button>
					</form>
				)}
			</ModalBody>
		</Modal>
	)
}
