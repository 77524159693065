import { faFacebook, faPinterest, faTwitter } from "@fortawesome/free-brands-svg-icons"
import { faLockAlt, faTrash } from "@fortawesome/pro-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { styled, useStyletron } from "baseui"
import { Button } from "baseui/button"
import { StyledLink } from "baseui/link"
import { Modal, ModalBody, ModalButton, ModalFooter, ModalHeader } from "baseui/modal"
import { Notification } from "baseui/notification"
import { colors } from "baseui/tokens"
import { H1, LabelMedium } from "baseui/typography"
import React from "react"
import { useMutation, useQuery } from "react-fetching-library"
import { useHistory } from "react-router-dom"
import { EventType, EventTypeNames, ScoreTypeNames } from "../../api/enums"
import { isAPIError } from "../../api/types"
import BoostedTagSVG from "../../assets/images/boosted tag.svg"
import { ErrorNotification } from "../../components/common"
import { Loading } from "../../components/loading"
import { PremiumButton } from "../../components/premiumButton"
import { Spaced } from "../../components/spaced"
import { Spread } from "../../components/spread"
import { CommonContainer } from "../../lib/controller"
import { deleteEvent, Event, exportNotes, exportScores, getEvents, reopenEvent, unlockEvent, updateEvent } from "../../lib/events"
import { EventEdit } from "./eventEdit"

export const EventPage = () => {
	const { eventID, setEventObject, removeEvent, user } = CommonContainer.useContainer()
	const history = useHistory()

	// Get Event info
	const [errorMessage, setErrorMessage] = React.useState<string>()
	const [event, setEvent] = React.useState<Event>()
	const { error, payload, loading, query: refresh } = useQuery(getEvents(eventID))
	React.useEffect(() => {
		if (loading) return
		if (error && isAPIError(payload)) {
			setErrorMessage(payload.shortMessage)
			return
		}
		if (!payload || payload.length === 0) {
			setErrorMessage("Failed to get event details")
			return
		}
		setEvent(payload[0])
		setErrorMessage(undefined)
	}, [error, payload, loading])

	// Exporting
	const {
		error: exportScoresError,
		payload: exportScoresPayload,
		query: queryExportScores,
		loading: isExportingScores,
	} = useQuery(exportScores(eventID), false)
	const onExportScores = async () => {
		const resp = await queryExportScores()
		if (!resp.error && resp.payload instanceof Blob) {
			saveAs(resp.payload, `${eventID}_scores.csv`)
		}
	}
	React.useEffect(() => {
		if (exportScoresError && isAPIError(exportScoresPayload)) setErrorMessage(exportScoresPayload.shortMessage)
	}, [exportScoresError, exportScoresPayload])
	const { error: exportNotesError, payload: exportNotesPayload, query: queryExportNotes, loading: isExportingNotes } = useQuery(exportNotes(eventID), false)
	const onExportNotes = async () => {
		const resp = await queryExportNotes()
		if (!resp.error && resp.payload instanceof Blob) {
			saveAs(resp.payload, `${eventID}_comments.csv`)
		}
	}
	React.useEffect(() => {
		if (exportNotesError && isAPIError(exportNotesPayload)) setErrorMessage(exportNotesPayload.shortMessage)
	}, [exportNotesError, exportNotesPayload])

	// Edit Mode
	const [editMode, _setEditMode] = React.useState(false)
	const [showSuccessModal, setShowSuccessModal] = React.useState(false)
	const [showArchiveModal, setShowArchiveModal] = React.useState(false)
	const [showDeleteModal, setShowDeleteModal] = React.useState(false)
	const [showShareModal, setShowShareModal] = React.useState(false)
	const [showUnlockModal, setShowUnlockModal] = React.useState(false)
	const [showReopenModal, setShowReopenModal] = React.useState(false)
	const [showCopiedNotification, setshowCopiedNotification] = React.useState(false)
	const setEditMode = (value: boolean) => {
		_setEditMode(value)
		history.replace({ search: value ? "edit=true" : undefined })
	}
	React.useEffect(() => {
		// Set edit mode based on edit search param
		const params = new URLSearchParams(history.location.search)
		_setEditMode(params.get("edit") === "true")
	}, [history.location.search])
	const onEditComplete = (updatedEvent?: Event) => {
		if (updatedEvent) {
			setShowSuccessModal(true)
			setEvent(updatedEvent)
			setEventObject(updatedEvent)
		}
		setEditMode(false)
	}

	// Archiving
	const { mutate: update, loading: archiving } = useMutation(updateEvent)
	const onArchive = async () => {
		if (!event) return
		const archive = !event.archived
		const resp = await update({ id: eventID, archived: archive })
		setShowArchiveModal(false)
		if (resp.error) {
			setErrorMessage(isAPIError(resp.payload) ? resp.payload.shortMessage : "Failed to archive event")
			return
		}
		refresh().then((r) => {
			// Update event object after archive/unarchive (updates state in the sidebar)
			if (r.error || !r.payload) return
			setEventObject(r.payload[0])
		})
	}

	// Deleting
	const { mutate: del, loading: deleting } = useMutation(deleteEvent)
	const onDelete = async () => {
		if (!event) return
		const resp = await del({ id: eventID })
		setShowDeleteModal(false)
		if (resp.error) {
			setErrorMessage(isAPIError(resp.payload) ? resp.payload.shortMessage : "Failed to delete event")
			return
		}
		removeEvent()
	}

	// Unlocking
	const { mutate: unlock, loading: unlocking } = useMutation(unlockEvent)
	const onUnlock = async () => {
		if (!event) return
		const resp = await unlock({ id: eventID })
		if (resp.error) {
			setErrorMessage(isAPIError(resp.payload) ? `Failed to unlock event: ${resp.payload.shortMessage}` : "Failed to unlock event")
			return
		}
		refresh().then((r) => {
			if (r.error || !r.payload) return
			setEventObject(r.payload[0])
			window.location.reload()
		})
	}

	// Reopening
	const { mutate: reopen, loading: reopening } = useMutation(reopenEvent)
	const onReopen = async () => {
		if (!event) return
		const resp = await reopen({
			id: eventID,
		})
		setShowReopenModal(false)
		if (resp.error || !resp.payload) {
			setErrorMessage(isAPIError(resp.payload) ? `Failed to reopen event: ${resp.payload.shortMessage}` : "Failed to reopen event")
			return
		}
		setEventObject(resp.payload)
		window.location.reload()
	}

	// Styling
	const [css, theme] = useStyletron()
	const container = css({
		maxWidth: "900px",
		margin: "auto",
	})
	const contentStyle = css({
		marginTop: "20px",
		marginBottom: "20px",
	})
	const detailStyle = css({
		padding: "10px",
		display: "grid",
		width: "100%",
		gridTemplateColumns: "220px auto",
	})
	const detailAltStyle = css({
		padding: "10px",
		display: "grid",
		gridTemplateColumns: "220px auto",
		backgroundColor: theme.colors.backgroundSecondary,
		borderRadius: "4px",
	})
	const headerStyle = css({
		display: "flex",
		alignItems: "center",
	})
	const logoStyle = css({
		maxHeight: "60px",
		marginRight: "15px",
	})
	const shareButtonContainer = css({
		display: "flex",
		flexDirection: "column",
	})
	const EventHeading = styled("div", {
		display: "flex",
		alignItems: "center",
		width: "100%",
	})

	// Event details (temporary - will replace with drop-down fields and toggles)
	const opinionDashboardLink = `${window.location.origin}/d/?${eventID}`
	const details = (() => {
		if (!event) return []
		const opinionEvent = event.judge_type === EventType.AudienceParticipation
		const items: { label: string; value: React.ReactNode }[] = [{ label: "Type", value: EventTypeNames[event.judge_type] }]
		if (opinionEvent) {
			items.push(
				{ label: "Public Dashboard", value: event.public_leaderboard === true ? "true" : "false" },
				{
					label: "Audience Participation Dashboard",
					value: (
						<a href={opinionDashboardLink} target="_blank" rel="noopener noreferrer" style={{ color: theme.colors.accent }}>
							{opinionDashboardLink}
						</a>
					),
				},
			)
		} else {
			items.push(
				{ label: "Score Type", value: ScoreTypeNames[event.score_type] },
				{
					label: "Leaderboard",
					value:
						event.public_leaderboard === true
							? event.public_leaderboard_limit === 0
								? "Public (No Limit)"
								: `Public (Top ${event.public_leaderboard_limit})`
							: "Private",
				},
			)
			if (event.judge_type === EventType.Default) {
				items.push(
					{
						label: "Leaderboard - Combine Official & Guest Scores",
						value: <FontAwesomeIcon icon={["fal", event.public_leaderboard_combine ? "check" : "times"]} />,
					},
					{
						label: "Judge Score Weights",
						value: `${event.official_weight} - ${event.guest_weight} (Official - Guest)`,
					},
				)
			}
			const commentArr = event.comment_section_caption.split(">")
			const comments = commentArr.map((s, index) => (
				<div key={index} style={{ width: "100%" }}>
					{s}
				</div>
			))
			items.push({ label: "Comment Section Caption", value: comments })
		}

		return items
	})()

	const publicLeaderboardLink = `${window.location.origin}/leaderboard?eventID=${eventID}`
	const isPublicDashboard = event?.judge_type === EventType.AudienceParticipation && event.public_leaderboard
	const isPublicLeaderboard = event?.public_leaderboard
	const shareLink = {
		default: "",
		twitter: "",
		facebook: "",
		pinterest: "",
	}
	if (isPublicDashboard) {
		shareLink.default = opinionDashboardLink
		shareLink.twitter = `https://twitter.com/intent/tweet?url=${opinionDashboardLink}&text=Join%20us%20on%20Judgefest%20to%20view%20the%20event%20dashboard%20for%20${event?.name}`
		shareLink.facebook = `https://www.facebook.com/sharer/sharer.php?u=${opinionDashboardLink}`
		shareLink.pinterest = `https://pinterest.com/pin/create/button/?url=${opinionDashboardLink}media=&description=Join%20us%20on%20Judgefest%20to%20view%20the%20event%20dashboard%20for%20${event?.name}`
	} else if (isPublicLeaderboard) {
		shareLink.default = publicLeaderboardLink
		shareLink.twitter = `https://twitter.com/intent/tweet?url=${publicLeaderboardLink}&text=Join%20us%20on%20Judgefest%20to%20view%20the%20leaderboard%20for%20${event?.name}`
		shareLink.facebook = `https://www.facebook.com/sharer/sharer.php?u=${publicLeaderboardLink}`
		shareLink.pinterest = `https://pinterest.com/pin/create/button/?url=${publicLeaderboardLink}media=&description=Join%20us%20on%20Judgefest%20to%20view%20the%20leaderboard%20for%20${event?.name}`
	}

	if (!event) {
		return <div className={container}>Loading event data...</div>
	}

	return (
		<div className={container}>
			<div className={headerStyle}>
				{event?.logo && <img src={`data:image/jpeg;base64,${event.logo}`} alt="Logo" className={logoStyle} />}
				<EventHeading>
					<Spaced>
						{event?.archived && <FontAwesomeIcon icon={faLockAlt} size="2x" />}
						{event?.name ? <H1>{event?.name}</H1> : <div style={{ fontStyle: "italic" }}>Unnamed Event</div>}
						{event?.unlocked && <img src={BoostedTagSVG} alt="Boost icon" />}
					</Spaced>
					{event.is_current && event?.archived && (
						<Button
							overrides={{
								BaseButton: {
									style: {
										marginLeft: "auto",
									},
								},
							}}
							kind="secondary"
							onClick={() => setShowReopenModal(true)}
						>
							Launch {event?.shortcode} v{event.iteration_number + 1}
						</Button>
					)}
				</EventHeading>
			</div>
			{!editMode && (
				<Spread
					overrides={{
						container: {
							marginTop: "5px",
							flexWrap: "wrap",
						},
					}}
				>
					<Spaced>
						<PremiumButton onClick={onExportScores} label={"Export Scores"} iconProp={["fal", "file-csv"]} loading={isExportingScores} />
						<PremiumButton onClick={onExportNotes} label={"Export Comments"} iconProp={["fal", "file-csv"]} loading={isExportingNotes} />
					</Spaced>
					{event.is_current && (
						<Spaced
							overrides={{
								container: {
									marginTop: "5px",
								},
							}}
						>
							<Button
								onClick={() => setShowArchiveModal(true)}
								disabled={!event}
								isLoading={archiving}
								startEnhancer={event && <FontAwesomeIcon icon={["fas", event?.archived ? "lock-open-alt" : "lock-alt"]} />}
							>
								{event?.archived ? "Open Event" : "Close Event"}
							</Button>
							<Button onClick={() => setEditMode(true)} disabled={!event || archiving} startEnhancer={<FontAwesomeIcon icon={["fas", "edit"]} />}>
								Edit
							</Button>
							{event?.archived && (
								<Button
									overrides={{
										BaseButton: {
											style: {
												":hover": {
													backgroundColor: colors.red300,
												},
											},
										},
									}}
									shape="square"
									onClick={() => setShowDeleteModal(true)}
								>
									<FontAwesomeIcon icon={faTrash} />
								</Button>
							)}
							{!event?.unlocked && (
								<Button
									onClick={() => setShowUnlockModal(true)}
									overrides={{
										BaseButton: {
											style: {
												position: "relative",
												marginLeft: "auto",
												backgroundColor: "black",
												transition: "all .2s",
												"::after": {
													content: "'Unlock Event'",
													position: "absolute",
													inset: 0,
													display: "flex",
													justifyContent: "center",
													alignItems: "center",
													background: "transparent linear-gradient(to bottom right, #e284b3, #7a92d8, #44b6b4) no-repeat scroll 0% 0% padding-box",
													color: "white",
													opacity: 0,
													transition: "opacity .2s ease-in",
												},
												":hover": {
													"::after": {
														opacity: 1,
													},
												},
											},
										},
									}}
								>
									Unlock Event
								</Button>
							)}
						</Spaced>
					)}
				</Spread>
			)}
			{errorMessage && <ErrorNotification message={errorMessage} />}

			{loading && <Loading />}

			{editMode ? (
				<EventEdit event={event} stopEditMode={onEditComplete} />
			) : (
				<div className={contentStyle}>
					{details.map((detail, index) => (
						<div key={`judge-detail-${detail.label}`} className={index % 2 === 0 ? detailAltStyle : detailStyle}>
							<LabelMedium $style={{ fontWeight: 600 }}>{`${detail.label}:`}</LabelMedium>
							<LabelMedium>{detail.value}</LabelMedium>
						</div>
					))}
				</div>
			)}

			{(isPublicDashboard || isPublicLeaderboard) && !editMode && (
				<Button
					onClick={() => {
						setShowShareModal(true)
					}}
					startEnhancer={<FontAwesomeIcon icon={["fas", "share"]} />}
				>
					Share Event
				</Button>
			)}

			{/* Share Event Modal */}
			<Modal
				onClose={() => {
					setshowCopiedNotification(false)
					setShowShareModal(false)
				}}
				isOpen={showShareModal}
			>
				<ModalHeader>Share Event Leaderboard</ModalHeader>
				<ModalBody>
					<div className={shareButtonContainer}>
						<StyledLink href={shareLink.twitter} target="_blank" rel="noopener noreferrer" tabIndex="-1">
							<Button
								startEnhancer={() => <FontAwesomeIcon icon={faTwitter} />}
								overrides={{
									BaseButton: {
										style: () => ({
											backgroundColor: "#1DA1F2",
											marginBottom: "5px",
											width: "100%",
										}),
									},
								}}
							>
								Share this via Twitter
							</Button>
						</StyledLink>
						<StyledLink href={shareLink.facebook} tabIndex="-1" target="_blank" rel="noopener noreferrer">
							<Button
								startEnhancer={() => <FontAwesomeIcon icon={faFacebook} />}
								overrides={{
									BaseButton: {
										style: () => ({
											backgroundColor: "#4267B2",
											marginBottom: "5px",
											width: "100%",
										}),
									},
								}}
							>
								Share this via Facebook
							</Button>
						</StyledLink>
						<StyledLink href={shareLink.pinterest} target="_blank" rel="noopener noreferrer" tabIndex="-1">
							<Button
								startEnhancer={() => <FontAwesomeIcon icon={faPinterest} />}
								overrides={{
									BaseButton: {
										style: () => ({
											backgroundColor: "#F0002A",
											marginBottom: "5px",
											width: "100%",
										}),
									},
								}}
							>
								Share this via Pinterest
							</Button>
						</StyledLink>
						<Button
							onClick={() => {
								navigator.clipboard.writeText(shareLink.default)
								setshowCopiedNotification(true)
							}}
							startEnhancer={() => <FontAwesomeIcon icon="link" />}
						>
							Copy link
						</Button>
					</div>
					{showCopiedNotification && (
						<Notification
							overrides={{
								Body: {
									style: {
										width: "100%",
										boxSizing: "border-box",
									},
								},
							}}
						>
							<span role="img" aria-label="Scissors">
								✂️
							</span>
							Link copied
						</Notification>
					)}
				</ModalBody>
				<ModalFooter>
					<ModalButton
						onClick={() => {
							setshowCopiedNotification(false)
							setShowShareModal(false)
						}}
					>
						OK
					</ModalButton>
				</ModalFooter>
			</Modal>

			{/* Success Modal */}
			<Modal onClose={() => setShowSuccessModal(false)} isOpen={!!showSuccessModal}>
				<ModalHeader>Event Updated Successfully</ModalHeader>
				<ModalFooter>
					<ModalButton onClick={() => setShowSuccessModal(false)}>OK</ModalButton>
				</ModalFooter>
			</Modal>

			{/* Archive Modal */}
			<Modal onClose={() => setShowArchiveModal(false)} isOpen={!!showArchiveModal}>
				<ModalHeader>{`${event?.archived ? "Open" : "Close"} this event?`}</ModalHeader>
				<ModalBody>
					{event?.archived ? (
						<p>
							{event?.judge_type === EventType.AudienceParticipation
								? "Opening the event will allow you to start pushing questions to the Dashboard and users to join."
								: "Opening the event will allow judges to login and start scoring teams."}
						</p>
					) : (
						<>
							<p>{`Closing the event will lock it - ${
								event?.judge_type === EventType.AudienceParticipation ? "preventing users from joining." : "preventing judges from logging in and scoring."
							}`}</p>
							<p>If the leaderboard is set to public - it will still be visible.</p>
						</>
					)}
				</ModalBody>
				<ModalFooter>
					<ModalButton onClick={() => setShowArchiveModal(false)} disabled={archiving} kind="secondary">
						Cancel
					</ModalButton>
					<ModalButton
						onClick={onArchive}
						isLoading={archiving}
						startEnhancer={<FontAwesomeIcon icon={["fas", event?.archived ? "lock-open-alt" : "lock-alt"]} />}
					>
						{event?.archived ? "Open Event" : "Close Event"}
					</ModalButton>
				</ModalFooter>
			</Modal>

			{/* Delete Modal */}
			<Modal onClose={() => setShowDeleteModal(false)} isOpen={!!showDeleteModal}>
				<ModalHeader>Delete this event?</ModalHeader>
				<ModalBody>
					<p>Are you sure you want to delete this {event?.unlocked && <b>unlocked</b>} event?</p>
					<p>All iterations of this event will be deleted. Once deleted, you will not be able to view this event's history.</p>
					<p>This action is irreversible.</p>
				</ModalBody>
				<ModalFooter>
					<ModalButton onClick={() => setShowDeleteModal(false)} disabled={deleting} kind="secondary">
						Cancel
					</ModalButton>
					<ModalButton
						overrides={{
							BaseButton: {
								style: {
									":hover": {
										backgroundColor: colors.red300,
									},
								},
							},
						}}
						onClick={onDelete}
						isLoading={deleting}
						startEnhancer={<FontAwesomeIcon icon={faTrash} />}
					>
						Delete
					</ModalButton>
				</ModalFooter>
			</Modal>

			{/* Unlock Event Modal */}
			<Modal
				isOpen={showUnlockModal}
				onClose={() => {
					if (unlocking) return
					setShowUnlockModal(false)
				}}
			>
				<ModalHeader>Use 1 Boost to Unlock this event?</ModalHeader>
				<ModalBody>
					<p>
						Boosting this event will unlock all of its features, including unlimited teams and event participants, ad removal, and CSV import/export support for
						teams, judges, and categories on the admin web app.
					</p>
					<p>An unlocked event can also be relaunched an unlimitted amount of times.</p>
					<p>You currently have {user?.creditCount} Boosts.</p>
				</ModalBody>
				<ModalFooter>
					<Spread>
						<Button
							onClick={() => {
								if (unlocking) return
								setShowUnlockModal(false)
							}}
							kind="secondary"
							disabled={unlocking}
						>
							Cancel
						</Button>
						<Button
							onClick={async () => {
								if (unlocking) return
								await onUnlock()
								setShowUnlockModal(false)
							}}
							isLoading={unlocking}
							overrides={{
								BaseButton: {
									style: {
										backgroundColor: "#276ef1",
									},
								},
							}}
						>
							Unlock Event
						</Button>
					</Spread>
				</ModalFooter>
			</Modal>

			{/* Reopen Modal */}
			<Modal onClose={() => setShowReopenModal(false)} isOpen={!!showReopenModal}>
				<ModalHeader>Launch the next iteration of {event?.shortcode}?</ModalHeader>
				<ModalBody>
					<p>
						Launching the next iteration of an event will close the current iteration, removing access from all judges and participants to that event. Original
						event data will be preserved, allowing event admins to view historical data on previous event iterations.
					</p>
					<p>Teams, judges and categories will be copied over to the next iteration of the event.</p>
					<p>Previous iterations of the event cannot be reopened or edited.</p>
				</ModalBody>
				<ModalFooter>
					<ModalButton onClick={() => setShowReopenModal(false)} disabled={reopening} kind="secondary">
						Cancel
					</ModalButton>
					<ModalButton onClick={onReopen} isLoading={reopening}>
						Launch {event?.shortcode} v{event.iteration_number + 1}
					</ModalButton>
				</ModalFooter>
			</Modal>
		</div>
	)
}
