import { faTrash } from "@fortawesome/pro-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { useStyletron } from "baseui"
import { Button } from "baseui/button"
import { FormControl } from "baseui/form-control"
import { Input } from "baseui/input"
import { Modal, ModalBody, ModalButton, ModalFooter, ModalHeader } from "baseui/modal"
import { Select } from "baseui/select"
import { H1 } from "baseui/typography"
import React from "react"
import { useMutation, useQuery } from "react-fetching-library"
import { Controller, useForm } from "react-hook-form"
import { RouteComponentProps, useHistory } from "react-router-dom"
import { ObjectType } from "../../api/enums"
import { APIError, isAPIError } from "../../api/types"
import { ErrorNotification, InlineLabel } from "../../components/common"
import { ItemList } from "../../components/itemList"
import { Loading } from "../../components/loading"
import { Spaced } from "../../components/spaced"
import { Spread } from "../../components/spread"
import { CommonContainer } from "../../lib/controller"
import { createGroup, deleteGroup, getGroup, Group, updateGroup } from "../../lib/groups"
import { Judge, listJudges } from "../../lib/judges"
import { listTeams, Team } from "../../lib/teams"

interface GroupPageProps extends RouteComponentProps<{ id: string }> {}

export const GroupPage: React.FC<GroupPageProps> = ({ match }) => {
	const [css, theme] = useStyletron()
	const containerStyle = css({
		maxWidth: "900px",
		margin: "10px auto",
	})
	const nameStyle = css({
		...theme.typography.HeadingSmall,
	})
	const headerStyle = css({
		display: "none",
		"@media only screen and (max-width: 700px)": {
			display: "flex",
		},
	})

	const { event, eventID } = CommonContainer.useContainer()
	const history = useHistory()

	const { id } = match.params
	const isNew = id === "create"

	const [editMode, _setEditMode] = React.useState(isNew)
	const [group, setGroup] = React.useState<Group>()
	const [errorMessage, setErrorMessage] = React.useState<string>()
	const [showSuccessModal, setShowSuccessModal] = React.useState<string>()
	const [showDeleteModal, setShowDeleteModal] = React.useState(false)

	const { mutate: del, loading: deleting } = useMutation(deleteGroup)
	const { error, payload, loading } = useQuery<Group>(getGroup(id, eventID), true)

	// Editing
	const setEditMode = (value: boolean) => {
		_setEditMode(value)
		history.replace({ search: value ? "edit=true" : undefined })
	}
	React.useEffect(() => {
		const params = new URLSearchParams(history.location.search)
		// Set edit mode based on edit search param
		if (!isNew) _setEditMode(params.get("edit") === "true")
	}, [history.location.search, isNew])

	const onEditComplete = (updatedGroup?: Group) => {
		if (updatedGroup) {
			setShowSuccessModal(isNew ? "Created" : "Updated")
			setGroup(updatedGroup)
			history.replace("/groups/" + updatedGroup.id)
		} else if (isNew) {
			history.push("/groups")
		}

		setEditMode(false)
	}

	// Get group info
	React.useEffect(() => {
		if (!payload || error) return
		setGroup(payload)
		setErrorMessage(undefined)
	}, [error, payload, loading])

	if (editMode)
		return (
			<div className={containerStyle}>
				<div className={headerStyle}>
					<H1>Groups</H1>
				</div>
				<div className={nameStyle}>{isNew ? "New Group" : "Edit Group"}</div>
				<GroupEdit group={group} isNew={isNew} stopEditMode={onEditComplete} />
			</div>
		)

	// Delete group
	const onDeleteGroup = async () => {
		if (!group) return

		const resp = await del({ eventID, groupID: group.id })
		setShowDeleteModal(false)

		if (resp.error) {
			setErrorMessage(isAPIError(resp.payload) ? resp.payload.shortMessage : "Failed to delete group")
			return
		}

		history.push("/judges#groups")
	}

	if (!group && loading) return <Loading />
	if (!group) return <></>

	return (
		<div className={containerStyle}>
			<H1>Groups</H1>
			<Spread>
				<div className={nameStyle}>{group.name}</div>
				{event?.is_current && (
					<div>
						<Button
							onClick={() => setEditMode(true)}
							startEnhancer={<FontAwesomeIcon icon={["fas", "edit"]} />}
							overrides={{
								BaseButton: {
									style: {
										marginRight: ".5rem",
									},
								},
							}}
						>
							Edit
						</Button>
						<Button onClick={() => setShowDeleteModal(true)} startEnhancer={<FontAwesomeIcon icon={faTrash} />}>
							Delete
						</Button>
					</div>
				)}
			</Spread>
			<ItemList
				hideActions={!event?.is_current}
				type={ObjectType.GroupTeamList}
				columns={[
					{
						name: "Name",
						resolver: (value) => value.name,
					},
					{
						name: "Product Name",
						resolver: (value) => value.product_name,
					},
				]}
				groupID={group.id}
				onRowClick={(row) => {
					history.push(`/teams/${row.id}`)
				}}
				onCreate={null}
				hideArchiveToggle={true}
			/>
			<ItemList
				hideActions={!event?.is_current}
				type={ObjectType.GroupJudgeList}
				columns={[
					{
						name: "Email",
						resolver: (value) => value.email,
					},
					{
						name: "Guest",
						resolver: (value) => value.is_guest === true && <FontAwesomeIcon icon={["fal", "check"]} />,
					},
				]}
				groupID={group.id}
				onRowClick={(row) => {
					history.push(`/judges/${row.id}`)
				}}
				onCreate={null}
				hideArchiveToggle={true}
			/>
			{/* Success Modal */}
			<Modal onClose={() => setShowSuccessModal(undefined)} isOpen={!!showSuccessModal}>
				<ModalHeader>{`Group ${showSuccessModal} Successfully`}</ModalHeader>
				<ModalFooter>
					<ModalButton onClick={() => setShowSuccessModal(undefined)}>OK</ModalButton>
				</ModalFooter>
			</Modal>

			{/* Delete Modal */}
			<Modal onClose={() => setShowDeleteModal(false)} isOpen={!!showDeleteModal}>
				<ModalHeader>{`${event?.archived ? "Open" : "Close"} this event?`}</ModalHeader>
				<ModalBody>
					<p>This action cannot be undone.</p>
					{errorMessage && <ErrorNotification message={errorMessage} />}
				</ModalBody>
				<ModalFooter>
					<ModalButton onClick={() => setShowDeleteModal(false)} kind="secondary">
						Cancel
					</ModalButton>
					<ModalButton onClick={onDeleteGroup} isLoading={deleting} startEnhancer={<FontAwesomeIcon icon={faTrash} />}>
						Delete group
					</ModalButton>
				</ModalFooter>
			</Modal>
		</div>
	)
}

interface GroupInput {
	newName: string
	assignedJudges: { label: string; id: string }[]
	assignedTeams: { label: string; id: string }[]
}

const GroupEdit = (props: { group?: Group; isNew: boolean; stopEditMode: (group?: Group) => void }) => {
	// Styling
	const [css] = useStyletron()
	const footerStyle = css({
		display: "flex",
		flexDirection: "row-reverse",
	})

	const { group, isNew, stopEditMode } = props

	const { eventID } = CommonContainer.useContainer()

	const { payload: teams } = useQuery<Team[]>(listTeams(eventID))
	const { payload: judges } = useQuery<Judge[]>(listJudges(eventID))
	const { control, register, handleSubmit, setValue, trigger, errors } = useForm<GroupInput>()
	const { mutate: create, loading: cLoading, error: cError, payload: cPayload } = useMutation(createGroup)
	const { mutate: update, loading: uLoading, error: uError, payload: uPayload } = useMutation(updateGroup)

	const loading = isNew ? cLoading : uLoading
	const error = isNew ? cError : uError
	const payload = isNew ? cPayload : uPayload

	const onSaveForm = handleSubmit(async (input) => {
		if (!trigger()) return

		const resp = isNew
			? await create({
					eventID,
					name: input.newName,
					assignedTeamIDs: input.assignedTeams?.map((t) => t.id) || [],
					assignedJudgeIDs: input.assignedJudges?.map((t) => t.id) || [],
			  })
			: await update({
					eventID,
					groupID: group?.id || "",
					newname: input.newName,
					assignedTeamIDs: input.assignedTeams?.map((t) => t.id) || [],
					assignedJudgeIDs: input.assignedJudges?.map((t) => t.id) || [],
			  })

		if ((resp.payload as APIError).error) return

		const updatedGroup = resp.payload as Group
		if (updatedGroup) stopEditMode(updatedGroup)
	})

	// Load defaults
	React.useEffect(() => {
		if (!group) return
		setValue("newName", group.name)
		setValue("assignedTeams", group.assigned_teams?.map(teamToSelectOption))
		setValue("assignedJudges", group.assigned_judges?.map(judgeToSelectOption))
	}, [group, setValue])

	return (
		<form
			onSubmit={(e) => {
				e.preventDefault()
				onSaveForm()
			}}
		>
			{error && isAPIError(payload) && <ErrorNotification message={payload.shortMessage} />}

			<FormControl label={<InlineLabel label="Name" caption="Name of the group" />} error={errors.newName?.message} positive="">
				<Input name="newName" inputRef={register({ required: "Group Name is required" })} disabled={loading} />
			</FormControl>

			<FormControl
				label={<InlineLabel label="Assigned Teams" caption="Selected teams will be assigned to this group. A team may only be assigned to a single group" />}
			>
				<Controller
					name="assignedTeams"
					control={control}
					defaultValue={[]}
					render={({ value, onChange }) => (
						<div>
							<Select
								options={teams ? teams.map(teamToSelectOption) : []}
								value={value}
								multi
								onChange={(params) => onChange(params.value)}
								overrides={{
									Tag: {
										props: {
											overrides: {
												Root: {
													style: {
														height: "unset",
														paddingTop: "6px",
														paddingBottom: "6px",
														paddingLeft: "14px",
														paddingRight: "14px",
													},
												},
												Text: {
													style: {
														maxWidth: "unset",
														whiteSpace: "no-wrap",
													},
												},
											},
										},
									},
								}}
								placeholder="All Teams"
								isLoading={!teams}
							/>
						</div>
					)}
				/>
			</FormControl>

			<FormControl
				label={
					<InlineLabel
						label="Assigned Judges"
						caption="Selected judges will be able to view teams assigned to this group. A judge may be assigned to multiple groups"
					/>
				}
			>
				<Controller
					name="assignedJudges"
					control={control}
					defaultValue={[]}
					render={({ value, onChange }) => (
						<div>
							<Select
								options={judges ? judges.map(judgeToSelectOption) : []}
								value={value}
								multi
								onChange={(params) => onChange(params.value)}
								overrides={{
									Tag: {
										props: {
											overrides: {
												Root: {
													style: {
														height: "unset",
														paddingTop: "6px",
														paddingBottom: "6px",
														paddingLeft: "14px",
														paddingRight: "14px",
													},
												},
												Text: {
													style: {
														maxWidth: "unset",
														whiteSpace: "no-wrap",
													},
												},
											},
										},
									},
								}}
								placeholder="All Teams"
								isLoading={!judges}
							/>
						</div>
					)}
				/>
			</FormControl>

			<div className={footerStyle}>
				<Spaced>
					<Button type="button" onClick={() => stopEditMode()} kind="secondary">
						Cancel
					</Button>
					<Button
						onClick={(e) => {
							e.preventDefault()
							onSaveForm()
						}}
						isLoading={loading}
						startEnhancer={<FontAwesomeIcon icon={["fas", "save"]} />}
					>
						Save
					</Button>
				</Spaced>
			</div>
		</form>
	)
}

const teamToSelectOption = (t: Team) => ({ label: `${t.name} - ${t.product_name}`, id: t.id })
const judgeToSelectOption = (j: Judge) => ({ label: `${j.email}${j.is_guest ? " - Guest" : ""}`, id: j.id })
