import { library as IconLibrary, IconProp, IconPrefix } from "@fortawesome/fontawesome-svg-core"

import {
	faHeadset,
	faHandHoldingBox,
	faUser,
	faUsers,
	faUserTag,
	faUserSecret,
	faUserChart,
	faMoneyCheckAlt,
	faUserCircle,
	faEnvelope,
	faChevronDown,
	faChevronUp,
	faChartPieAlt,
	faCheck,
	faTimes,
	faFilter,
	faSearch,
	faBuilding,
	faCog,
	faSignOut,
	faFile,
	faBalanceScale,
	faGavel,
	faStar,
	faFileCsv,
	faFilePdf,
	faIdCard,
	faCreditCard,

} from "@fortawesome/pro-light-svg-icons"
import {
	faFilter as fasFilter,
	faUserSecret as fasUserSecret,
	faUser as fasUser,
	faUserTag as fasUserTag,
	faUserChart as fasUserChart,
	faBuilding as fasBuilding,
	faFile as fasFile,
	faBalanceScale as fasBalanceScale,
	faGavel as fasGavel,
	faUsers as fasUsers,
	faChartPieAlt as fasChartPieAlt,
	faSignOut as fasSignOut,
	faStar as fasStar,
	faList as fasList,
	faQuestion as fasQuestion,
	faEdit as fasEdit,
	faLockAlt as fasLockAlt,
	faLockOpenAlt as fasLockOpenAlt,
	faSort as fasSort,
	faSave as fasSave,
	faArchive as fasArchive,
	faTrashAlt as fasTrashAlt,
	faUndo as fasUndo,
	faPlusCircle as fasPlusCirlce,
	faMinusCircle as fasMinusCircle,
	faUserHardHat as fasUserHardHat,
	faLock as fasLock,
	faDollarSign as fasDollarSign,
	faUnlock as fasUnlock,
	faShare as fasShare,
	faCreditCard as fasCreditCard,
	faLink as fasLink,
} from "@fortawesome/pro-solid-svg-icons"

export const loadIcons = () => {
	IconLibrary.add(
		faHeadset,
		faHandHoldingBox,
		faUser,
		faUsers,
		faUserTag,
		faUserSecret,
		faUserChart,
		faMoneyCheckAlt,
		faUserCircle,
		faEnvelope,
		faChevronDown,
		faChevronUp,
		faChartPieAlt,
		faCheck,
		faTimes,
		faFilter,
		faSearch,
		faBuilding,
		faCog,
		faSignOut,
		faFile,
		faBalanceScale,
		faGavel,
		faStar,
		faFileCsv,
		faFilePdf,
		faIdCard,
		faCreditCard,

		fasFilter,
		fasUserSecret,
		fasUser,
		fasUserTag,
		fasUserChart,
		fasBuilding,
		fasFile,
		fasBalanceScale,
		fasGavel,
		fasUsers,
		fasChartPieAlt,
		fasSignOut,
		fasStar,
		fasList,
		fasQuestion,
		fasEdit,
		fasLockAlt,
		fasLockOpenAlt,
		fasSort,
		fasSave,
		fasArchive,
		fasTrashAlt,
		fasUndo,
		fasPlusCirlce,
		fasMinusCircle,
		fasUserHardHat,
		fasLock,
		fasUnlock,
		fasDollarSign,
		fasShare,
		fasCreditCard,
		fasLink,
	)
}

export const GetItemIcon = (name: string, prefix?: IconPrefix): IconProp => {
	const p = prefix || "fal"
	switch (name) {
		case "user":
			return [p, "user"]
		case "role":
			return [p, "user-tag"]
		case "userActivity":
			return [p, "user-chart"]
		case "organisation":
			return [p, "building"]
		case "blob":
			return [p, "file"]
	}
	return [p, "user"]
}
