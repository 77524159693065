import * as React from "react"
import { Notification } from "baseui/notification"
import { FormControl } from "baseui/form-control"
import { Controller, useForm } from "react-hook-form"
import { CommonContainer } from "../lib/controller"
import { ErrorNotification } from "./common"
import { Button } from "baseui/button"
import { Input } from "baseui/input"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { Address } from "../api/types"
import { Select, TYPE } from "baseui/select"
import * as data from "../assets/countries.json"

interface FormData {
	line1?: string
	line2?: string
	line3?: string
	city?: string
	state?: string
	country?: string
	postOrZip?: string
	companyName?: string
	taxNumber?: string
}

export const ChangeAddressForm = () => {
	const { handleSubmit, register, trigger, errors, setValue, formState, control } = useForm<FormData>()
	const { user, useChangeAddress } = CommonContainer.useContainer()
	const { changeAddress, loading, success, error } = useChangeAddress()
	const countries = data.data.map((c: { id: string; label: string }) => c)

	const onSubmit = handleSubmit(async (input) => {
		if (!trigger()) return

		const newAdd: Address = {
			companyName: input.companyName,
			taxNumber: input.taxNumber,
			line1: input.line1,
			line2: input.line2,
			city: input.city,
			state: input.state,
			country: input.country,
			postOrZip: input.postOrZip,
		}
		changeAddress(newAdd)
	})

	// Load current data
	React.useEffect(() => {
		if (!user || !user.address) return
		setValue("line1", user.address.line1)
		setValue("line2", user.address.line2)
		setValue("companyName", user.address.companyName)
		setValue("city", user.address.city)
		setValue("state", user.address.state)
		setValue("country", user.address.country)
		setValue("postOrZip", user.address.postOrZip)
		setValue("taxNumber", user.address.taxNumber)
	}, [user, setValue])

	return (
		<form onSubmit={onSubmit}>
			<div>
				{success && !formState.isDirty && <Notification kind="positive">Your details has been updated.</Notification>}
				{error && <ErrorNotification message={error} />}
				<FormControl label="Company Name" error={errors.companyName && errors.companyName.message}>
					<Input inputRef={register()} name="companyName" type="text" error={errors.companyName !== undefined} disabled={loading} />
				</FormControl>
				<FormControl label="Tax Number" error={errors.taxNumber && errors.taxNumber.message}>
					<Input inputRef={register()} name="taxNumber" type="text" error={errors.taxNumber !== undefined} disabled={loading} />
				</FormControl>
				<FormControl label="Address Line 01" error={errors.line1 && errors.line1.message}>
					<Input inputRef={register()} name="line1" type="text" error={errors.line1 !== undefined} disabled={loading} />
				</FormControl>
				<FormControl label="Address Line 02" error={errors.line2 && errors.line2.message}>
					<Input inputRef={register()} name="line2" type="text" error={errors.line2 !== undefined} disabled={loading} />
				</FormControl>
				<FormControl label="City" error={errors.city && errors.city.message}>
					<Input inputRef={register()} name="city" type="text" error={errors.city !== undefined} disabled={loading} />
				</FormControl>
				<FormControl label="State" error={errors.state && errors.state.message}>
					<Input inputRef={register()} name="state" type="text" error={errors.state !== undefined} disabled={loading} />
				</FormControl>
				<FormControl label="Country" error={errors.country && errors.country.message}>
					<Controller
						name="country"
						control={control}
						render={({ onChange, value }) => {
							const v = countries.find((i) => i.id === value)
							return (
								<Select
									options={countries}
									onChange={({ value }) => {
										if (!value[0]) {
											onChange("")
											return
										}
										onChange(value[0].id)
									}}
									onInputChange={(e) => {
										const textBox = e.target as HTMLTextAreaElement
										onChange(textBox.id)
									}}
									value={v ? [v] : []}
									type={TYPE.search}
									maxDropdownHeight={"400px"}
								/>
							)
						}}
					/>
				</FormControl>
				<FormControl label="Postcode or Zipcode" error={errors.postOrZip && errors.postOrZip.message}>
					<Input inputRef={register()} name="postOrZip" type="text" error={errors.postOrZip !== undefined} disabled={loading} />
				</FormControl>
			</div>

			<Button
				onClick={onSubmit}
				isLoading={loading}
				startEnhancer={<FontAwesomeIcon icon={["fas", "save"]} />}
				overrides={{
					Root: {
						style: {
							display: "flex",
							marginLeft: "auto",
						},
					},
				}}
			>
				Save
			</Button>
		</form>
	)
}
