import * as React from "react"
import { Modal, ModalBody, ModalButton, ModalFooter, ModalHeader } from "baseui/modal"
import { useMutation } from "react-fetching-library"
import { FileUploader } from "baseui/file-uploader"
import { plural } from "pluralize"
import { ObjectType } from "../api/enums"
import { importMutation } from "../lib/common"

interface ImportModalProps {
	event: string
	type: ObjectType
	isOpen: boolean
	onClose: () => void
	onRefresh: () => void
}

export const ImportModal = (props: ImportModalProps) => {
	const { type, isOpen, onClose, onRefresh } = props

	const [file, setFile] = React.useState<File | undefined>()
	const [errorMessage, setErrorMessage] = React.useState<string>()
	const { mutate, loading } = useMutation(importMutation(type))

	const onImport = async () => {
		if (!file) return

		setErrorMessage(undefined)

		const resp = await mutate({
			eventID: props.event,
			file,
		})

		if (resp.error) {
			setErrorMessage(resp.payload?.shortMessage)
			return
		}

		onRefresh()
		onClose()
	}

	const pluralName = plural(type)
	const csvFormat = () => {
		switch (type) {
			case ObjectType.Team:
				return "name,product_name,description,website_url"
			case ObjectType.Category:
				return "name,weight,description"
			default:
				return "email,pin"
		}
	}

	return (
		<Modal closeable isOpen={isOpen} onClose={onClose}>
			<ModalHeader>
				<span style={{ textTransform: "capitalize" }}>{`Import ${pluralName}`}</span>
			</ModalHeader>
			<ModalBody>
				<p>
					{`Import csv file of ${pluralName} in this format: `}
					<br />
					<strong>{csvFormat()}</strong>
				</p>
				{file && (
					<p>
						<strong>{file.name}</strong>
					</p>
				)}
				<FileUploader
					errorMessage={errorMessage}
					accept=".csv"
					onDrop={(acceptedFiles) => {
						if (acceptedFiles.length === 0) {
							setErrorMessage("Invalid file")
							return
						}
						setErrorMessage(undefined)
						setFile(acceptedFiles[0])
					}}
					onRetry={() => setErrorMessage(undefined)}
					disabled={loading}
				/>
			</ModalBody>
			<ModalFooter>
				<ModalButton onClick={onImport} isLoading={loading} disabled={!file}>
					Import
				</ModalButton>
				<ModalButton onClick={onClose}>Cancel</ModalButton>
			</ModalFooter>
		</Modal>
	)
}
