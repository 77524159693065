import { useStyletron } from "baseui"
import { Block } from "baseui/block"
import { PLACEMENT, ToasterContainer } from "baseui/toast"
import React from "react"
import { BrowserRouter as Router, Route, Switch } from "react-router-dom"
import { ImpersonatorBorderAndButton } from "./components/impersonatorBorderAndButton"
import { Loading } from "./components/loading"
import { SideBar } from "./components/nav"
import { CreditCounter, TopBar, TopBarAccountDropdown } from "./components/topbar"
import { CommonContainer } from "./lib/controller"
import { ErrorBoundary } from "./pages/error"
import { EventPickerPage } from "./pages/eventPicker"
import { Leaderboard, PublicLeaderboard } from "./pages/leaderboard"
import { ListPage } from "./pages/listPages"
import { ManageBilling } from "./pages/management/billing"
import { Boosts } from "./pages/management/boosts"
import { EventPage } from "./pages/management/event"
import { EventUsers } from "./pages/management/eventUsers"
import { Upgrade } from "./pages/management/upgrade"
import { RedirectToApp } from "./pages/redirectToApp"
import { Settings } from "./pages/settings"
import { SignIn } from "./pages/signIn"
import { SignUp } from "./pages/signUp"
import { UserSubscription } from "./pages/subscription"
import { SuperAdminViewEventPage } from "./pages/superadmin/event"
import { SuperAdminManageEventsPage } from "./pages/superadmin/events"
import { SuperAdminHome } from "./pages/superadmin/home"
import { SuperAdminViewUserPage } from "./pages/superadmin/user"
import { SuperAdminManageUsersPage } from "./pages/superadmin/users"
import { ViewPage } from "./pages/viewPages"
import "./stylesheet.css"

export const TOPBAR_HEIGHT = "70px"

export const App = () => {
	const { useCheckAuth } = CommonContainer.useContainer()
	const { loading, signedIn, isSuperAdmin, isImpersonated } = useCheckAuth()
	const { eventID } = CommonContainer.useContainer()

	const [css, theme] = useStyletron()
	const impersonationStyle = css({
		position: "relative",
		display: "flex",
		flexDirection: "column",
		height: "100vh",
	})
	const containerStyle = css({
		flex: "1",
		position: "relative",
		display: "flex",
		width: "100%",
		overflow: "auto",
	})
	const mainStyle = css({
		display: "flex",
		width: "100%",
		flexDirection: "column",
		overflowY: "auto",
	})
	const contentStyle = css({
		width: "100%",
		height: `calc(100% - ${TOPBAR_HEIGHT})`, // 100% - top bar height
		padding: "0 20px",
	})
	const noEventContentStyle = css({
		height: `calc(100% - ${TOPBAR_HEIGHT})`, // 100% - top bar height
		marginTop: TOPBAR_HEIGHT,
	})
	const noEventTopBarStyle = css({
		position: "absolute",
		display: "flex",
		justifyContent: "flex-end",
		alignItems: "center",
		width: "100%",
		height: TOPBAR_HEIGHT,
		padding: "0 25px",
		boxShadow: theme.lighting.shadow400,
		backgroundColor: "rgba(255, 255, 255, 0.65)",
		fontSize: "1.5rem",
	})

	if (loading) return <Loading />

	const renderRoutes = () => {
		// If signed in
		if (signedIn) {
			// If user is super admin
			if (isSuperAdmin) {
				return (
					<div className={mainStyle}>
						<TopBar />
						<div className={contentStyle}>
							<ErrorBoundary>
								<Switch>
									<Route exact path="/" component={SuperAdminHome} />
									<Route exact path="/users" component={SuperAdminManageUsersPage} />
									<Route exact path="/users/:id" component={SuperAdminViewUserPage} />
									<Route exact path="/events" component={SuperAdminManageEventsPage} />
									<Route exact path="/events/:id" component={SuperAdminViewEventPage} />
								</Switch>
							</ErrorBoundary>
						</div>
					</div>
				)
			}
			// If regular user
			if (!eventID) {
				return (
					<div className={mainStyle}>
						<div className={noEventTopBarStyle}>
							<Block marginRight="1rem">
								<CreditCounter />
							</Block>
							<TopBarAccountDropdown />
						</div>
						<div className={noEventContentStyle}>
							<ErrorBoundary>
								<Switch>
									<Route exact path="/settings" component={Settings} />
									<Route exact path="/subscription" component={UserSubscription} />
									<Route exact path="/billing" component={ManageBilling} />
									<Route exact path="/upgrade" component={Upgrade} />
									<Route exact path="/boosts" component={Boosts} />
									<Route path="/" component={EventPickerPage} />
								</Switch>
							</ErrorBoundary>
						</div>
					</div>
				)
			} else {
				return (
					<>
						<SideBar />
						<div className={mainStyle}>
							<TopBar />
							<div className={contentStyle}>
								<ErrorBoundary>
									<Switch>
										<Route exact path="/groups/:id" component={ViewPage.Group} />

										<Route exact path="/teams/:id" component={ViewPage.Team} />
										<Route exact path="/teams" component={ListPage.Team} />

										<Route exact path="/judges/:id" component={ViewPage.Judge} />
										<Route exact path="/judges/:updated?/:id?" component={ListPage.Judge} />

										<Route exact path="/categories/:id" component={ViewPage.Category} />
										<Route exact path="/categories" component={ListPage.Category} />

										<Route exact path="/leaderboard" component={Leaderboard} />

										<Route exact path="/users" component={EventUsers} />

										<Route exact path="/questions/:id" component={ViewPage.Question} />
										<Route exact path="/questions" component={ListPage.Questions} />

										{/* <Route exact path="/word-blacklist" component={TempOpinionEventPage} /> */}

										<Route exact path="/settings" component={Settings} />

										<Route exact path="/subscription" component={UserSubscription} />
										<Route exact path="/billing" component={ManageBilling} />
										<Route exact path="/upgrade" component={Upgrade} />
										<Route exact path="/boosts" component={Boosts} />

										{/* Opens the JudgeFest mobile app */}
										<Route exact path="/open" component={RedirectToApp} />

										<Route exact path="/" component={EventPage} />
									</Switch>
								</ErrorBoundary>
							</div>
						</div>
					</>
				)
			}
		}

		// If not signed in
		return (
			<div className={mainStyle}>
				<Switch>
					<Route exact path="/leaderboard" component={PublicLeaderboard} />

					<Route exact path="/register" component={SignUp} />

					<Route path="/" component={SignIn} />
				</Switch>
			</div>
		)
	}

	return (
		<ToasterContainer placement={PLACEMENT.bottom}>
			<div className={impersonationStyle}>
				<Router>
					<div className={containerStyle}>{renderRoutes()}</div>
					{isImpersonated && <ImpersonatorBorderAndButton />}
				</Router>
			</div>
		</ToasterContainer>
	)
}
