import { ObjectType } from "../api/enums"
import { exportCategories, getCategory, importCategories } from "./categories"
import { exportJudges, getJudge, importJudges } from "./judges"
import { exportTeams, getTeam, importTeams } from "./teams"

export const getQuery = (type: ObjectType, eventID: string, id: string) => {
	switch (type) {
		case ObjectType.Team:
			return getTeam(eventID, id)
		case ObjectType.Category:
			return getCategory(eventID, id)
		default:
			return getJudge(eventID, id)
	}
}

export const exportQuery = (type: ObjectType, eventID: string) => {
	switch (type) {
		case ObjectType.Team:
			return exportTeams(eventID)
		case ObjectType.Category:
			return exportCategories(eventID)
		default:
			return exportJudges(eventID)
	}
}

export const importMutation = (type: ObjectType) => {
	switch (type) {
		case ObjectType.Team:
			return importTeams
		case ObjectType.Category:
			return importCategories
		default:
			return importJudges
	}
}
