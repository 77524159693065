import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { useStyletron } from "baseui"
import { Button } from "baseui/button"
import { Card } from "baseui/card"
import { FormControl } from "baseui/form-control"
import { Input } from "baseui/input"
import { Modal, ModalBody, ModalButton, ModalFooter, ModalHeader } from "baseui/modal"
import { Textarea } from "baseui/textarea"
import * as React from "react"
import { useMutation, useQuery } from "react-fetching-library"
import { Controller, useForm } from "react-hook-form"
import { ObjectType, ScoreType } from "../../api/enums"
import { isAPIError, TeamAssessment, TeamScore } from "../../api/types"
import { ErrorNotification } from "../../components/common"
import { ItemList } from "../../components/itemList"
import { Spaced } from "../../components/spaced"
import { Spread } from "../../components/spread"
import { Category, listCategories } from "../../lib/categories"
import { CommonContainer } from "../../lib/controller"
import { deleteTeamAssessment, updateTeamAssessment } from "../../lib/teamAssessments"

interface TeamAssessmentInput {
	judgeEmail: string

	scores: TeamScore[]
	hasScores: boolean
	comment: string
}

export const TeamAssessmentList = (props: { teamID: string }) => {
	const { teamID } = props

	const { event, eventID } = CommonContainer.useContainer()

	// Editing
	const { mutate: update, loading, error, payload } = useMutation(updateTeamAssessment)
	const { control, handleSubmit, errors, watch, reset } = useForm<TeamAssessmentInput>({
		defaultValues: {
			judgeEmail: "",

			scores: [],
			hasScores: false,
			comment: "",
		},
	})
	const judgeEmail = watch("judgeEmail")
	const hasScores = watch("hasScores")
	const [judgeID, setJudgeID] = React.useState<string>()
	const [judgeIsGuest, setJudgeIsGuest] = React.useState(false)

	const onSaveForm = handleSubmit(async (input) => {
		if (!judgeID || !event) return

		let scores = input.scores
		if (event.score_type === ScoreType.Decimal) {
			// If event uses decimal point scores - times scores by 10 (since endpoint expects integers)
			scores = input.scores.map((s) => ({ categoryID: s.categoryID, score: Math.floor(s.score * 10) } as TeamScore))
		} else {
			// Make sure scores are an integer
			scores = input.scores.map((s) => ({ categoryID: s.categoryID, score: Math.floor(s.score) } as TeamScore))
		}

		const data = {
			teamID: teamID,
			judgeID: judgeID,
			scores: scores,
			comment: input.comment,
		}

		const resp = await update(data)
		if (!resp.error) setJudgeID(undefined)
	})

	// Deleting
	const { mutate: deleteScores, loading: deleting, error: deleteError, payload: deletePayload } = useMutation(deleteTeamAssessment)
	const [showDeleteModal, setShowDeleteModal] = React.useState(false)
	const onDelete = async () => {
		if (!judgeID) return
		const resp = await deleteScores({ teamID, judgeID })
		if (resp.error) return
		setJudgeID(undefined)
		setShowDeleteModal(false)
	}

	// Get Catgories (for edit modal)
	const { payload: categories } = useQuery<Category[]>(listCategories(eventID))

	const getTotal = (value: TeamScore[]) => {
		if (!categories) return 0

		if (event?.avg_overall_scores) {
			let weightsTotal = 0
			const total = value.reduce((a, b) => {
				const weight = categories.find((c) => c.id === b.categoryID)?.weight || 0
				weightsTotal += weight
				return a + b.score * weight
			}, 0)
			if (weightsTotal <= 0) weightsTotal = 1

			return Math.round((total / weightsTotal) * 10) / 10
		} else {
			return Math.round(value.reduce((a, b) => a + b.score, 0) * 10) / 10
		}
	}

	// Styling
	const [css] = useStyletron()
	const editSubtitleStyle = css({
		marginLeft: "auto",
		fontSize: "16px",
		color: "grey",
	})
	const totalScoreStyle = css({
		display: "flex",
		flexDirection: "row-reverse",
		marginRight: "10px",
	})
	const guestJudgeStyle = css({
		marginLeft: "5px",
		color: "grey",
		fontSize: "14px",
	})

	if (judgeID)
		return (
			<Card
				title={
					<>
						<span>{`${event?.is_current ? "Edit" : "View"} Scores & Comments`}</span>
						<span className={editSubtitleStyle}>{judgeEmail}</span>
					</>
				}
				overrides={{
					Title: {
						style: {
							display: "flex",
						},
					},
				}}
			>
				<form onSubmit={onSaveForm}>
					{error && isAPIError(payload) && <ErrorNotification message={payload.shortMessage} />}

					<Controller
						name="scores"
						control={control}
						render={({ onChange, value }) => (
							<div>
								{categories?.map((category) => {
									const index = (value as TeamScore[]).findIndex((v) => v.categoryID === category.id)
									return (
										<div key={`score-${category.id}`} style={{ display: "flex" }}>
											<FormControl
												disabled={!event?.is_current}
												label={category.name}
												overrides={{
													ControlContainer: {
														style: {
															maxWidth: "80px",
														},
													},
												}}
											>
												<Input
													value={index === -1 ? 0 : value[index].score}
													onChange={(e) => {
														if (index === -1) return
														const v: TeamScore[] = [...value]
														v[index].score = Math.max(Math.min(+e.currentTarget.value, 10), 0)
														onChange(v)
													}}
													onBlur={
														event?.score_type === ScoreType.Decimal
															? undefined
															: (e) => {
																	// Remove floating point if event score type is not decimal
																	if (index === -1) return
																	const v: TeamScore[] = [...value]
																	v[index].score = Math.max(Math.min(Math.floor(+e.currentTarget.value), 10), 0)
																	onChange(v)
															  }
													}
													type="number"
													max={10}
													min={0}
													disabled={loading || judgeIsGuest}
												/>
											</FormControl>
										</div>
									)
								})}
								<div className={totalScoreStyle}>{`Total: ${getTotal(value)}`}</div>
							</div>
						)}
					/>

					<FormControl disabled={!event?.is_current} label="Comment" error={errors.comment?.message} positive="">
						<Controller
							name="comment"
							control={control}
							as={Textarea}
							overrides={{
								Input: {
									style: {
										resize: "vertical",
										minHeight: "46px",
									},
								},
							}}
							disabled={loading || judgeIsGuest}
							placeholder={event?.comment_section_caption}
						/>
					</FormControl>

					{event?.is_current && (
						<Spread>
							<Button
								type="button"
								onClick={() => setShowDeleteModal(true)}
								disabled={loading || !hasScores}
								isLoading={deleting}
								startEnhancer={<FontAwesomeIcon icon={["fas", "trash-alt"]} />}
							>
								Delete
							</Button>
							<Spaced>
								<Button kind="secondary" type="button" onClick={() => setJudgeID(undefined)} disabled={loading}>
									Cancel
								</Button>
								{!judgeIsGuest && (
									<Button onClick={onSaveForm} isLoading={loading} startEnhancer={<FontAwesomeIcon icon={["fas", "save"]} />}>
										{!hasScores ? "Submit" : "Update"}
									</Button>
								)}
							</Spaced>
						</Spread>
					)}
				</form>

				<Modal isOpen={showDeleteModal} onClose={() => setShowDeleteModal(false)} closeable>
					<ModalHeader>Delete Judge's Scores & Comment?</ModalHeader>
					<ModalBody>
						<p>Are you sure you want to delete this Judge's scores & comments?</p>
						{deleteError && isAPIError(deletePayload) && <ErrorNotification message={deletePayload.shortMessage} />}
					</ModalBody>
					<ModalFooter>
						<ModalButton kind="secondary" onClick={() => setShowDeleteModal(false)}>
							Cancel
						</ModalButton>
						<ModalButton onClick={onDelete} startEnhancer={<FontAwesomeIcon icon={["fas", "trash-alt"]} />}>
							Delete
						</ModalButton>
					</ModalFooter>
				</Modal>
			</Card>
		)

	return (
		<ItemList
			{...props}
			hideActions={!event?.is_current}
			type={ObjectType.TeamAssessment}
			columns={[
				{
					name: "Judge",
					resolver: (value: TeamAssessment) => (
						<div>
							<span>{value.judgeEmail}</span>
							{value.judgeIsGuest && <span className={guestJudgeStyle}>{"(guest)"}</span>}
						</div>
					),
				},
				{
					name: "Total Score",
					resolver: (value: TeamAssessment) =>
						value.totalScore === 0
							? value.hasScores
								? "0"
								: "N/A"
							: Math.round((event?.score_type === ScoreType.Decimal ? value.totalScore / 10 : value.totalScore) * 10) / 10,
				},
				{
					name: "Comment",
					resolver: (value: TeamAssessment) => (value.comment.length < 50 ? value.comment : value.comment.substr(0, 47) + "..."),
				},
			]}
			onCreate={null}
			onRowClick={(value: TeamAssessment) => {
				if (!event) return
				setJudgeID(value.judgeID)
				setJudgeIsGuest(value.judgeIsGuest)
				if (event.score_type === ScoreType.Decimal) {
					// If event uses decimal point scores - divide scores by 10 (server stores scores as intergers)
					value.scores = value.scores.map((s) => ({ categoryID: s.categoryID, score: s.score / 10 } as TeamScore))
				}
				reset({ ...value })
			}}
			hideArchiveToggle
		/>
	)
}
