import { faUpload } from "@fortawesome/pro-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { useStyletron } from "baseui"
import { Button } from "baseui/button"
import { Checkbox } from "baseui/checkbox"
import { FormControl } from "baseui/form-control"
import { Input } from "baseui/input"
import { Modal, ModalBody, ModalButton, ModalFooter, ModalHeader } from "baseui/modal"
import { Select } from "baseui/select"
import { Slider } from "baseui/slider"
import { H1, LabelMedium } from "baseui/typography"
import * as React from "react"
import { useMutation, useQuery } from "react-fetching-library"
import { Controller, useForm } from "react-hook-form"
import { RouteComponentProps, useHistory } from "react-router-dom"
import {
	NumberOnDashboardNames,
	NumberOnDashboardType,
	QuestionInputType,
	QuestionInputTypeNames,
	SliderScaleOptionNames,
	SliderScaleOptionType,
} from "../../api/enums"
import { APIError, isAPIError } from "../../api/types"
import { ErrorNotification } from "../../components/common"
import { Loading } from "../../components/loading"
import { Spaced } from "../../components/spaced"
import { Spread } from "../../components/spread"
import { CommonContainer } from "../../lib/controller"
import { createQuestion, deleteQuestion, getQuestion, pushQuestion, Question, QuestionUpdateRequest, updateQuestion } from "../../lib/questions"
import { NumberSliderOverrides } from "../../themeOverrides"

const required = { required: { value: true, message: "Required" } }

const inputTypeOptions = QuestionInputTypeNames.map((s, i) => ({
	id: i as QuestionInputType,
	label: s,
}))
const sliderScaleOptions = SliderScaleOptionNames.map((s, i) => ({
	id: i as SliderScaleOptionType,
	label: s,
}))
const numberOnDashboardOptions = NumberOnDashboardNames.map((s, i) => ({
	id: i as NumberOnDashboardType,
	label: s,
}))

interface detailsLabel {
	label: string
	value: any
}

export const QuestionPage = (props: RouteComponentProps<{ id: string }>) => {
	const id = props.match.params.id
	const isNew = id === "create"

	const { event, eventID } = CommonContainer.useContainer()
	const history = useHistory()
	const [details, setDetails] = React.useState<detailsLabel[]>([])

	// Get Question
	const [question, setQuestion] = React.useState<Question>()
	const { error, payload, loading, query: refresh } = useQuery<Question[]>(getQuestion(eventID, id), !isNew)

	React.useEffect(() => {
		if (!payload || payload.length === 0) return
		setQuestion(payload[0])
	}, [payload, loading, error])

	// Edit Mode
	const [editMode, _setEditMode] = React.useState(isNew)
	const setEditMode = (value: boolean) => {
		_setEditMode(value)
		history.replace({ search: value ? "edit=true" : undefined })
	}

	const onEditComplete = () => {
		history.push("/questions")
		setEditMode(false)
	}

	// Archiving
	const { mutate, loading: mutating } = useMutation(updateQuestion)
	const onArchive = () =>
		mutate({
			...question,
			eventID: eventID,
			categoryID: id,
			archived: true,
			name: question?.name,
		}).then(() => refresh())
	const onUnarchive = () =>
		mutate({
			...question,
			eventID: eventID,
			categoryID: id,
			archived: false,
			name: question?.name,
		}).then(() => refresh())

	// Deleting
	const { mutate: mutateDelete, loading: deleting, error: deleteError, payload: deletePayload } = useMutation(deleteQuestion(eventID))
	const [showDeleteModal, setShowDeleteModal] = React.useState(false)

	// Pushing
	const { mutate: mutatePush, loading: pushing, error: pushError, payload: pushPayload } = useMutation(pushQuestion(eventID))
	const [showPushModal, setShowPushModal] = React.useState(false)

	// Styling
	const [css, theme] = useStyletron()
	const containerStyle = css({
		maxWidth: "900px",
		margin: "auto",
		marginTop: "10px",
	})
	const nameStyle = css({
		...theme.typography.HeadingSmall,
	})
	const detailStyle = css({
		padding: "10px",
		display: "grid",
		gridTemplateColumns: "150px auto",
	})
	const detailAltStyle = css({
		padding: "10px",
		display: "grid",
		gridTemplateColumns: "150px auto",
		backgroundColor: theme.colors.backgroundSecondary,
		borderRadius: "4px",
	})
	const headerStyle = css({
		display: "none",
		"@media only screen and (max-width: 700px)": {
			display: "flex",
		},
	})

	// Set details depending on type of question
	React.useEffect(() => {
		if (!question) return
		let details: detailsLabel[] = [
			{ label: "Question", value: question.name },
			{ label: "Type", value: QuestionInputTypeNames[question.type] },
		]
		// Multi choice
		if (question.type === 0) {
			const choices = question.sub_options.split("~")
			details.push({ label: "Allow Write-in Answers", value: question.option === 0 ? "No" : "Yes" })
			details.push({ label: "Number of Choices", value: choices.length - 1 })
			choices.forEach((e, i) => {
				if (i + 1 === choices.length) return
				details.push({ label: `Choice ${i + 1}`, value: choices[i] })
			})
		}
		// Slider
		if (question.type === 1) {
			details.push({
				label: "Slider Scale",
				value: SliderScaleOptionNames[question.option],
			})
			details.push({
				label: "Number on Dashboard",
				value: NumberOnDashboardNames[decodeSubOption(question.sub_options)],
			})
		}
		// Text
		if (question.type === 2) {
			details.push({
				label: "Character Limit",
				value: question.option,
			})
			details.push({ label: "Hint", value: question.sub_options })
		}
		setDetails(details)
	}, [question, setDetails])

	// subOptions for slider types are stored in database like this `json`{"overallNumber":3}`json`
	// this returns the value of overallNumber
	const decodeSubOption = (str: string): number => {
		const strArr = str.split(`"overallNumber":`)
		try {
			return parseInt(strArr[1][0])
		} catch {
			return 0
		}
	}

	// Edit Mode?
	if (editMode)
		return (
			<div className={containerStyle}>
				<div className={headerStyle}>
					<H1>{event ? event.id : "Questions"}</H1>
				</div>
				<div className={nameStyle}>{"New Question"}</div>
				<QuestionEdit question={question} isNew={isNew} stopEditMode={onEditComplete} decodeSubOption={decodeSubOption} />
			</div>
		)

	// Loading
	if (!question && loading) return <Loading />
	if (!question) return <></>

	return (
		<div className={containerStyle}>
			<Spread>
				<div />

				{/* Actions */}
				{event?.is_current && (
					<Spaced>
						{!question.archived && (
							<Button onClick={() => setShowPushModal(true)} startEnhancer={<FontAwesomeIcon icon={faUpload} />}>
								Push Question
							</Button>
						)}
						{!question.archived && (
							<Button onClick={() => setEditMode(true)} startEnhancer={<FontAwesomeIcon icon={["fas", "edit"]} />}>
								Edit
							</Button>
						)}
						{!question.archived && (
							<Button onClick={onArchive} isLoading={mutating} startEnhancer={<FontAwesomeIcon icon={["fas", "archive"]} />}>
								Archive
							</Button>
						)}
						{question.archived && (
							<Button onClick={onUnarchive} isLoading={mutating} startEnhancer={<FontAwesomeIcon icon={["fas", "undo"]} />}>
								Unarchive
							</Button>
						)}
						{question.archived && (
							<Button onClick={() => setShowDeleteModal(true)} isLoading={mutating} startEnhancer={<FontAwesomeIcon icon={["fas", "trash-alt"]} />}>
								Delete
							</Button>
						)}
					</Spaced>
				)}
			</Spread>

			<div>
				{details.map((detail, index) => {
					return (
						<div key={`question-${detail.label}`} className={index % 2 === 0 ? detailAltStyle : detailStyle}>
							<LabelMedium $style={{ fontWeight: 600 }}>{`${detail.label}:`}</LabelMedium>
							<div>{detail.value}</div>
						</div>
					)
				})}
			</div>

			{/* Delete Modal */}
			<Modal onClose={() => setShowDeleteModal(false)} isOpen={showDeleteModal}>
				<ModalHeader>Delete Question?</ModalHeader>
				<ModalBody>
					<p>Are you sure you want to delete this question?</p>
					{deleteError && isAPIError(deletePayload) && <ErrorNotification message={deletePayload.shortMessage} />}
				</ModalBody>
				<ModalFooter>
					<ModalButton onClick={() => setShowDeleteModal(false)} kind="secondary">
						Cancel
					</ModalButton>
					<ModalButton
						onClick={async () => {
							const resp = await mutateDelete(id)
							if (!resp.error) history.push("/questions")
						}}
						isLoading={deleting}
						startEnhancer={<FontAwesomeIcon icon={["fas", "trash-alt"]} />}
					>
						Delete
					</ModalButton>
				</ModalFooter>
			</Modal>

			{/* Push Modal */}
			<Modal onClose={() => setShowPushModal(false)} isOpen={showPushModal}>
				<ModalHeader>Push Question?</ModalHeader>
				<ModalBody>
					<p>Are you sure you want to push this question live?</p>
					{pushError && isAPIError(pushPayload) && <ErrorNotification message={deletePayload.shortMessage} />}
				</ModalBody>
				<ModalFooter>
					<ModalButton onClick={() => setShowPushModal(false)} kind="secondary">
						Cancel
					</ModalButton>
					<ModalButton
						onClick={async () => {
							const resp = await mutatePush(id)
							if (!resp.error) history.push("/questions")
						}}
						isLoading={pushing}
						startEnhancer={<FontAwesomeIcon icon={faUpload} />}
					>
						Push
					</ModalButton>
				</ModalFooter>
			</Modal>
		</div>
	)
}

interface QuestionInput {
	name?: string // Question
	type?: number
	options: number // ? seems to be 1 for Allow write in answers, 255 for text questions
	sub_options?: string
	sub_options_index?: number
	choices?: string[]
}

const QuestionEdit = (props: {
	question?: Question
	isNew: boolean
	stopEditMode: (question?: Question) => void
	decodeSubOption: (str: string) => number
}) => {
	const { question, isNew, stopEditMode, decodeSubOption } = props
	const { eventID } = CommonContainer.useContainer()
	const [noOfChoices, setNoOfChoices] = React.useState(4)
	const [questionType, setQuestionType] = React.useState<number>()
	// Setup form
	const { control, register, handleSubmit, setValue, trigger, errors } = useForm<QuestionInput>()
	const { mutate: update, loading, error, payload } = useMutation(isNew ? createQuestion : updateQuestion)

	const createSubOptions = (input: QuestionInput): string => {
		// If multiple choice add choices into sub option field
		if (input.type === 0 && input.choices) {
			const choiceStr = input.choices.join("~")
			return choiceStr + "~" // added to match input from flutter app
		}
		// If Slider set the index of the slider scale to suboptions
		if (input.type === 1) {
			if (input.sub_options_index || input.sub_options_index === 0) {
				const subOptObj = { overallNumber: input.sub_options_index }
				return "`json`" + JSON.stringify(subOptObj) + "`json`" // to match input from flutter app
			}
			return "0"
		}
		if (input.type === 2 && input.sub_options) {
			return input.sub_options
		}
		return ""
	}

	const createOptions = (input: QuestionInput): number => {
		let options = 0
		// If multiple choice add choices into sub option field
		if (input.type === 0 && input.options) {
			options = input.options ? 1 : 0
		}
		if (input.type === 1) {
			options = input.options
		}
		if (input.type === 2) {
			options = input.options
		}
		return options
	}

	const onSaveForm = handleSubmit(async (input) => {
		if (!trigger()) return
		// If new question
		if (!question) {
			const updateRequest: QuestionUpdateRequest = { ...input, eventID: eventID }
			updateRequest.sub_options = createSubOptions(input)
			updateRequest.option = createOptions(input)
			const resp = await update(updateRequest)

			// Error?
			if ((error && isAPIError(resp.payload)) || (resp.payload as APIError).error) return
			const updatedQuestion = resp.payload as Question
			// On Success
			if (updatedQuestion) stopEditMode()
		}
		// If editing question
		if (question) {
			const updateRequest: QuestionUpdateRequest = {
				...input,
				eventID: eventID,
				categoryID: question.id,
			}
			updateRequest.sub_options = createSubOptions(input)
			updateRequest.option = createOptions(input)
			const resp = await update(updateRequest)
			// Error?
			if ((error && isAPIError(resp.payload)) || (resp.payload as APIError).error) return
			const updatedQuestion = resp.payload as Question
			// On Success
			if (updatedQuestion) stopEditMode()
		}
	})

	// Load defaults
	React.useEffect(() => {
		if (!question) return
		setValue("name", question.name)
		setValue("type", question.type)
		if (question.type === 0) {
			const choiceArr = question.sub_options.split("~")
			setValue("options", question.option)
			setValue("choices", choiceArr)
			setNoOfChoices(choiceArr.length - 1)
			setQuestionType(question.type)
			return
		}
		if (question.type === 1) {
			setQuestionType(question.type)
			setValue("options", question.option)
			setValue("sub_options_index", decodeSubOption(question.sub_options))
			return
		}
		if (question.type === 2) {
			setQuestionType(question.type)
			setValue("options", question.option)
			setValue("sub_options", question.sub_options)
			return
		}
	}, [question, questionType, setValue, decodeSubOption])

	// Styling
	const [css] = useStyletron()
	const containerStyle = css({
		padding: "10px",
	})
	const footerStyle = css({
		display: "flex",
		flexDirection: "row-reverse",
	})

	const choices: JSX.Element[] = []
	for (let i = 0; i < noOfChoices; i++) {
		choices.push(
			<FormControl key={i} label={`Choice ${i + 1}`} error={errors.name?.message} positive="">
				<Input name={`choices[${i}]`} inputRef={register({ required: "Choice is required" })} disabled={loading} />
			</FormControl>,
		)
	}

	return (
		<form
			onSubmit={(e) => {
				e.preventDefault()
				onSaveForm()
			}}
			className={containerStyle}
		>
			{error && isAPIError(payload) && <ErrorNotification message={payload.shortMessage} />}

			<FormControl label="Question" error={errors.name?.message} positive="">
				<Input name="name" inputRef={register({ required: "Question is required" })} disabled={loading} />
			</FormControl>

			<FormControl label="Type of Question" error={errors.type?.message} positive="">
				<Controller
					name="type"
					control={control}
					rules={required}
					onChange={([{ value }]: any) => value}
					render={({ onChange, value }) => {
						const v = inputTypeOptions.find((i) => i.id === value)
						return (
							<Select
								options={inputTypeOptions}
								onChange={(e) => {
									e.value[0] && onChange(e.value[0].id)
									setQuestionType(e.value[0].id as number)
								}}
								clearable={false}
								escapeClearsValue={false}
								deleteRemoves={false}
								searchable={false}
								value={v ? [v] : []}
								error={errors.type !== undefined}
								placeholder="Select a question type"
							/>
						)
					}}
				/>
			</FormControl>

			{questionType === 0 && (
				<FormControl label="Allow Write-in Answers" error={errors.options?.message} positive="">
					<Controller
						name="options"
						control={control}
						defaultValue={false}
						render={({ onChange, value }) => (
							<Checkbox
								checked={value}
								onChange={(e) => {
									const target = e.target as HTMLInputElement
									onChange(target.checked)
								}}
							/>
						)}
					/>
				</FormControl>
			)}
			{questionType === 0 && (
				<FormControl label="Number of Choices" error={errors.sub_options?.message} positive="">
					<Controller
						name="sub_options"
						control={control}
						onChange={([{ value }]: any) => value}
						defaultValue={noOfChoices}
						render={({ onChange, value }) => (
							<Slider
								value={[value]}
								onChange={(e) => {
									onChange(e.value[0])
									setNoOfChoices(e.value[0])
								}}
								min={2}
								max={8}
								overrides={NumberSliderOverrides}
							/>
						)}
					/>
				</FormControl>
			)}
			{questionType === 0 && choices}

			{questionType === 1 && (
				<FormControl label="Slider Scale" error={errors.options?.message} positive="">
					<Controller
						name="options"
						control={control}
						rules={required}
						onChange={([{ value }]: any) => value}
						render={({ onChange, value }) => {
							const v = sliderScaleOptions.find((i) => i.id === value)
							return (
								<Select
									options={sliderScaleOptions}
									onChange={(e) => {
										e.value[0] && onChange(e.value[0].id)
									}}
									clearable={false}
									escapeClearsValue={false}
									deleteRemoves={false}
									searchable={false}
									value={v ? [v] : []}
									error={errors.options !== undefined}
									placeholder="Select a scale"
								/>
							)
						}}
					/>
				</FormControl>
			)}

			{questionType === 1 && (
				<FormControl label="Number on Dashboard" error={errors.sub_options_index?.message} positive="">
					<Controller
						name="sub_options_index"
						control={control}
						rules={required}
						onChange={([{ value }]: any) => value}
						render={({ onChange, value }) => {
							const v = numberOnDashboardOptions.find((i) => i.id === value)
							return (
								<Select
									options={numberOnDashboardOptions}
									onChange={(e) => {
										e.value[0] && onChange(e.value[0].id)
									}}
									clearable={false}
									escapeClearsValue={false}
									deleteRemoves={false}
									searchable={false}
									value={v ? [v] : []}
									error={errors.sub_options_index !== undefined}
									placeholder="Select a question type"
								/>
							)
						}}
					/>
				</FormControl>
			)}
			{questionType === 2 && (
				<FormControl label="Charater Limit" error={errors.sub_options?.message} positive="">
					<Controller
						name="options"
						control={control}
						onChange={([{ value }]: any) => value}
						defaultValue={225}
						render={({ onChange, value }) => (
							<Slider
								value={[value]}
								onChange={(e) => {
									onChange(e.value[0])
									setNoOfChoices(e.value[0])
								}}
								min={2}
								max={1000}
							/>
						)}
					/>
				</FormControl>
			)}

			{questionType === 2 && (
				<FormControl label="Hint (Optional)" error={errors.name?.message} positive="">
					<Input name="sub_options" inputRef={register()} disabled={loading} />
				</FormControl>
			)}
			<div className={footerStyle}>
				<Spaced>
					<Button type="button" onClick={() => stopEditMode()} kind="secondary">
						Cancel
					</Button>
					<Button
						onClick={(e) => {
							e.preventDefault()
							onSaveForm()
						}}
						isLoading={loading}
						startEnhancer={<FontAwesomeIcon icon={["fas", "save"]} />}
					>
						Save
					</Button>
				</Spaced>
			</div>
		</form>
	)
}
