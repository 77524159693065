import { Action } from "react-fetching-library"

export interface SubscriptionStatus {
	status: string
	accountType: number
	periodEndDate: number
	cancelDate: number
	endedDate: number
	payService: string
	paymentEmail: string
}

export interface StripeProduct {
	price: number
	product_id: string
}

export interface StripePlanPrices {
	new_professional: StripeProduct
	one_boost: StripeProduct
	two_boosts: StripeProduct
	four_boosts: StripeProduct
}

export interface RevenueCatSubscriber {
	original_app_user_id: string
	original_application_version?: string
	first_seen: string
	last_seen: string
	entitlements?: RevenueCatEntitlements
}

export interface RevenueCatEntitlements {
	[key: string]: RevenueCatEntitlement
}

export interface RevenueCatEntitlement {
	expires_date?: string
	purchase_date: string
	product_identifier: string
}

export interface CheckoutSessionRequest {
	successURL: string
	cancelURL: string
	productID: string
}

export const GetStripePrices: Action<StripePlanPrices> = {
	method: "GET",
	credentials: "include",
	endpoint: `/stripe-prices`,
	responseType: "json",
}

export const GetBillingPortalURL = (returnURL: string): Action<string> => ({
	method: "GET",
	credentials: "include",
	endpoint: `/auth/user/stripe_portal_session?returnURL=${returnURL}`,
	responseType: "json",
})

export const GetCheckoutSessionID = (values: CheckoutSessionRequest): Action<string> => ({
	method: "POST",
	credentials: "include",
	endpoint: `/auth/user/stripe_checkout_session`,
	body: {
		params: {
			...values,
		},
	},
	responseType: "json",
})

export const GetSubscriptionStatus: Action<RevenueCatSubscriber> = {
	method: "GET",
	credentials: "include",
	endpoint: "/auth/user/subscription_status",
	responseType: "json",
}
