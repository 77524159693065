export const isUUID = (uuid: string) => uuid.match("^[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12}$") !== null

export const generateShortcode = (name: string) => {
	let generatedShortname = ""
	const nameArry = name.split(" ")
	nameArry.forEach((s) => {
		if (parseInt(s, 10)) {
			if (s[0] === "0") generatedShortname += s[0]
			generatedShortname += parseInt(s, 10)
			return
		}
		if (s.length === 0) return
		if (s[0] === "0") {
			s.split("").forEach((c) => {
				if (c === "0") {
					generatedShortname += c
				}
			})
			return
		}
		generatedShortname += s[0].toUpperCase()
	})

	return generatedShortname
}

export const capitalizeFirstLetter = (str: string): string => {
	return str.charAt(0).toUpperCase() + str.slice(1)
}
