import * as React from "react"
import { Notification } from "baseui/notification"
import { useStyletron } from "baseui"

export const ErrorNotification = (props: { message: string; closeable?: boolean }) => {
	const { message, closeable } = props
	return (
		<Notification
			closeable={closeable}
			kind="negative"
			overrides={{
				Body: {
					style: {
						width: "100%",
						boxSizing: "border-box",
					},
				},
			}}
		>
			{message}
		</Notification>
	)
}

export const InlineLabel = (props: { label: string; caption: string }) => {
	const [css] = useStyletron()
	const captionStyle = css({
		color: "#545454",
		fontSize: "12px",
		lineHeight: "20px",
		marginLeft: "20px",
	})
	return (
		<div>
			<span>{props.label}</span>
			<span className={captionStyle}>{props.caption}</span>
		</div>
	)
}
