import { Action } from "react-fetching-library"
import { EventType, ScoreType } from "../api/enums"
import { APIError, LeaderboardResponse, User } from "../api/types"
import { User as EventOwner } from "./superAdmin"

export interface Event {
	id: string // UUID
	shortcode: string
	name: string
	logo: string
	logo_content_type: string
	archived: boolean
	judge_limit: number
	team_limit: number
	category_limit: number
	opinion_category_limit: number
	audience_participation_limit: number
	judge_type: EventType
	score_type: ScoreType
	word_blacklist: string
	team_display_name: string
	public_leaderboard: boolean
	public_leaderboard_limit: number
	public_leaderboard_combine: boolean
	official_weight: number
	guest_weight: number
	comment_section_caption: string
	owner_user_id: string
	min_possible_score: number
	avg_overall_scores: boolean
	has_user_management: boolean
	unlocked: boolean
	displayAds: boolean
	original_event_id: string
	iteration_number: number
	is_current: boolean
	is_custom: boolean
	created_at: string
	owner_user?: EventOwner
	unlocked_by?: Judge
}

export interface Judge {
	email: string
}

export interface EventUpdateRequest {
	id?: string
	name?: string
	newname?: string
	newshortcode?: string
	teamdisplayname?: string
	publicleaderboard?: boolean
	publicLeaderboardLimit?: number
	judgetype?: number
	scoretype?: number
	archived?: boolean
	logo?: string
	copyFromID?: string
	commentSectionCaption?: string
	ownerUserID?: string
}

export interface EventDeleteRequest {
	id: string
}

export interface EventUnlockRequest {
	id: string
}

export interface EventReopenRequest {
	id: string
}

export const createEvent = (values: EventUpdateRequest): Action<Event | APIError> => {
	return {
		method: "POST",
		endpoint: "/admin/events/add",
		credentials: "include",
		body: { params: values },
		responseType: "json",
	}
}
export const updateEvent = (values: EventUpdateRequest): Action<Event | APIError> => {
	return {
		method: "POST",
		endpoint: "/admin/events/update",
		credentials: "include",
		body: { params: values },
		responseType: "json",
	}
}
export const deleteEvent = (values: EventDeleteRequest): Action<APIError> => {
	return {
		method: "POST",
		endpoint: "/admin/events/delete",
		credentials: "include",
		body: { params: values },
		responseType: "json",
	}
}
export const unlockEvent = (values: EventUnlockRequest): Action<APIError> => {
	return {
		method: "POST",
		endpoint: "/admin/events/unlock",
		credentials: "include",
		body: { params: values },
		responseType: "json",
	}
}
export const reopenEvent = (values: EventReopenRequest): Action<Event> => {
	return {
		method: "POST",
		endpoint: "/admin/events/reopen",
		credentials: "include",
		body: { params: values },
		responseType: "json",
	}
}

export const getEventHistory = (eventID: string): Action<Event[]> => ({
	method: "GET",
	credentials: "include",
	endpoint: `/admin/events/history?id=${eventID}`,
	responseType: "json",
})

export const getEvents = (eventID?: string): Action<Event[]> => ({
	method: "GET",
	credentials: "include",
	endpoint: `/admin/events${eventID ? `?id=${eventID}` : ""}`,
	responseType: "json",
})

export const checkEventShortcode = (shortcode: string): Action<{ payload: Event }> => ({
	method: "POST",
	credentials: "include",
	endpoint: "/event/check/shortcode",
	body: { params: { shortcode: shortcode } },
	responseType: "json",
})

export const checkEventID = (eventID: string): Action<{ payload: Event }> => ({
	method: "POST",
	credentials: "include",
	endpoint: "/event/check/id",
	body: { params: { id: eventID } },
	responseType: "json",
})

export const exportScores = (eventID: string): Action<Blob | APIError> => {
	return {
		method: "GET",
		credentials: "include",
		endpoint: `/admin/events/export_scores?id=${eventID}`,
		responseType: "blob",
	}
}

export const exportNotes = (eventID: string): Action<Blob | APIError> => {
	return {
		method: "GET",
		credentials: "include",
		endpoint: `/admin/events/export_notes?id=${eventID}`,
		responseType: "blob",
	}
}

export const getLeaderboard = (eventID: string, publicLeaderboard: boolean): Action<LeaderboardResponse | APIError> => ({
	method: "GET",
	credentials: "include",
	endpoint: `/leaderboard${publicLeaderboard ? "public" : "admin"}?event_id=${eventID}`,
	responseType: "json",
})

export const getEventUsers = (eventID: string): Action<User[] | APIError> => ({
	method: "GET",
	credentials: "include",
	endpoint: `/admin/events/users?eventID=${eventID}`,
	responseType: "json",
})

export const addEventUser = (values: { eventID: string; userEmail: string }): Action<User[] | APIError> => ({
	method: "POST",
	credentials: "include",
	endpoint: `/admin/events/users/add?eventID=${values.eventID}&userEmail=${values.userEmail}`,
	responseType: "json",
})

export const removeEventUser = (values: { eventID: string; userID: string }): Action<User[] | APIError> => ({
	method: "POST",
	credentials: "include",
	endpoint: `/admin/events/users/remove?eventID=${values.eventID}&userID=${values.userID}`,
	responseType: "json",
})
