import { useStripe } from "@stripe/react-stripe-js"
import { styled } from "baseui"
import { Block } from "baseui/block"
import { Button } from "baseui/button"
import { Spinner } from "baseui/spinner"
import React from "react"
import { useMutation, useQuery } from "react-fetching-library"
import OneBoostSVG from "../../assets/images/1 boost.svg"
import TwoBoostsSVG from "../../assets/images/2 boost.svg"
import FourBoostsSVG from "../../assets/images/4 boost.svg"
import BoltBackgroundSVG from "../../assets/images/bolt background.svg"
import BoostBoltSVG from "../../assets/images/boost bolt.svg"
import { GetCheckoutSessionID, GetStripePrices, StripeProduct } from "../../lib/stripe"
import { BoltBackground, BoostBanner, BoostBorderContainer, CenteredParagraph, generateBoostBackgroundStyle, Spacer } from "./upgrade"

interface BoostsProps {}

export const Boosts: React.FC<BoostsProps> = () => {
	const { payload: prices } = useQuery(GetStripePrices)
	const { mutate } = useMutation(GetCheckoutSessionID)
	const stripe = useStripe()
	const [isCheckoutLoading, setIsCheckoutLoading] = React.useState(false)

	const onPurchaseBoost = async (product: StripeProduct) => {
		setIsCheckoutLoading(true)
		try {
			if (!stripe) throw new Error("Failed to get Stripe client")
			// Get checkout session
			const resp = await mutate({
				successURL: `${window.location.href.split("?")[0]}?success=true`,
				cancelURL: window.location.href.split("?")[0],
				productID: product.product_id,
			})

			if (resp.error || !resp.payload) throw new Error("Failed to get Stripe checkout session ID")
			// Redirect to checkout
			await stripe.redirectToCheckout({
				sessionId: resp.payload,
			})
		} catch (error) {
			// display friendly message to user
		} finally {
			setIsCheckoutLoading(false)
		}
	}

	const Container = styled("div", {
		display: "flex",
		height: "100%",
		flexDirection: "column",
		alignItems: "center",
		justifyContent: "center",
	})
	const BoostsWrap = styled("div", {
		display: "flex",
		width: "100%",
		justifyContent: "center",
	})

	if (!prices || isCheckoutLoading) {
		return (
			<Container>
				<Spinner />
			</Container>
		)
	}

	const { one_boost, two_boosts, four_boosts } = prices

	return (
		<Container>
			<BoostBanner />
			<Spacer />
			<CenteredParagraph>Boosts can be used to unlock events, removing all limits and ads for that event</CenteredParagraph>
			<Spacer />
			<BoostsWrap>
				<BoostButton boostData={one_boost} boostTitleSrc={OneBoostSVG} onPurchaseBoost={onPurchaseBoost} />
				<Spacer />
				<BoostButton boostData={two_boosts} boostTitleSrc={TwoBoostsSVG} onPurchaseBoost={onPurchaseBoost} />
				<Spacer />
				<BoostButton boostData={four_boosts} boostTitleSrc={FourBoostsSVG} onPurchaseBoost={onPurchaseBoost} isHighlighted />
			</BoostsWrap>
		</Container>
	)
}

interface BoostButtonProps {
	isHighlighted?: boolean
	boostData: StripeProduct
	boostTitleSrc: string
	onPurchaseBoost: (b: StripeProduct) => Promise<void>
}

export const BoostButton: React.FC<BoostButtonProps> = ({ boostData, isHighlighted, onPurchaseBoost, boostTitleSrc }) => {
	const BoostTitle = styled("img", {
		maxWidth: "100%",
	})
	const BoostBolt = styled("img", {
		maxWidth: "100%",
	})
	const BoostPrice = styled("p", {
		margin: 0,
		marginTop: "1rem",
		fontFamily: "rock_shieldregular, sans-serif",
		fontWeight: "bolder",
		fontSize: "1.6rem",
		letterSpacing: isHighlighted ? "2px" : "1px",
		color: isHighlighted ? "white" : "black",
		textShadow: isHighlighted ? "-1px 0 #c49edf, 0 1px #c49edf, 1px 0 #c49edf, 0 -1px #c49edf" : "none",
	})
	const BestValue = styled("p", {
		marginTop: "-.5rem",
		fontWeight: "bold",
		color: "white",
		textShadow: "-1px 0 #c49edf, 0 1px #c49edf, 1px 0 #c49edf, 0 -1px #c49edf",
	})

	return (
		<BoostBorderContainer width="200px" height="200px">
			<Button
				onClick={() => onPurchaseBoost(boostData)}
				overrides={{
					BaseButton: {
						style: isHighlighted
							? generateBoostBackgroundStyle()
							: {
									...generateBoostBackgroundStyle(),
									backgroundImage: "none",
									backgroundColor: "white",
									":hover": {
										backgroundColor: "white",
									},
							  },
					},
				}}
			>
				<Block display="flex" flexDirection="column" alignItems="center" justifyContent="center">
					{isHighlighted && <BestValue>Best Value!</BestValue>}
					<BoostTitle src={boostTitleSrc} />
					<BoostBolt src={BoostBoltSVG} />
					<BoostPrice>${boostData.price / 100}</BoostPrice>
					{isHighlighted && <BoltBackground src={BoltBackgroundSVG} />}
				</Block>
			</Button>
		</BoostBorderContainer>
	)
}
