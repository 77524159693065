import { TeamPage } from "./management/team"
import { JudgePage } from "./management/judge"
import { CategoryPage } from "./management/category"
import { QuestionPage } from "./management/question"
import { GroupPage } from "./management/group"

export const ViewPage = {
	Group: GroupPage,
	Team: TeamPage,
	Judge: JudgePage,
	Category: CategoryPage,
	Question: QuestionPage,
}
