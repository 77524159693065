import { Action } from "react-fetching-library"
import { APIError } from "../api/types"
import { Group } from "./groups"
import { Team } from "./teams"

export interface Judge {
	id: string
	is_guest: boolean
	event_id: string
	pin: string
	email: string
	archived: boolean
	assigned_teams: Team[]
	assigned_groups: Group[]
}

export interface JudgeCreateRequest {
	email?: string
	pin?: string
	guest?: boolean
	archived?: boolean
	assignedTeamIDs?: string[]
	assignedGroupIDs?: string[]
}

export interface JudgeUpdateRequest {
	id: string
	email?: string
	pin?: string
	guest?: boolean
	archived?: boolean
	assignedTeamIDs?: string[]
	assignedGroupIDs?: string[]
}

export const createJudge = (eventID: string) => (values: JudgeCreateRequest): Action<Judge | APIError> => {
	return {
		method: "POST",
		endpoint: `/admin/events/${eventID}/judges/add`,
		credentials: "include",
		body: { params: values },
		responseType: "json",
	}
}

export const updateJudge = (eventID: string) => (values: JudgeUpdateRequest): Action<Judge | APIError> => {
	return {
		method: "POST",
		endpoint: `/admin/events/${eventID}/judges/update`,
		credentials: "include",
		body: { params: values },
		responseType: "json",
	}
}

export const deleteJudge = (eventID: string) => (judgeID: string): Action<any | APIError> => {
	return {
		method: "DELETE",
		endpoint: `/admin/events/${eventID}/judges/delete?judge_id=${judgeID}`,
		credentials: "include",
		responseType: "json",
	}
}

export const getJudge = (eventID: string, id: string): Action<Judge[] | APIError> => {
	return {
		method: "GET",
		credentials: "include",
		endpoint: `/admin/events/${eventID}/judges?id=${id}`,
		responseType: "json",
	}
}

export const listJudges = (eventID: string): Action<Judge[] | APIError> => {
	return {
		method: "GET",
		credentials: "include",
		endpoint: `/admin/events/${eventID}/judges`,
		responseType: "json",
	}
}

export const exportJudges = (eventID: string): Action<Blob | APIError> => {
	return {
		method: "GET",
		credentials: "include",
		endpoint: `/admin/events/${eventID}/judges/export`,
		responseType: "blob",
	}
}

export const importJudges = (values: { eventID: string; file: Blob }): Action<APIError> => {
	const formData = new FormData()
	formData.set("file", values.file)
	return {
		method: "POST",
		credentials: "include",
		endpoint: `/admin/events/${values.eventID}/judges/import`,
		body: formData,
		responseType: "json",
	}
}

export const generateJudgePin = (eventID: string): Action<{ payload: { pin: string } }> => {
	return {
		method: "GET",
		credentials: "include",
		endpoint: `/admin/events/${eventID}/judges/generate-pin`,
		responseType: "json",
	}
}
