import { faIdCard } from "@fortawesome/pro-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { styled } from "baseui"
import { Block, BlockProps } from "baseui/block"
import { Button } from "baseui/button"
import { Theme } from "baseui/theme"
import React from "react"
import { useHistory } from "react-router-dom"
import { StyleObject } from "styletron-react"
import BoltBackgroundSVG from "../../assets/images/bolt background.svg"
import BoltSVG from "../../assets/images/Bolt white.svg"
import BoostLogoSVG from "../../assets/images/Boost Logo.svg"
import PurchaseBoostsSVG from "../../assets/images/Purchase boosts.svg"

export const Container = styled("div", {
	display: "flex",
	height: "100%",
	flexDirection: "column",
	alignItems: "center",
	justifyContent: "center",
})
export const ButtonWrap = styled("div", {
	display: "flex",
})
export const BoostBorderContainer = styled<
	{
		$direction?: string
	},
	React.FC<BlockProps>,
	Theme
>(Block, (props) => ({
	overflow: "hidden",
	position: "relative",
	borderRadius: "1rem",
	backgroundImage: `linear-gradient(${props.$direction || "to bottom right"}, #e284b3, #7a92d8, #44b6b4)`,
}))
export const Spacer = styled("div", {
	height: "1rem",
	width: "1rem",
})
export const Banner = styled("div", {
	position: "absolute",
	top: "0",
	right: "0",
	width: "150px",
	transformOrigin: "0 0",
	transform: "translate(50%, -130%) rotate(45deg)",
	backgroundColor: "#ff278c",
	textAlign: "center",
	fontWeight: "bold",
	color: "white",
})
export const generateBoostBackgroundStyle = (direction?: string): StyleObject => ({
	display: "block",
	height: "calc(100% - 4px)",
	width: "calc(100% - 4px)",
	transform: "translate(2px, 2px)",
	borderRadius: "1rem !important",
	backgroundImage: `linear-gradient(${direction || "to bottom right"}, #fc95c9, #8ea9f7, #4fd5d2)`,
})
export const BoostHeading = styled("img", {})
export const BoostSubheading = styled("p", {
	textTransform: "uppercase",
	letterSpacing: "2px",
	fontStyle: "italic",
	fontFamily: "rock_shieldregular, sans-serif",
	fontWeight: "bolder",
	textShadow: "-1px 0 #c49edf, 0 1px #c49edf, 1px 0 #c49edf, 0 -1px #c49edf",
	fontSize: "1.3rem",
	color: "white",
})
export const BoostSubHeadingSpacer = styled("img", {
	width: "2rem",
	margin: "0 1rem",
	height: "60px",
})
export const BoltBackground = styled("img", {
	position: "absolute",
	inset: 0,
	objectFit: "cover",
	pointerEvents: "none",
})
export const PurchaseBoosts = styled("img", {
	width: "100%",
	pointerEvents: "none",
})
export const CenteredParagraph = styled("p", {
	textAlign: "center",
})

interface UpgradeProps {}

export const Upgrade: React.FC<UpgradeProps> = () => {
	const history = useHistory()

	return (
		<Container>
			<BoostBanner />
			<Spacer />
			<CenteredParagraph>Supercharge your events! Manage your current subscription, or buy some boosts to unlock individual events</CenteredParagraph>
			<Spacer />
			<ButtonWrap>
				<Button
					overrides={{
						BaseButton: {
							style: {
								height: "200px",
								width: "200px",
							},
						},
					}}
					onClick={() => history.push("/subscription")}
				>
					<div>
						<FontAwesomeIcon icon={faIdCard} size="4x" />
						<p>Manage subscription</p>
					</div>
				</Button>
				<Spacer />
				<BoostBorderContainer width="200px" height="200px">
					<Button
						overrides={{
							BaseButton: {
								style: generateBoostBackgroundStyle(),
							},
						}}
						onClick={() => history.push("/boosts")}
					>
						<Banner>New!</Banner>
						<BoltBackground src={BoltBackgroundSVG} />
						<PurchaseBoosts src={PurchaseBoostsSVG} />
					</Button>
				</BoostBorderContainer>
			</ButtonWrap>
		</Container>
	)
}

export const BoostBanner = () => {
	return (
		<BoostBorderContainer width="100%" maxWidth="1000px" $direction="to right">
			<Block
				overrides={{
					Block: {
						style: generateBoostBackgroundStyle("to right"),
					},
				}}
			>
				<BoltBackground src={BoltBackgroundSVG} />
				<Block display="flex" flexDirection="column" justifyContent="center" alignItems="center" padding="2rem">
					<BoostHeading src={BoostLogoSVG} />
					<Block display="flex" alignItems="center">
						<BoostSubheading>Unlock Events</BoostSubheading>
						<BoostSubHeadingSpacer src={BoltSVG} />
						<BoostSubheading>Unlimited Judges &#38; Participants</BoostSubheading>
						<BoostSubHeadingSpacer src={BoltSVG} />
						<BoostSubheading>No Ads</BoostSubheading>
					</Block>
				</Block>
			</Block>
		</BoostBorderContainer>
	)
}
