export enum EventType {
	Default,
	OfficialOnly,
	GuestOnly,
	AudienceParticipation,
}
export const EventTypeNames = ["Official & Guest Judges", "Official Judges Only", "Guest Judges Only", "Audience Participation"]

export enum AccountType {
	Free,
	Basic,
	Professional,
	NewProfessional,
	Enterprise,
	SuperAdmin = 999,
}

export const getAccountTypeString = (accountType: number) => {
	switch (accountType) {
		case AccountType.Free:
			return "Free"
		case AccountType.Basic:
			return "Basic"
		case AccountType.Professional:
			return "Professional"
		case AccountType.NewProfessional:
			return "New Professional"
		case AccountType.Enterprise:
			return "Enterprise"
		case AccountType.SuperAdmin:
			return "Super Admin"
	}
	return "Unknown"
}

export const TeamDisplayNameOptions = ["Teams", "Bands", "Groups", "Squads", "Other"]

export enum ScoreType {
	Whole,
	Decimal,
}
export const ScoreTypeNames = ["Maximum 10 points rounded to whole numbers (e.g. 5/10)", "Maximum 10 points with decimals (e.g. 5.5/10)"]

export enum CategoryInputType {
	Slider,
	TextField,
}
export const CategoryInputTypeNames = ["Slider", "Text Field"]

export enum QuestionInputType {
	MultipleChoice,
	Slider,
	Text,
}
export const QuestionInputTypeNames = ["Multiple Choice", "Slider", "Text"]

export enum SliderScaleOptionType {
	OneToTen,
	NeedsWorkToPerfect,
	AppealingToMasterpiece,
	LeastToMost,
}

export const SliderScaleOptionNames = ["1-10", "Needs work - Perfect", "Appealing - Masterpiece", "Least - Most"]

export enum NumberOnDashboardType {
	None,
	Total,
	Average,
	TotalPlusAverage,
}

export const NumberOnDashboardNames = ["None", "Total", "Average", "Total + Average"]

export enum ObjectType {
	Event = "event",
	Team = "team",
	Judge = "judge",
	Category = "category",
	TeamAssessment = "score",
	Question = "question",
	Group = "group",
	GroupTeamList = "group_teams",
	GroupJudgeList = "group_judges",
}
