import * as React from "react"
import { useStyletron } from "baseui"
import { Spinner } from "baseui/spinner"
import { useMutation, useQuery } from "react-fetching-library"
import { addEventUser, getEventUsers, removeEventUser } from "../../lib/events"
import { ErrorNotification } from "../../components/common"
import { isAPIError, User } from "../../api/types"
import { Button } from "baseui/button"
import { Spaced } from "../../components/spaced"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { Input } from "baseui/input"
import { FormControl } from "baseui/form-control"
import { CommonContainer } from "../../lib/controller"
import { H1 } from "baseui/typography"
import { Loading } from "../../components/loading"
import { useForm } from "react-hook-form"
import { AccountType } from "../../api/enums"
import { useHistory } from "react-router-dom"
import { Modal, ModalBody, ModalButton, ModalFooter, ModalHeader } from "baseui/modal"

export const EventUsers = () => {
	const { event, user: me } = CommonContainer.useContainer()
	const history = useHistory()
	const [userToRemove, setUserToRemove] = React.useState<string | undefined>()

	const canManageEventUsers =
		!!me &&
		!!event &&
		(me.accountType === AccountType.Professional || me.accountType === AccountType.NewProfessional || event.unlocked) &&
		event.owner_user_id === me.id

	const [users, setUsers] = React.useState<User[]>([])
	const { error, errorObject, payload, loading, query } = useQuery<User[]>(getEventUsers(event?.id || ""), !!event)
	const { mutate: addUser, loading: addingUser, error: addUserError, payload: addUserPayload } = useMutation<User[]>(addEventUser)
	const { mutate: removeUser, loading: removingUser, error: removeUserError, payload: removeUserPayload } = useMutation<User[]>(removeEventUser)

	const { register, handleSubmit, trigger, errors, setValue } = useForm<{ email: string }>()
	const [errorMessage, setErrorMessage] = React.useState<string>()

	React.useEffect(() => {
		if (!!event) query()
	}, [event, query])
	React.useEffect(() => {
		if (!payload) return
		setUsers(payload)
	}, [payload])

	React.useEffect(() => {
		if (error) setErrorMessage(isAPIError(payload) ? payload.shortMessage : `${errorObject}`)
		else if (addUserError) setErrorMessage(isAPIError(addUserPayload) ? addUserPayload.shortMessage : "Failed to add user")
		else if (removeUserError) setErrorMessage(isAPIError(removeUserPayload) ? removeUserPayload.shortMessage : "Failed to remove user")
	}, [error, payload, errorObject, addUserError, addUserPayload, removeUserError, removeUserPayload])

	const onSubmit = handleSubmit(({ email }) => {
		if (!canManageEventUsers || !trigger()) return

		// Add user -> update user list
		addUser({ eventID: event?.id, userEmail: email.toLowerCase() }).then((resp) => {
			if (resp.error || !resp.payload) return
			setUsers(resp.payload)
			setValue("email", "")
		})
	})
	const onRemove = (userID: string) => {
		// Remove user -> update user list
		if (canManageEventUsers || userID === me?.id)
			removeUser({ eventID: event?.id, userID }).then((resp) => {
				if (resp.error || !resp.payload) return
				setUsers(resp.payload)
				if (!canManageEventUsers) history.go(0)
			})
		setUserToRemove(undefined)
	}

	// Styling
	const [css] = useStyletron()
	const container = css({
		maxWidth: "900px",
		margin: "auto",
		fontSize: "18px",
		paddingBottom: "20px",
	})
	const helpText = css({
		color: "grey",
		fontSize: "12px",
	})
	const listStyle = css({
		borderTop: `solid 1px grey`,
		marginBottom: "20px",
	})
	const userStyle = css({
		height: "50px",
		borderBottom: `solid 1px grey`,
		display: "flex",
		justifyContent: "space-between",
		alignItems: "center",
	})

	if (!event) return <Loading />

	if (!event.has_user_management)
		return (
			<div className={container}>
				<H1>Users</H1>
				<ErrorNotification message="This event does not have permission for user management" />
			</div>
		)

	return (
		<div className={container}>
			<Modal isOpen={!!userToRemove} onClose={() => setUserToRemove(undefined)} closeable>
				<ModalHeader>Remove User</ModalHeader>
				<ModalBody>
					<p>Are you sure you want to remove this User?</p>
				</ModalBody>
				<ModalFooter>
					<ModalButton kind="secondary" onClick={() => setUserToRemove(undefined)}>
						Cancel
					</ModalButton>
					<ModalButton onClick={(e) => onRemove(userToRemove!)} startEnhancer={<FontAwesomeIcon icon={["fas", "trash-alt"]} />}>
						Delete
					</ModalButton>
				</ModalFooter>
			</Modal>
			<H1>Users</H1>
			<p className={helpText}>Add/Remove additional users to help manage this event.</p>

			{loading && <Spinner />}

			<div className={listStyle}>
				{users.map((user) => (
					<div key={`event-user-${user.id}`} className={userStyle}>
						<Spaced>
							<FontAwesomeIcon icon={["fas", "user"]} color={user.id === event.owner_user_id ? "#FB158F" : "black"} />
							{user.email}
						</Spaced>
						{user.id !== event.owner_user_id && !!me && (me.id === user.id || canManageEventUsers) && (
							<Button
								kind="minimal"
								startEnhancer={<FontAwesomeIcon icon={["fas", "minus-circle"]} />}
								disabled={removingUser}
								onClick={() => setUserToRemove(user.id)}
							>
								Remove
							</Button>
						)}
					</div>
				))}
			</div>

			{canManageEventUsers && (
				<form onSubmit={onSubmit}>
					<FormControl label="Add User" error={errors.email && errors.email.message}>
						<Input
							placeholder="user@example.com"
							name="email"
							type="email"
							error={errors.email !== undefined}
							inputRef={register({
								required: "Email is required.",
								pattern: {
									value: /.+@.+\..+/,
									message: "Invalid email address",
								},
							})}
							endEnhancer={
								<Button startEnhancer={<FontAwesomeIcon icon={["fas", "plus-circle"]} />} isLoading={addingUser} type="submit">
									Invite
								</Button>
							}
							overrides={{
								Root: {
									style: {
										paddingRight: 0,
									},
								},
								EndEnhancer: {
									style: {
										paddingRight: 0,
									},
								},
							}}
							disabled={addingUser}
						/>
					</FormControl>
				</form>
			)}

			{errorMessage && <ErrorNotification message={errorMessage} />}
		</div>
	)
}
