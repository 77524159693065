import { faSortSizeDown, faSortSizeDownAlt } from "@fortawesome/pro-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { Accordion, Panel } from "baseui/accordion"
import { Block } from "baseui/block"
import { Button, SHAPE } from "baseui/button"
import { Drawer } from "baseui/drawer"
import { FormControl } from "baseui/form-control"
import { Filter, Search, Show } from "baseui/icon"
import { Input } from "baseui/input"
import { Pagination } from "baseui/pagination"
import { StatefulPopover } from "baseui/popover"
import { Radio, RadioGroup } from "baseui/radio"
import { OptionsT, Select } from "baseui/select"
import { StyledSpinnerNext } from "baseui/spinner"
import { TableBuilder, TableBuilderColumn } from "baseui/table-semantic"
import { Tag } from "baseui/tag"
import { toaster } from "baseui/toast"
import { colors } from "baseui/tokens"
import { Label1 } from "baseui/typography"
import React, { useCallback, useEffect, useState } from "react"
import { Action, QueryResponse, useMutation } from "react-fetching-library"
import { Controller, useForm } from "react-hook-form"
import { useHistory } from "react-router-dom"
import { ScoreType, ScoreTypeNames } from "../../api/enums"
import { Heading1, Heading2 } from "../../components/typography"
import { debounce } from "../../lib/debounce"
import { Event } from "../../lib/events"
import { createEvent, CreateEventRequest, getEvents, GetEventsRequest, GetEventsResponse, GetEventsSortBy } from "../../lib/superAdmin"

interface SuperAdminManageEventsPageProps {}

export const SuperAdminManageEventsPage: React.FC<SuperAdminManageEventsPageProps> = () => {
	return (
		<EventsTable
			defaultOptions={{
				limit: 20,
				offset: 0,
			}}
			query={getEvents}
		/>
	)
}

type EventRowData = [
	string, // id
	string, // shortcode
	string, // name
	string, // unlocked
	string, // archived
	number, // iteration_number
	string, // is_current
	string, // is_custom
	string, // owner_user_id
	string, // created_at
]

const columns = ["ID", "Shortcode", "Name", "Unlocked", "Archived", "Iteration Number", "Is Current", "Is Custom", "Owner User ID", "Created On"]

interface EventsTableProps {
	defaultOptions: GetEventsRequest
	query: (request: any) => Action<GetEventsResponse>
	userId?: string // used for the create new event button
}

export const EventsTable: React.FC<EventsTableProps> = ({ defaultOptions, query, userId }) => {
	const history = useHistory()
	const { mutate: refetchEvents, payload, loading, error } = useMutation(query)
	const [showCreateEventDrawer, setShowCreateEventDrawer] = useState(false)
	const [eventRowData, setEventRowData] = useState<EventRowData[]>([])
	const [currentPage, setCurrentPage] = useState(payload?.current_page || 1)
	const [options, setOptions] = useState<GetEventsRequest>(defaultOptions) // stores options that haven't been applied yet
	const [confirmedOptions, setConfirmedOptions] = useState<GetEventsRequest>(options) // stores applied options
	const [searchQuery, setSearchQuery] = useState("")

	useEffect(() => {
		refetchEvents(defaultOptions)
	}, [refetchEvents, defaultOptions])

	useEffect(() => {
		if (!payload || error) return
		setEventRowData(generateEventRowDataFromEventList(payload.events))
	}, [payload, error])

	const debouncedRefetchEventsWithSearchQuery = useCallback(
		debounce((query: string) => {
			setCurrentPage(1)
			refetchEvents({
				...confirmedOptions,
				filters: {
					...confirmedOptions.filters,
					search_query: query,
				},
			})
			console.log("SEARCHED: " + query)
		}, 1000),
		[confirmedOptions],
	)

	const renderOptionTags = () => {
		const tags: React.ReactNode[] = []
		let index = 0

		if (confirmedOptions.sort_by) {
			let displayString = "Sort by: "
			switch (confirmedOptions.sort_by) {
				case GetEventsSortBy.SORT_BY_CREATED_AT_ASCENDING:
					displayString += "Oldest to Newest"
					break
				case GetEventsSortBy.SORT_BY_CREATED_AT_DESCENDING:
					displayString += "Newest to Oldest"
					break
			}

			tags.push(
				<Tag
					key={index++}
					onActionClick={() => {
						setConfirmedOptions((prevConfirmedOptions) => ({
							...prevConfirmedOptions,
							sort_by: undefined,
						}))
						setOptions((prevOptions) => ({
							...prevOptions,
							sort_by: undefined,
						}))
						refetchEvents({
							...confirmedOptions,
							filters: {
								...confirmedOptions.filters,
								search_query: searchQuery,
							},
							sort_by: undefined,
						})
					}}
				>
					{displayString}
				</Tag>,
			)
		}

		if (confirmedOptions.filters) {
			const { iteration, is_current, is_custom, unlocked, archived } = confirmedOptions.filters
			if (iteration) {
				tags.push(
					<Tag
						key={index++}
						onActionClick={() => {
							setConfirmedOptions((prevConfirmedOptions) => ({
								...prevConfirmedOptions,
								filters: {
									...prevConfirmedOptions.filters,
									iteration: undefined,
								},
							}))
							setOptions((prevOptions) => ({
								...prevOptions,
								filters: {
									...prevOptions.filters,
									iteration: undefined,
								},
							}))
							refetchEvents({
								...confirmedOptions,
								filters: {
									...confirmedOptions.filters,
									search_query: searchQuery,
									iteration: undefined,
								},
							})
						}}
					>
						Iteration: {iteration}
					</Tag>,
				)
			}
			if (typeof is_current !== "undefined") {
				tags.push(
					<Tag
						key={index++}
						onActionClick={() => {
							setConfirmedOptions((prevConfirmedOptions) => ({
								...prevConfirmedOptions,
								filters: {
									...prevConfirmedOptions.filters,
									is_current: undefined,
								},
							}))
							setOptions((prevOptions) => ({
								...prevOptions,
								filters: {
									...prevOptions.filters,
									is_current: undefined,
								},
							}))
							refetchEvents({
								...confirmedOptions,
								filters: {
									...confirmedOptions.filters,
									search_query: searchQuery,
									is_current: undefined,
								},
							})
						}}
					>
						Launch status: {is_current ? "Current events" : "Old events"}
					</Tag>,
				)
			}
			if (typeof is_custom !== "undefined") {
				tags.push(
					<Tag
						key={index++}
						onActionClick={() => {
							setConfirmedOptions((prevConfirmedOptions) => ({
								...prevConfirmedOptions,
								filters: {
									...prevConfirmedOptions.filters,
									is_custom: undefined,
								},
							}))
							setOptions((prevOptions) => ({
								...prevOptions,
								filters: {
									...prevOptions.filters,
									is_custom: undefined,
								},
							}))
							refetchEvents({
								...confirmedOptions,
								filters: {
									...confirmedOptions.filters,
									search_query: searchQuery,
									is_custom: undefined,
								},
							})
						}}
					>
						Custom: {is_custom ? "Custom events" : "Regular events"}
					</Tag>,
				)
			}
			if (typeof unlocked !== "undefined") {
				tags.push(
					<Tag
						key={index++}
						onActionClick={() => {
							setConfirmedOptions((prevConfirmedOptions) => ({
								...prevConfirmedOptions,
								filters: {
									...prevConfirmedOptions.filters,
									unlocked: undefined,
								},
							}))
							setOptions((prevOptions) => ({
								...prevOptions,
								filters: {
									...prevOptions.filters,
									unlocked: undefined,
								},
							}))
							refetchEvents({
								...confirmedOptions,
								filters: {
									...confirmedOptions.filters,
									search_query: searchQuery,
									unlocked: undefined,
								},
							})
						}}
					>
						Unlocked: {unlocked ? "Unlocked" : "Not Unlocked"}
					</Tag>,
				)
			}
			if (typeof archived !== "undefined") {
				tags.push(
					<Tag
						key={index++}
						onActionClick={() => {
							setConfirmedOptions((prevConfirmedOptions) => ({
								...prevConfirmedOptions,
								filters: {
									...prevConfirmedOptions.filters,
									archived: undefined,
								},
							}))
							setOptions((prevOptions) => ({
								...prevOptions,
								filters: {
									...prevOptions.filters,
									archived: undefined,
								},
							}))
							refetchEvents({
								...confirmedOptions,
								filters: {
									...confirmedOptions.filters,
									search_query: searchQuery,
									archived: undefined,
								},
							})
						}}
					>
						Archived: {archived ? "Archived" : "Not Archived"}
					</Tag>,
				)
			}
		}

		return tags
	}

	return (
		<>
			<Block>
				{/* SEARCH BAR AND OPTIONS */}
				<Block
					position="sticky"
					top="0"
					display="flex"
					alignItems="center"
					padding="1rem 0"
					backgroundColor={"white"}
					overrides={{
						Block: {
							style: {
								zIndex: 5,
								overflowX: "auto",
								borderBottom: `1px solid ${colors.gray100}`,
							},
						},
					}}
				>
					<Input
						value={searchQuery || ""}
						onChange={(e) => {
							const { value } = e.currentTarget
							debouncedRefetchEventsWithSearchQuery(value)
							setSearchQuery(value)
						}}
						startEnhancer={<Search />}
						overrides={{
							Root: {
								style: {
									minWidth: "200px",
									maxWidth: "300px",
									marginRight: "1rem",
								},
							},
						}}
					/>
					<StatefulPopover
						content={({ close }) => (
							<Block width="300px">
								<Accordion>
									<Panel title="Filters">
										<Label1 marginBottom=".5rem">
											Iteration:
											<Block height=".5rem" />
											<Input
												type="number"
												value={options.filters?.iteration || ""}
												onChange={(e) => {
													const { value } = e.currentTarget

													setOptions((prevOptions) => ({
														...prevOptions,
														filters: {
															...prevOptions.filters,
															iteration: parseInt(value),
														},
													}))
												}}
											/>
										</Label1>
										<Label1 marginBottom=".5rem">
											Launch Status:
											<RadioGroup
												onChange={(e) =>
													setOptions((prevOptions) => ({
														...prevOptions,
														filters: {
															...prevOptions.filters,
															is_current: e.currentTarget.value === "true",
														},
													}))
												}
												value={String(options.filters?.is_current)}
											>
												<Radio value={"true"}>Current Events</Radio>
												<Radio value={"false"}>Old Events</Radio>
											</RadioGroup>
										</Label1>
										<Label1 marginBottom=".5rem">
											Custom:
											<RadioGroup
												onChange={(e) =>
													setOptions((prevOptions) => ({
														...prevOptions,
														filters: {
															...prevOptions.filters,
															is_custom: e.currentTarget.value === "true",
														},
													}))
												}
												value={String(options.filters?.is_custom)}
											>
												<Radio value={"true"}>Custom Events</Radio>
												<Radio value={"false"}>Regular Events</Radio>
											</RadioGroup>
										</Label1>
										<Label1 marginBottom=".5rem">
											Unlocked:
											<RadioGroup
												onChange={(e) =>
													setOptions((prevOptions) => ({
														...prevOptions,
														filters: {
															...prevOptions.filters,
															unlocked: e.currentTarget.value === "true",
														},
													}))
												}
												value={String(options.filters?.unlocked)}
											>
												<Radio value={"true"}>Unlocked</Radio>
												<Radio value={"false"}>Not Unlocked</Radio>
											</RadioGroup>
										</Label1>
										<Label1 marginBottom=".5rem">
											Archived:
											<RadioGroup
												onChange={(e) =>
													setOptions((prevOptions) => ({
														...prevOptions,
														filters: {
															...prevOptions.filters,
															archived: e.currentTarget.value === "true",
														},
													}))
												}
												value={String(options.filters?.archived)}
											>
												<Radio value={"true"}>Archived</Radio>
												<Radio value={"false"}>Not Archived</Radio>
											</RadioGroup>
										</Label1>
									</Panel>
									<Panel title="Sort By">
										<Label1 marginBottom=".5rem">
											Created on:
											<RadioGroup
												onChange={(e) =>
													setOptions((prevOptions) => ({
														...prevOptions,
														sort_by: e.currentTarget.value as GetEventsSortBy,
													}))
												}
												value={options.sort_by}
											>
												<Radio value={GetEventsSortBy.SORT_BY_CREATED_AT_ASCENDING}>
													Oldest to Newest <FontAwesomeIcon icon={faSortSizeDownAlt} />
												</Radio>
												<Radio value={GetEventsSortBy.SORT_BY_CREATED_AT_DESCENDING}>
													Newest to Oldest <FontAwesomeIcon icon={faSortSizeDown} />
												</Radio>
											</RadioGroup>
										</Label1>
									</Panel>
								</Accordion>
								<Button
									overrides={{
										Root: {
											style: {
												width: "100%",
											},
										},
									}}
									onClick={() => {
										setCurrentPage(1)
										close()
										refetchEvents({
											...options,
											filters: {
												...options.filters,
												search_query: searchQuery,
											},
										})
										setConfirmedOptions(options)
									}}
								>
									Apply
								</Button>
							</Block>
						)}
					>
						<Button
							size="compact"
							shape={SHAPE.pill}
							endEnhancer={Filter}
							overrides={{
								Root: {
									style: {
										height: "2rem",
										marginRight: "1rem",
									},
								},
							}}
						>
							Options
						</Button>
					</StatefulPopover>
					{renderOptionTags()}
				</Block>
				{!eventRowData || !payload || loading ? (
					<Block height="100%" display="flex" justifyContent="center" alignItems="center">
						<StyledSpinnerNext size="3rem" />
					</Block>
				) : (
					<>
						{/* TABLE AND DATA */}
						<TableBuilder
							overrides={{
								TableBodyCell: {
									style: {
										verticalAlign: "middle",
									},
								},
							}}
							data={eventRowData}
						>
							<TableBuilderColumn>
								{(row) => (
									<Button
										onClick={() => {
											history.push(`/events/${row[0]}`)
										}}
										overrides={{
											BaseButton: {
												style: {
													":hover": {
														backgroundColor: colors.gray300,
													},
												},
											},
										}}
										size="mini"
										kind="tertiary"
										shape={SHAPE.circle}
									>
										<Show />
									</Button>
								)}
							</TableBuilderColumn>
							{columns.map((c, index) => (
								<TableBuilderColumn key={index} header={c}>
									{(row) => row[index]}
								</TableBuilderColumn>
							))}
						</TableBuilder>
					</>
				)}
				{/* PAGINATION */}
				<Block
					position="sticky"
					bottom="0"
					display="flex"
					justifyContent="space-between"
					padding="1rem 0"
					backgroundColor={"white"}
					overrides={{
						Block: {
							style: {
								borderTop: `1px solid ${colors.gray100}`,
							},
						},
					}}
				>
					<Pagination
						numPages={payload?.total_pages || 0}
						currentPage={currentPage}
						onPageChange={async ({ nextPage }) => {
							setCurrentPage(nextPage)
							refetchEvents({
								...confirmedOptions,
								filters: {
									...confirmedOptions.filters,
									search_query: searchQuery,
								},
								offset: (nextPage - 1) * 20,
							})
						}}
					/>
					{userId && <Button onClick={() => setShowCreateEventDrawer(true)}>New Event</Button>}
				</Block>
			</Block>
			{userId && (
				<Drawer isOpen={showCreateEventDrawer} autoFocus onClose={() => setShowCreateEventDrawer(false)}>
					<CreateEventForm userId={userId} refetchEvents={() => refetchEvents(defaultOptions)} onComplete={() => setShowCreateEventDrawer(false)} />
				</Drawer>
			)}
		</>
	)
}

interface CreateEventFormProps {
	userId: string
	refetchEvents: () => Promise<QueryResponse<GetEventsResponse>>
	onComplete: () => void
}

type CreateEventForm = Omit<CreateEventRequest, "ownerUserId">

const CreateEventForm: React.FC<CreateEventFormProps> = ({ userId, refetchEvents, onComplete }) => {
	const { mutate: update, loading } = useMutation(createEvent)
	const { control, handleSubmit, trigger } = useForm<CreateEventForm>()

	const onSubmit = handleSubmit(async (input) => {
		// Trigger form validation, if not valid then return
		if (!trigger()) return

		// Update the event
		const resp = await update({
			...input,
			ownerUserId: userId,
		})

		if (resp.error) {
			// If error display error toast
			toaster.negative(<>{resp.payload.shortMessage}</>, {})
			return
		}

		// Refresh updated event
		await refetchEvents()
		// Display success toast
		toaster.positive(<>{resp.payload?.response?.message}</>, {})
		onComplete()
	})

	return (
		<form onSubmit={onSubmit}>
			<Heading1>Create New Event</Heading1>
			<Block>
				<Heading2>Event details</Heading2>
				<FormControl label="Shortcode">
					<Controller
						name="shortcode"
						control={control}
						render={({ value, onChange }) => {
							return (
								<>
									<Input value={value} onChange={(e) => onChange(e.currentTarget.value.toUpperCase())} type="text" size="compact" disabled={loading} required />
								</>
							)
						}}
					/>
				</FormControl>
				<FormControl label="Name">
					<Controller
						name="name"
						control={control}
						render={({ value, onChange }) => {
							return (
								<>
									<Input value={value} onChange={(e) => onChange(e.currentTarget.value)} type="text" size="compact" disabled={loading} />
								</>
							)
						}}
					/>
				</FormControl>
				<FormControl label="Team display name">
					<Controller
						name="teamDisplayName"
						control={control}
						render={({ value, onChange }) => {
							return (
								<>
									<Input value={value} onChange={(e) => onChange(e.currentTarget.value)} type="text" size="compact" disabled={loading} />
								</>
							)
						}}
					/>
				</FormControl>
				<FormControl label="Score type">
					<Controller
						name="scoreType"
						control={control}
						render={({ onChange, value }) => {
							const options = ScoreTypeNames.map((name, index) => ({ id: index as ScoreType, label: name }))
							const v = options.filter((o) => o.id === value)

							return (
								<Select
									size="compact"
									options={options}
									onChange={({ option }) => {
										onChange(option?.id)
									}}
									value={v}
									clearable={false}
									searchable={false}
									disabled={loading}
								/>
							)
						}}
					/>
				</FormControl>
				<FormControl label="Combine public leaderboard scores">
					<Controller
						name="publicLeaderboardCombine"
						control={control}
						render={({ onChange, value }) => {
							const options: OptionsT = [
								{
									id: "Yes",
									value: true,
								},
								{
									id: "No",
									value: false,
								},
							]
							const v = options.filter((o) => o.value === value)

							return (
								<Select
									size="compact"
									options={options}
									onChange={({ option }) => {
										onChange(option?.value)
									}}
									value={v}
									labelKey="id"
									valueKey="value"
									clearable={false}
									searchable={false}
									disabled={loading}
								/>
							)
						}}
					/>
				</FormControl>
				<FormControl label="Official judge scoring weight">
					<Controller
						name="officialWeight"
						control={control}
						render={({ value, onChange }) => {
							return (
								<>
									<Input
										value={value || ""}
										onChange={(e) => onChange(e.currentTarget.valueAsNumber || undefined)}
										type="number"
										size="compact"
										disabled={loading}
									/>
								</>
							)
						}}
					/>
				</FormControl>
				<FormControl label="Guest judge scoring weight">
					<Controller
						name="guestWeight"
						control={control}
						render={({ value, onChange }) => {
							return (
								<>
									<Input
										value={value || ""}
										onChange={(e) => onChange(e.currentTarget.valueAsNumber || undefined)}
										type="number"
										size="compact"
										disabled={loading}
									/>
								</>
							)
						}}
					/>
				</FormControl>
				<FormControl label="Comment section caption">
					<Controller
						name="commentSectionCaption"
						control={control}
						render={({ value, onChange }) => {
							return (
								<>
									<Input value={value} onChange={(e) => onChange(e.currentTarget.value)} type="text" size="compact" disabled={loading} />
								</>
							)
						}}
					/>
				</FormControl>
			</Block>
			<Block>
				<Heading2>Event limits</Heading2>
				<FormControl label="Judge limit">
					<Controller
						name="judgeLimit"
						control={control}
						render={({ value, onChange }) => {
							return (
								<>
									<Input
										value={value || ""}
										onChange={(e) => onChange(e.currentTarget.valueAsNumber || undefined)}
										type="number"
										size="compact"
										disabled={loading}
									/>
								</>
							)
						}}
					/>
				</FormControl>
				<FormControl label="Team limit">
					<Controller
						name="teamLimit"
						control={control}
						render={({ value, onChange }) => {
							return (
								<>
									<Input
										value={value || ""}
										onChange={(e) => onChange(e.currentTarget.valueAsNumber || undefined)}
										type="number"
										size="compact"
										disabled={loading}
									/>
								</>
							)
						}}
					/>
				</FormControl>
				<FormControl label="Category limit">
					<Controller
						name="categoryLimit"
						control={control}
						render={({ value, onChange }) => {
							return (
								<>
									<Input
										value={value || ""}
										onChange={(e) => onChange(e.currentTarget.valueAsNumber || undefined)}
										type="number"
										size="compact"
										disabled={loading}
									/>
								</>
							)
						}}
					/>
				</FormControl>
				<FormControl label="Opinion category limit">
					<Controller
						name="opinionCategoryLimit"
						control={control}
						render={({ value, onChange }) => {
							return (
								<>
									<Input
										value={value || ""}
										onChange={(e) => onChange(e.currentTarget.valueAsNumber || undefined)}
										type="number"
										size="compact"
										disabled={loading}
									/>
								</>
							)
						}}
					/>
				</FormControl>
				<FormControl label="Audience participation limit">
					<Controller
						name="audienceParticipationLimit"
						control={control}
						render={({ value, onChange }) => {
							return (
								<>
									<Input
										value={value || ""}
										onChange={(e) => onChange(e.currentTarget.valueAsNumber || undefined)}
										type="number"
										size="compact"
										disabled={loading}
									/>
								</>
							)
						}}
					/>
				</FormControl>
			</Block>
			<Block display="flex">
				<Button
					overrides={{
						Root: {
							style: {
								marginLeft: "auto",
							},
						},
					}}
					type="submit"
					isLoading={loading}
				>
					Create Event
				</Button>
			</Block>
		</form>
	)
}

const generateEventRowDataFromEventList = (events: Event[]): EventRowData[] => {
	const rows: EventRowData[] = []

	events.forEach((e) => {
		rows.push([
			e.id,
			e.shortcode,
			e.name,
			e.unlocked ? "Unlocked" : "",
			e.archived ? "Archived" : "",
			e.iteration_number,
			e.is_current ? "Latest" : "Old",
			e.is_custom ? "Custom" : "",
			e.owner_user_id,
			new Date(e.created_at).toLocaleDateString(),
		])
	})

	return rows
}
