import { AccountType, EventType, ScoreType } from "./enums"

export interface APIError {
	error: string
	shortMessage: string
	longMessage: string
	variables: Object
	tags: Object
}

export function isAPIError(arg: any): arg is APIError {
	return arg !== undefined
}

export interface User {
	id: string
	email: string
	role: string
	verified: boolean
	accountType: AccountType
	firstName: string
	lastName: string
	contactNumber: string
	industry: string
	address: Address
	creditCount: number

	eventID: string
	isGuest?: boolean
	isSuperAdmin?: boolean
	isImpersonated?: boolean
}

export interface Address {
	id?: string
	companyName?: string
	taxNumber?: string
	line1?: string
	line2?: string
	city?: string
	state?: string
	country?: string
	postOrZip?: string
}

export interface TeamAssessment {
	scores: TeamScore[]
	totalScore: number
	hasScores: boolean
	comment: string
	judgeID: string
	judgeEmail: string
	judgeIsGuest: boolean
}

export interface TeamScore {
	categoryID: string
	score: number
}

export interface LeaderboardEntry {
	TeamName: string
	ProductName: string
	Score: number
}
export interface LeaderboardResponse {
	MainJudge: LeaderboardEntry[]
	GuestJudge: LeaderboardEntry[]
	EventType: EventType
	ScoreType: ScoreType
	PublicEvent: boolean
}

export function isLeaderboardResponse(arg: any): arg is LeaderboardResponse {
	return arg !== undefined
}

export interface BillingInfo {
	name?: string
	email?: string
	address1?: string
	address2?: string
	city?: string
	state?: string
	country?: string
	postcode?: string
	phone?: string
}

export interface Invoice {
	storeName: string
	planName: string
	price: number
	currency: string
	invoiceCreatedDate: number
	subStartDate: number
	subEndDate: number
}
