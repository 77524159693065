import { Action } from "react-fetching-library"
import { APIError } from "../api/types"

export const getAdminReport = (event_id: string, team_id?: string): Action<ReportData[] | APIError> => {
	return {
		method: "GET",
		credentials: "include",
		endpoint: `/admin/teams/report?event_id=${event_id}${team_id && `&team_id=${team_id}`}`,
		responseType: "json",
	}
}
export const getFullAdminReport = (event_id: string): Action<ReportData[] | APIError> => {
	return {
		method: "GET",
		credentials: "include",
		endpoint: `/admin/teams/report?event_id=${event_id}`,
		responseType: "json",
	}
}

export interface Score {
	categoryName: string
	value: number
}

export interface ReportData {
	total: number
	scores: Score[]
	comments: string[]
	teamName: string
	productName: string
}
