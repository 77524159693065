import * as React from "react"
import { Button } from "baseui/button"
import { useStyletron } from "styletron-react"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { IconProp } from "@fortawesome/fontawesome-svg-core"
import { useHistory } from "react-router-dom"
import { CommonContainer } from "../lib/controller"
import { canExportImport as check } from "../lib/canExportImport"

export interface PremiumButtonProps {
	canExportImport?: boolean
	iconProp: IconProp
	onClick: () => void
	label: string
	loading?: boolean
}

export const PremiumButton = ({ canExportImport, onClick, label, iconProp, loading }: PremiumButtonProps) => {
	const { event, user } = CommonContainer.useContainer()
	canExportImport = canExportImport || check(user, event)
	const [css] = useStyletron()
	const history = useHistory()

	const [buttonHovered, setButtonHovered] = React.useState(false)
	const lockedIcon: IconProp = ["fas", "lock"]
	const unlockedIcon: IconProp = ["fas", "unlock"]

	const btnWrap = css({
		position: "relative",
		overflow: "hidden",
		marginTop: "5px",
	})
	const premCover = css({
		position: "absolute",
		right: "-32px",
		top: "10px",
		width: "100px",
		overflow: "hidden",
		transform: "rotate(45deg)",
		backgroundColor: "black",
		fontSize: "10px",
		color: "white",
		textAlign: "center",
		pointerEvents: "none",
		background: "transparent linear-gradient(257deg, #F2148C 0%, #861EF7 51%, #38E2CF 100%) 0% 0% no-repeat padding-box",
	})

	const whatIcon = (): IconProp => {
		if (canExportImport) return iconProp
		if (buttonHovered) return unlockedIcon
		else return lockedIcon
	}

	const toSubscriptionPage = () => {
		history.push("/upgrade") // Subscription page currently disabled
	}

	return (
		<div
			className={btnWrap}
			title={!canExportImport ? "This is an advanced feature. Please consider upgrading your account." : undefined}
			onMouseOver={() => setButtonHovered(true)}
			onMouseLeave={() => setButtonHovered(false)}
		>
			<Button
				onClick={canExportImport ? onClick : toSubscriptionPage}
				startEnhancer={<FontAwesomeIcon icon={whatIcon()} />}
				isLoading={loading}
				overrides={{
					BaseButton: {
						style: () => (!canExportImport ? { backgroundColor: "lightgray" } : {}),
					},
				}}
			>
				{label}
			</Button>
			{!canExportImport && <div className={premCover}>Premium</div>}
		</div>
	)
}
