import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { useStyletron } from "baseui"
import { Button } from "baseui/button"
import { FormControl } from "baseui/form-control"
import { Input } from "baseui/input"
import { Modal, ModalBody, ModalButton, ModalFooter, ModalHeader } from "baseui/modal"
import { Tab, Tabs } from "baseui/tabs-motion"
import { Textarea } from "baseui/textarea"
import { H1, LabelMedium } from "baseui/typography"
import * as React from "react"
import { useMutation, useQuery } from "react-fetching-library"
import { Controller, useForm } from "react-hook-form"
import { RouteComponentProps, useHistory } from "react-router-dom"
import { APIError, isAPIError } from "../../api/types"
import { ErrorNotification, InlineLabel } from "../../components/common"
import { Loading } from "../../components/loading"
import { Spaced } from "../../components/spaced"
import { Spread } from "../../components/spread"
import { TeamCard } from "../../components/teamCard"
import { CommonContainer } from "../../lib/controller"
import { createTeam, deleteTeam, getTeam, Team, updateTeam } from "../../lib/teams"
import { ListPage } from "../listPages"

export const TeamPage = (props: RouteComponentProps<{ id: string }>) => {
	const id = props.match.params.id
	const isNew = id === "create"

	const { event, eventID } = CommonContainer.useContainer()
	const history = useHistory()

	// Get Team
	const [team, setTeam] = React.useState<Team>()
	const { error, payload, loading, query: refresh } = useQuery<Team[]>(getTeam(eventID, id), !isNew)

	React.useEffect(() => {
		if (!payload || payload.length === 0) return
		setTeam(payload[0])
	}, [payload, loading, error])

	const [activeKey, setActiveKey] = React.useState("details")

	// Edit Mode
	const [editMode, _setEditMode] = React.useState(isNew)
	const [showSuccessModal, setShowSuccessModal] = React.useState<string>()
	const setEditMode = (value: boolean) => {
		_setEditMode(value)
		history.replace({ search: value ? "edit=true" : undefined })
	}
	React.useEffect(() => {
		const params = new URLSearchParams(history.location.search)
		setActiveKey(params.get("tab") || "details")
		// Set edit mode based on edit search param
		if (!isNew) _setEditMode(params.get("edit") === "true")
	}, [history.location.search, isNew])

	const onEditComplete = (updatedTeam?: Team) => {
		if (updatedTeam) {
			setShowSuccessModal(isNew ? "Created" : "Updated")
			setTeam(updatedTeam)
			history.replace("/teams/" + updatedTeam.id)
		} else if (isNew) {
			history.push("/teams")
		}

		setEditMode(false)
	}

	// Archiving
	const { mutate, loading: mutating } = useMutation(updateTeam)
	const onArchive = () => mutate({ teamID: id, archived: true }).then(() => refresh())
	const onUnarchive = () => mutate({ teamID: id, archived: false }).then(() => refresh())

	// Deleting
	const { mutate: mutateDelete, loading: deleting, error: deleteError, payload: deletePayload } = useMutation(deleteTeam)
	const [showDeleteModal, setShowDeleteModal] = React.useState(false)

	// Styling
	const [css, theme] = useStyletron()
	const containerStyle = css({
		maxWidth: "900px",
		margin: "10px auto",
	})
	const nameStyle = css({
		...theme.typography.HeadingSmall,
	})
	const detailStyle = css({
		padding: "10px",
		display: "grid",
		gridTemplateColumns: "150px auto",
	})
	const detailAltStyle = css({
		padding: "10px",
		display: "grid",
		gridTemplateColumns: "150px auto",
		backgroundColor: theme.colors.backgroundSecondary,
		borderRadius: "4px",
	})
	const headerStyle = css({
		display: "none",
		"@media only screen and (max-width: 700px)": {
			display: "flex",
		},
	})
	const urlStyle = css({
		margin: "0px",
		cursor: "pointer",
		color: "blue",
		":hover": {
			textDecoration: "underline",
		},
	})

	// Edit Mode?
	if (editMode)
		return (
			<div className={containerStyle}>
				<div className={headerStyle}>
					<H1>{event ? event.team_display_name : "Teams"}</H1>
				</div>
				<div className={nameStyle}>{team ? team.name : "New Team"}</div>
				<TeamEdit team={team} isNew={isNew} stopEditMode={onEditComplete} />
			</div>
		)

	// Loading
	if (!team && loading) return <Loading />
	if (!team) return <></>

	const details = [
		{ label: "Name", value: team.name },
		{ label: "Product Name", value: team.product_name },
		{ label: "Description", value: team.description },
		{ label: "Website", value: team.website_url },
	]

	// Check for valid URL
	const validURL = (str: string): boolean => {
		try {
			const u = new URL(str)
			return !!u
		} catch {
			return false
		}
	}

	// Open URL in new window
	const openUrl = (url: string) => {
		const newWindow = window.open(url, "_blank", "noopener,noreferrer")
		if (newWindow) newWindow.opener = null
	}
	return (
		<div className={containerStyle}>
			<H1>{event ? event.team_display_name : "Teams"}</H1>
			<Spread>
				<div className={nameStyle}>{team.name}</div>

				{/* Actions */}
				{event?.is_current && (
					<Spaced>
						{!team.archived && (
							<Button onClick={() => setEditMode(true)} startEnhancer={<FontAwesomeIcon icon={["fas", "edit"]} />}>
								Edit
							</Button>
						)}
						{!team.archived && (
							<Button onClick={onArchive} isLoading={mutating} startEnhancer={<FontAwesomeIcon icon={["fas", "archive"]} />}>
								Archive
							</Button>
						)}
						{team.archived && (
							<Button onClick={onUnarchive} isLoading={mutating} startEnhancer={<FontAwesomeIcon icon={["fas", "undo"]} />}>
								Unarchive
							</Button>
						)}
						{team.archived && (
							<Button onClick={() => setShowDeleteModal(true)} isLoading={mutating} startEnhancer={<FontAwesomeIcon icon={["fas", "trash-alt"]} />}>
								Delete
							</Button>
						)}
					</Spaced>
				)}
			</Spread>

			<Tabs
				activeKey={activeKey}
				onChange={({ activeKey }: any) => {
					history.push({ search: "tab=" + activeKey })
					setActiveKey(activeKey)
				}}
				activateOnFocus
			>
				<Tab title="Details" key="details">
					<div>
						{details.map((detail, index) => {
							// Split the description onto multiple lines at every fullstop
							let labels = [<LabelMedium>{detail.value}</LabelMedium>]
							if (detail.label.toLowerCase() === "description") {
								const descriptionArray: string[] = detail.value.split(".")
								labels = descriptionArray.map((v, i) => (
									<LabelMedium key={v + i} $style={{ width: "100%" }}>
										{v}
										{i < descriptionArray.length - 1 ? "." : ""}
									</LabelMedium>
								))
							}
							// If valid website url make it a hyperlink
							if (detail.label.toLowerCase() === "website" && validURL(detail.value)) {
								labels = [
									<div onClick={() => openUrl(detail.value)}>
										<LabelMedium>
											<p className={urlStyle}>{detail.value}</p>
										</LabelMedium>
									</div>,
								]
							}
							return (
								<div key={`team-detail-${detail.label}`} className={index % 2 === 0 ? detailAltStyle : detailStyle}>
									<LabelMedium $style={{ fontWeight: 600 }}>{`${detail.label}:`}</LabelMedium>
									<div>{labels}</div>
								</div>
							)
						})}
					</div>
				</Tab>
				<Tab title="Score Breakdown" key="score-breakdown">
					<TeamCard team={team} />
				</Tab>
				<Tab title="Scores & Comments" key="scores">
					<ListPage.TeamAssessments teamID={team.id} />
				</Tab>
			</Tabs>

			{/* Success Modal */}
			<Modal onClose={() => setShowSuccessModal(undefined)} isOpen={!!showSuccessModal}>
				<ModalHeader>{`Team ${showSuccessModal} Successfully`}</ModalHeader>
				<ModalFooter>
					<ModalButton onClick={() => setShowSuccessModal(undefined)}>OK</ModalButton>
				</ModalFooter>
			</Modal>

			{/* Delete Modal */}
			<Modal onClose={() => setShowDeleteModal(false)} isOpen={showDeleteModal}>
				<ModalHeader>Delete Team?</ModalHeader>
				<ModalBody>
					<p>Are you sure you want to delete this team?</p>
					{deleteError && isAPIError(deletePayload) && <ErrorNotification message={deletePayload.shortMessage} />}
				</ModalBody>
				<ModalFooter>
					<ModalButton onClick={() => setShowDeleteModal(false)} kind="secondary">
						Cancel
					</ModalButton>
					<ModalButton
						onClick={async () => {
							const resp = await mutateDelete(id)
							if (!resp.error) history.push("/teams")
						}}
						isLoading={deleting}
						startEnhancer={<FontAwesomeIcon icon={["fas", "trash-alt"]} />}
					>
						Delete
					</ModalButton>
				</ModalFooter>
			</Modal>
		</div>
	)
}

interface TeamInput {
	name?: string
	productName?: string
	description?: string
	websiteUrl?: string
}

const TeamEdit = (props: { team?: Team; isNew: boolean; stopEditMode: (team?: Team) => void }) => {
	const { team, isNew, stopEditMode } = props

	const { eventID } = CommonContainer.useContainer()

	// Setup form
	const { control, register, handleSubmit, setValue, trigger, errors } = useForm<TeamInput>()
	const { mutate: update, loading, error, payload } = useMutation(isNew ? createTeam : updateTeam)

	const onSaveForm = handleSubmit(async (input) => {
		if (!trigger()) return
		const resp = await update(!!team ? { teamID: team.id, ...input } : { eventID: eventID, ...input })
		// Error?
		if ((error && isAPIError(resp.payload)) || (resp.payload as APIError).error) return
		const updatedTeam = resp.payload as Team
		// On Success
		if (updatedTeam) stopEditMode(updatedTeam)
	})

	// Load defaults
	React.useEffect(() => {
		if (!team) return
		setValue("name", team.name)
		setValue("productName", team.product_name)
		setValue("description", team.description)
		setValue("websiteUrl", team.website_url)
	}, [team, setValue])

	// Styling
	const [css] = useStyletron()
	const footerStyle = css({
		display: "flex",
		flexDirection: "row-reverse",
	})

	return (
		<form
			onSubmit={(e) => {
				e.preventDefault()
				onSaveForm()
			}}
		>
			{error && isAPIError(payload) && <ErrorNotification message={payload.shortMessage} />}

			<FormControl label={<InlineLabel label="Name" caption="Who made it" />} error={errors.name?.message} positive="">
				<Input name="name" inputRef={register({ required: "Team Name is required" })} disabled={loading} />
			</FormControl>

			<FormControl label={<InlineLabel label="Product Name" caption="What will be judged" />} error={errors.productName?.message} positive="">
				<Input name="productName" inputRef={register({ required: "Product Name is required" })} disabled={loading} />
			</FormControl>

			<FormControl label={<InlineLabel label="Website" caption="(optional)" />} error={errors.websiteUrl?.message} positive="">
				<Input name="websiteUrl" inputRef={register} disabled={loading} />
			</FormControl>

			<FormControl label={<InlineLabel label="Description" caption="(optional)" />} error={errors.description?.message} positive="">
				<Controller
					name="description"
					defaultValue=""
					control={control}
					as={Textarea}
					overrides={{
						Input: {
							style: {
								resize: "vertical",
								minHeight: "46px",
							},
						},
					}}
					disabled={loading}
				/>
			</FormControl>

			<div className={footerStyle}>
				<Spaced>
					<Button type="button" onClick={() => stopEditMode()} kind="secondary">
						Cancel
					</Button>
					<Button
						onClick={(e) => {
							e.preventDefault()
							onSaveForm()
						}}
						isLoading={loading}
						startEnhancer={<FontAwesomeIcon icon={["fas", "save"]} />}
					>
						Save
					</Button>
				</Spaced>
			</div>
		</form>
	)
}
