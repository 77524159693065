import { Action } from "react-fetching-library"
import { ObjectType } from "../api/enums"
import { APIError } from "../api/types"

export const getTemplate = (type: ObjectType): Action<Blob | APIError> => {
	return {
		method: "GET",
		credentials: "include",
		endpoint: `/template?type=${type}`,
		responseType: "blob",
	}
}
