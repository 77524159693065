import { styled } from "styletron-react"

export const Heading1 = styled("h1", {
	fontSize: "40px",
	fontWeight: 500,
})
export const Heading2 = styled("h2", {
	fontSize: "24px",
	fontWeight: 500,
})
