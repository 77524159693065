import { faDatabase, faUser } from "@fortawesome/pro-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { Block } from "baseui/block"
import { Button } from "baseui/button"
import React from "react"
import { useHistory } from "react-router-dom"

interface SuperAdminHomeProps {}

export const SuperAdminHome: React.FC<SuperAdminHomeProps> = () => {
	const history = useHistory()

	return (
		<Block display="flex" justifyContent="center" alignItems="center" height="100%">
			<Button
				overrides={{
					BaseButton: {
						style: {
							height: "200px",
							width: "200px",
							marginRight: "1rem",
						},
					},
				}}
				onClick={() => history.push("/users")}
			>
				<div>
					<FontAwesomeIcon icon={faUser} size="4x" />
					<p>Manage users</p>
				</div>
			</Button>
			<Button
				overrides={{
					BaseButton: {
						style: {
							height: "200px",
							width: "200px",
						},
					},
				}}
				onClick={() => history.push("/events")}
			>
				<div>
					<FontAwesomeIcon icon={faDatabase} size="4x" />
					<p>Manage events</p>
				</div>
			</Button>
		</Block>
	)
}
