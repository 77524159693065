import * as React from "react"
import * as ReactDOM from "react-dom"
import * as Sentry from "@sentry/react"
import { App } from "./app"
import { SENTRY_DSN, STRIPE_PUBLISHABLE_KEY } from "./config"

import { Client as Styletron } from "styletron-engine-atomic"
import { Provider as StyletronProvider } from "styletron-react"
import { BaseProvider } from "baseui"
import { ClientContextProvider } from "react-fetching-library"
import { Client } from "./api/client"
import { loadIcons } from "./lib/loadIcons"
import { LightTheme } from "./themeOverrides"
import { CommonContainer } from "./lib/controller"
import { loadStripe } from "@stripe/stripe-js"
import { Elements as StripeElements } from "@stripe/react-stripe-js"

import "./assets/css/stripe.css"

if (SENTRY_DSN) Sentry.init({ dsn: SENTRY_DSN })
const stripe = loadStripe(STRIPE_PUBLISHABLE_KEY)
const engine = new Styletron()
loadIcons()

ReactDOM.render(
	<React.StrictMode>
		<StyletronProvider value={engine}>
			<BaseProvider theme={LightTheme} zIndex={5000}>
				<ClientContextProvider client={Client}>
					<StripeElements stripe={stripe}>
						<CommonContainer.Provider>
							<App />
						</CommonContainer.Provider>
					</StripeElements>
				</ClientContextProvider>
			</BaseProvider>
		</StyletronProvider>
	</React.StrictMode>,
	document.getElementById("root"),
)
