import React, { useEffect } from "react"
import { isAndroid, isIOS } from "react-device-detect"

interface RedirectToAppProps {}

export const RedirectToApp: React.FC<RedirectToAppProps> = () => {
	useEffect(() => {
		if (isAndroid) {
			const url = "intent://app.judgefest.com/open/#Intent;scheme=https;package=com.judgefest;end"

			window.location.replace(url)
		} else if (isIOS) {
			window.location.replace("judgefest://")

			setTimeout(() => {
				window.location.replace("https://apps.apple.com/us/app/judgefest/id1462710439")
			}, 10000)
		} else {
			window.location.replace("https://judgefest.com")
		}
	}, [])

	return (
		<div>
			<div>If you have not been automatically redirected, click on the following link:</div>
			{isAndroid ? (
				<a href="https://play.google.com/store/apps/details?id=com.judgefest">Open Android app</a>
			) : isIOS ? (
				<a href="https://apps.apple.com/us/app/judgefest/id1462710439">Open iOS app</a>
			) : (
				<a href="https://judgefest.com">Open Web app</a>
			)}
		</div>
	)
}
