import * as React from "react"
import { useStyletron } from "baseui"
import { LabelSmall } from "baseui/typography"
import preval from "preval.macro"
const { version } = require("../../package.json")
const dateTimeStamp = preval`module.exports = new Date().toLocaleString();`

/**
 * Display Current Version and Build Time
 */
export const VersionText = () => {
	const [css] = useStyletron()

	const buildTimeStyle = css({
		color: "white",
	})

	return (
		<LabelSmall
			$style={{
				marginTop: "2rem",
				opacity: 0.8,
				right: "6px",
				bottom: "10px",
				color: "white",
				textAlign: "right",
			}}
		>
			v{version}
			<br />
			<div className={buildTimeStyle}>{dateTimeStamp || "-"}</div>
		</LabelSmall>
	)
}

VersionText.defaultProps = {
	versionColour: "white",
	buildTimeColour: "grey",
}
