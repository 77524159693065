import * as React from "react"
import { useMutation, useQuery } from "react-fetching-library"
import { useLocalStorage } from "react-use"
import { createContainer } from "unstated-next"
import { AccountType } from "../api/enums"
import { Address, APIError, isAPIError, User } from "../api/types"
import { AuthCheck, Register, SignInAction, SignOutAction } from "./auth"
import { Event, getEvents } from "./events"
import { ChangeAddress, ChangeDetails, ChangePassword } from "./user"

const useCommon = () => {
	// Auth
	const [user, setUser] = React.useState<User>()
	const useCheckAuth = () => {
		const { payload, error, loading } = useQuery(AuthCheck)
		React.useEffect(() => {
			if (!payload || error) return
			setUser((payload as { payload: User }).payload)
		}, [payload, error])

		return { loading, signedIn: error === false && !!user, isSuperAdmin: !!user?.isSuperAdmin, isImpersonated: !!user?.isImpersonated }
	}

	const { mutate: signInMutation } = useMutation<User | APIError>(SignInAction)
	const signIn = async (email: string, password: string) => {
		const resp = await signInMutation({ email, password })
		if (resp.error) {
			if (isAPIError(resp.payload)) return resp.payload.shortMessage
			else return "Failed to contact server"
		}
		setUser(resp.payload as User)
	}

	const { mutate: signOutMutation } = useMutation(SignOutAction)
	const signOut = async () => {
		const resp = await signOutMutation(SignOutAction)
		if (resp.error) {
			if (isAPIError(resp.payload)) return resp.payload.shortMessage
			else return "Failed to contact server"
		}
		setEvent(undefined)
		setUser(undefined)
	}

	const { mutate: registerMutation } = useMutation(Register)
	const signUp = async (email: string, firstName: string, lastName: string, contactNumber: string, password: string) => {
		const resp = await registerMutation({ email, firstName, lastName, contactNumber, password })
		if (resp.error) {
			if (isAPIError(resp.payload)) return resp.payload.shortMessage
			else return "Failed to contact server"
		}
	}

	const useChangeDetails = () => {
		const { mutate, loading, status, error, payload } = useMutation(ChangeDetails)
		const changeDetails = async (email: string, firstName: string, lastName: string, contactNumber: string, industry: string) => {
			const resp = await mutate({ email, firstName, lastName, contactNumber, industry })
			// Success
			if (resp.status === 200 && user && user.email !== email) setUser({ ...user, email, verified: false, firstName, lastName, contactNumber, industry })
			if (resp.status === 200 && user && user.email === email) setUser({ ...user, firstName, lastName, contactNumber, industry })
			return resp
		}
		return {
			changeDetails,
			loading,
			success: status === 200,
			error: error && isAPIError(payload) ? payload.shortMessage : undefined,
		}
	}

	const useChangeAddress = () => {
		const { mutate, loading, status, error, payload } = useMutation(ChangeAddress)
		const changeAddress = async (address: Address) => {
			const resp = await mutate(address)
			// Success
			if (resp.status === 200 && user) setUser({ ...user, address: address })
			return resp
		}
		return {
			changeAddress,
			loading,
			success: status === 200,
			error: error && isAPIError(payload) ? payload.shortMessage : undefined,
		}
	}

	const useChangePassword = () => {
		const { mutate, loading, status, error, payload } = useMutation(ChangePassword)
		const changePassword = (password: string, newPassword: string) => mutate({ password, newPassword })
		return {
			changePassword,
			loading,
			success: status === 200,
			error: error && isAPIError(payload) ? payload.shortMessage : undefined,
		}
	}

	/** Whether the user is paying customer (AccountType Basic or higher)  */
	const isPaidUser = user && (user.accountType as number) >= (AccountType.Basic as number)

	// Event
	const [getEventStorage, setEventIDStorage, removeEvent] = useLocalStorage<string>("eventUUID")
	const [eventID, setEventID] = React.useState(getEventStorage || "")
	const [event, setEvent] = React.useState<Event>()
	const { query: getEvent } = useQuery(getEvents(eventID))

	React.useEffect(() => {
		if (!eventID) return
		getEvent().then((resp) => {
			if (!resp.payload || resp.payload.length === 0) {
				setEventID("")
				return
			}
			setEvent(resp.payload[0])
		})
	}, [eventID, getEvent])

	/** Updates current event object and event id (used for event editing) */
	const setEventObject = (updatedEvent: Event) => {
		setEvent(updatedEvent)
		setEventIDStorage(updatedEvent.id)
		setEventID(updatedEvent.id)
	}

	return {
		user,
		setUser,
		useCheckAuth,
		signIn,
		signOut,
		signUp,

		useChangeDetails,
		useChangePassword,
		useChangeAddress,

		isPaidUser,

		event,
		eventID,
		setEvent: (value: string) => {
			setEventIDStorage(value)
			setEventID(value)
		},
		setEventObject,
		removeEvent: () => {
			removeEvent()
			setEventID("")
			setEvent(undefined)
		},
	}
}

export const CommonContainer = createContainer(useCommon)
