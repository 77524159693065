import * as React from "react"
import { Card } from "baseui/card"
import { useStyletron } from "styletron-react"
import { useQuery } from "react-fetching-library"
import { Team } from "../lib/teams"
import { isAPIError } from "../api/types"
import { TeamScoreBreakdown } from "./teamScoreBreakdown"
import { getAdminReport, ReportData } from "../lib/report"
import { CommonContainer } from "../lib/controller"
import { ScoreType } from "../api/enums"

export const TeamCard = (props: { team: Team }) => {
	const { team } = props
	const { event, eventID } = CommonContainer.useContainer()

	const [css] = useStyletron()
	const { payload, error } = useQuery<ReportData[]>(getAdminReport(eventID, team.id))
	const scoreDivision = event?.score_type === ScoreType.Decimal ? 10 : 1

	if (error && isAPIError(payload)) {
		return (
			<div>
				<pre>{payload.shortMessage}</pre>
			</div>
		)
	}

	const cardStyle = css({
		paddingTop: "20px",
		maxWidth: "780px",
		margin: "auto",
	})
	const scoreCardInfoStyle = css({
		display: "flex",
	})
	const scoreBox = css({
		display: "flex",
	})
	const teamInfoStyle = css({
		color: "white",
		padding: "1rem",
	})
	const scoreText = css({
		fontSize: "30px",
		fontWeight: "bold",
		color: "white",
		margin: 0,
		paddingLeft: "10px",
	})
	const labelScoreStyle = css({
		color: "white",
		margin: 0,
	})

	if (!payload || payload.length === 0) return null
	const report = payload[0]

	return (
		<div className={cardStyle}>
			<Card
				title={team.product_name}
				overrides={{
					Root: {
						style: {
							borderTopColor: "black",
							borderBottomColor: "black",
							borderLeftColor: "black",
							borderRightColor: "black",
							borderTopWidth: "0px",
							borderBottomWidth: "0px",
							borderLeftWidth: "0px",
							borderRightWidth: "0px",
							borderTopLeftRadius: "3px",
							borderTopRightRadius: "3px",
							borderBottomLeftRadius: "3px",
							borderBottomRightRadius: "3px",
							boxShadow: "0px 0px 18px #00000019;",
							padding: "3px",
							backgroundColor: "#1C0658",
						},
					},
					Title: {
						style: {
							color: "white",
							fontSize: "30px",
						},
					},
					Body: {
						style: {
							display: "flex",
							justifyContent: "space-between",
						},
					},
				}}
			>
				<div className={teamInfoStyle} key={`team-${team.id}`}>
					<div style={{ fontSize: "25 px" }}>{team.name}</div>
				</div>

				<div className={scoreCardInfoStyle}>
					<Card
						overrides={{
							Root: {
								style: {
									borderTopStyle: "none",
									borderBottomStyle: "none",
									borderLeftStyle: "none",
									borderRightStyle: "none",
									backgroundColor: "#1C0658",
								},
							},
						}}
					>
						<div className={scoreBox}>
							<p className={labelScoreStyle}>Total Score: </p>
							<p className={scoreText}>{Math.round((report.total / scoreDivision) * 10) / 10}</p>
						</div>
					</Card>
				</div>
			</Card>

			<div className={cardStyle}>
				<TeamScoreBreakdown report={report} />
			</div>
		</div>
	)
}
